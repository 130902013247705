import React, {Component} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types'

//Componente para listar en select las EPS consumido en API

class Eps extends Component {   

    constructor()
    {
     super()
      this.state={        
        eps: [],
        id_eps:''
      }
    }

    componentDidMount(){    
    
        axios.get("https://medicallapi.azurewebsites.net/api/Entities")
        .then((response) => {
          console.log(response);
          this.setState({eps: response.data})
        })
        .catch((error) => {
          console.log(error);
        })
      }  
      
      handleChange = (e) =>{
        this.setState({id_eps:e.target.value})
        //console.log(e.target.value)
        //var med=(e.target.value)
        //alert(med)
      }
      

    render(){ 
      const {handlerideps} = this.props  
      const {id_eps} = this.state        
   
     return (   
     
        <div >

                <select onChange={this.handleChange} onClick={()=>handlerideps(id_eps)} value={this.state.id_eps}  className="form-control" id="eps">
                    <option  value="0"> Seleccione...</option> ,
                    {this.state.eps.map(item=>                              
                    <option key={item.id} value={item.id}> {item.name}</option>  
                    )}
                            
                </select>                    
         
        </div>    
      
    )

  }
  
}  

Eps.propTypes = {
  handlerideps:PropTypes.func.isRequired
}


export default Eps;