import React, {Component} from 'react';
import axios from 'axios';
import * as ReactBootStrap from 'react-bootstrap';
import Moment from 'react-moment';
import 'moment/locale/es';
import moment from "moment";
import '../tools/css/ContratosAdd.css';
import '../tools/css/Home.css';
import BtnCancel from '../components/BtnCancel';
import DiasSemana from '../API/DiasSemana';
import BtnEdit from '../components/BtnEdit';
import BtnDelete from '../components/BtnDelete';
import Info from '../tools/images/Info';
import Buscar from '../tools/images/Buscar';
import Eps from '../API/Eps';
import $ from 'jquery'; // <-to import jquery
import 'bootstrap';

import emptypage from '../tools/images/carpeta.gif';

import ReactNotifications from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';


const ApiURL="https://medicallapi.azurewebsites.net";
var dia_intervalo='null';
var dia_intervalo_edit='null';
var mensaje='';
var param='';
var idhorario='0';
var nameparametro_edit=''
var modal=0
var id_dianolaboral=0;



class Horarios_Atencion extends Component {

  constructor()
    {
     super()
      this.state={
        Horario: [],  
        Parametro: [], 
        Dia_nolaboral: [],
        loading: false, 
        loading_button_intervalo: true,
        active_button_intervalo: true,  
        loading_button_editar: true,
        active_button_editar: true,  
        loading_button_eliminar: true,
        active_button_eliminar: true,
        form:{ 
          inicio:'',
          fin:'',
          id_horario:'',
          dia_edit:'',
          inicio_edit:'',
          fin_edit:'',  
          parametro:'',       
          tiempo_espera:'',
          tiempo_espera_edit:'', 
          dia_no_laboral:''      
          }
      }    
    
    }

    handlerdia = (d) => {
      console.log(d)
      dia_intervalo=(d)
      //alert(id_especialidad)
    }

    handleChange=async e=>{
      await this.setState({
         form:{
              ...this.state.form,
              [e.target.name]: e.target.value
              }
         })
         console.log(this.state.form);
         //this.Selectdrop();
    }


    Campo_numerico(){
      $(".camponum").keypress(function(tecla)
      {
        if(tecla.charCode<48 || tecla.charCode>57){
         
          return false;

        }
      })
    }


    Selectdrop(){
      if(dia_intervalo=='null' && this.state.form.inicio!=''){
        $("#dia").val($('#dia > option:first').val()); 
       
      }

      if(param='' && this.state.form.tiempo_espera!=''){       
        $("#parametro").val($('#parametro > option:first').val());
      }
      
    }

    handlerSelectdrop(){
      
      $("#dia").val($('#dia > option:first').val());
      $("#parametro").val($('#parametro > option:first').val());
      $("#inicio").val("");
      $("#fin").val("");
      $("#dia_no_laboral").val("");
      $("#tiempo_espera").val("");
      $("#div_horario1").find("*").prop('disabled', false);
      $("#div_horario2").find("*").prop('disabled', false);
      $("#div_horario3").find("*").prop('disabled', false);
      $("#div_horario4").find("*").prop('disabled', false);

      dia_intervalo='null'
      param=''
     
     
    }

    Limpiar_form=()=>{
      this.setState({
        form:{          
          inicio:'',
          fin:'',
          dia_no_laboral:''   
        }
      })
     
    }

    GetParametro = (p) =>{
      //this.setState({parametro:p.target.value})
      console.log(p.target.value)
      param=(p.target.value)

      this.Traer_Parametro(param)
     
    }

    handleModalaeditarhorario()
    {      
      $('#modal-horarioedit').modal('show'); // <- to show modal editar horario
      
    } 
    
    handleModalaeditparametro()
    {      
      $('#modal-editparametro').modal('show'); // <- to show modal editar horario
      
    }
    
    handleModalagregarnolaboral()
    {      
      $('#modal-agregar_dianolaboral').modal('show'); // <- to show modal editar horario
      this.Listar_dia_nolaboral();      
      
    }

    handleModalelimina_dianolaboral()
    {      
      $('#modal-elimina_nolaboral').modal('show'); // <- to show modal elimiinar intervalo
      
    }

    Borrar_no_laboral(dia){
      id_dianolaboral  = dia.id;

      this.handleModalelimina_dianolaboral();

     }

    ModalverificaHorario(){
      
      if(this.state.Horario.length==0){

        $('#modal-verificahorario').modal('show'); // <- to show modal aviso

      }
      
    }

    seleccionar_horario=(horario)=>{
      idhorario=horario.id
      dia_intervalo_edit=horario.day
      var dia=horario.day
         if(dia=='1'){
          dia='Lunes'
        }else{
          if(dia=='2'){
            dia='Martes'
          }else{
            if(dia=='3'){
              dia='Miercoles'
            }else{
              if(dia=='4'){
                dia='Jueves'
              }else{
                if(dia=='5'){
                  dia='Viernes'
                }else{
                  if(dia=='6'){
                    dia='Sabado'
                  }else{
                    dia='Domingo'
                  }
                }
              }
            }
          }
        }
      this.setState({
        form:{
          id_horario:horario.id, 
          dia_edit:dia,
          inicio_edit:moment(horario.from).format("HH:mm"),  
          fin_edit:moment(horario.to).format("HH:mm"), 
          inicio:this.state.form.inicio,
          fin:this.state.form.fin
                
        }
      })
      //var  dato  = solicitud.user.identification;
      //alert(dato);
    }


    seleccionar_parametro=(parametos)=>{
      param=parametos.name
     
        nameparametro_edit=parametos.name
         if(nameparametro_edit=='Autorización'){
          nameparametro_edit='Autorización o soporte'
        }else{
          if(nameparametro_edit=='Cuota Moderadora'){
            nameparametro_edit='Pago de cuota moderadora'
          }else{
            if(nameparametro_edit=='Sin Respuesta'){
              nameparametro_edit='Vencimiento de solicitud de cita'
            }
          }
        }
      this.setState({
        form:{
          tiempo_espera_edit:parametos.value,
          tiempo_espera:this.state.form.tiempo_espera
                
        }
      })
      //var  dato  = solicitud.user.identification;
      //alert(dato);
    }

    
    componentDidMount(){   
      $('[data-toggle="tooltip"]').tooltip()

      $('#tabla1').hide();
      $('#tabla2').hide();
      $('#empty_page').hide();
      $('#empty_page2').hide();
      $('#text_page').hide(); 
      $('#text_page2').hide(); 
      $('#tabla_dia_nolaboral').hide();          
      $('#text_page_dianolaboral').hide();
      this.Listar_parametros();
      //this.Listar_horario_atencion();
     
           
    }


    empty_page_hide(){

      $('#tabla1').hide();  
      $('#tabla2').hide();     
      $('#empty_page').hide();
      $('#empty_page2').hide();     
      $('#text_page').hide(); 
      $('#text_page2').hide();  

    }

    Listar_dia_nolaboral(){

      axios.get(ApiURL+"/api/NotWorkingDates/ListAll")
      .then((response) => {
        console.log(response);

        this.setState({Dia_nolaboral: response.data})

        if(this.state.Dia_nolaboral.length==0){ 
          
          $('#tabla_dia_nolaboral').hide();          
          $('#text_page_dianolaboral').show();
         
        }else{

          $('#tabla_dia_nolaboral').show();          
          $('#text_page_dianolaboral').hide();
          

        }
        //this.handlerSelectdrop();
      })
      .catch((error) => {
        console.log(error);

        //this.Listar_parametros();

          $('#tabla_dia_nolaboral').hide();          
          $('#text_page_dianolaboral').show();        
     
        
      })

    }



    Listar_horario_atencion(){

      this.empty_page_hide();

      this.setState({ loading: this.state.loading = false })
      axios.get(ApiURL+"/api/WorkingHours/GetWorkingHours")
      .then((response) => {
        console.log(response);

        this.setState({Horario: response.data})

        if(this.state.Horario.length==0){                 
         
        }else{
          $('#tabla1').show();
          $('#empty_page').hide();
          $('#text_page').hide();
          

        }        

        this.setState({ loading: this.state.loading = true })
        //this.handlerSelectdrop();
      })
      .catch((error) => {
        console.log(error);

        //this.Listar_parametros();

        $('#tabla1').hide();
        $('#empty_page').show();
        $('#text_page').show(); 

        this.setState({ loading: this.state.loading = true })        

        if(this.state.Parametro.length>0){

          $('#modal-verificahorario').modal('show'); // <- to show modal aviso

        }

        
        
      })

    }

    Listar_parametros(){

      this.empty_page_hide();

      this.setState({ loading: this.state.loading = false })
      axios.get(ApiURL+"/api/ConfigParams/ListAll")
      .then((response) => {
        console.log(response);

        this.setState({Parametro:response.data})

        if(modal==0){
          this.Listar_horario_atencion()
          modal=1
        }

        if(this.state.Parametro.length==0){
                  
        }else{
          $('#tabla2').show();
          $('#empty_page2').hide();
          $('#text_page2').hide(); 

        }       

        this.setState({ loading: this.state.loading = true })
        //this.handlerSelectdrop();
      })
      .catch((error) => {
        console.log(error);

        $('#tabla2').hide();
        $('#empty_page2').show();
        $('#text_page2').show(); 
        
  
        $('#custom-tabs-three-profile-tab').click();     

        this.setState({ loading: this.state.loading = true })

        $('#modal-verificaintervalos').modal('show'); // <- to show modal aviso

        modal=1
        
      })

    }


    SaveIntevalo=async()=>{

      if (dia_intervalo=="null" || this.state.form.inicio=='' || this.state.form.fin=='' ){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{
        if(this.state.form.inicio==this.state.form.fin){

          store.addNotification({
            title: 'Atención',
            message: "La hora inicio y hora fin no pueden ser iguales",
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

        }else{
        
        this.setState({ loading_button_intervalo: this.state.loading_button_intervalo = false })
        this.setState({ active_button_intervalo: this.state.active_button_intervalo = false })

        await axios.post(ApiURL+"/api/WorkingHours/AddWorkingHour",
  
          {                 
            day: dia_intervalo,
            from: this.state.form.inicio,
            to: this.state.form.fin
          } 
          )  
  
        .then(response=>{
          console.log(response.data)
          mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
               
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button_intervalo: this.state.loading_button_intervalo = true })
          this.setState({ active_button_intervalo: this.state.active_button_intervalo = true })

          //$("#div_horario1").find("*").prop('disabled', true);
          //$("#div_horario2").find("*").prop('disabled', true);

          this.Listar_horario_atencion();
          //this.Listar_parametros();
          //this.Limpiar_form();
          
        })
        .catch(error=>{
            
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

            this.setState({ loading_button_intervalo: this.state.loading_button_intervalo = true })
            this.setState({ active_button_intervalo: this.state.active_button_intervalo = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        //console.log(error.config);
      
      })

       }

      }      
  
    }


     edit_horario=async()=>{

      this.setState({ loading_button_editar: this.state.loading_button_editar = false })
      this.setState({ active_button_editar: this.state.active_button_editar = false })

      await axios.put(ApiURL+"/api/WorkingHours/EditWorkingHour",  
        {
          id:this.state.form.id_horario,
          day:dia_intervalo_edit,
          from:this.state.form.inicio_edit,
          to:this.state.form.fin_edit,
          
        } )          
  
        .then(response=>{
          console.log(response.data)
          mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);          
                 
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button_editar: this.state.loading_button_editar = true })
          this.setState({ active_button_editar: this.state.active_button_editar = true })
          this.componentDidMount();
          this.Listar_horario_atencion();

          $('#modal-horarioedit').modal('hide'); // <- to hide modal editar horario          
          
        })
        .catch(error=>{
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

            this.setState({ loading_button_editar: this.state.loading_button_editar = true })
            this.setState({ active_button_editar: this.state.active_button_editar = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        //console.log(error.config);
      
      })
  
     }

     Borrar_intervalo(){

      $('#modal-elimina_intervalo').modal('show');

     }


     delete_intervalo=async()=>{

      this.setState({ loading_button_eliminar: this.state.loading_button_eliminar = false })
      this.setState({ active_button_eliminar: this.state.active_button_eliminar = false })
     
      axios.delete(ApiURL+"/api/WorkingHours/RemoveWorkingHour/"+idhorario)
      .then((response) => {
        console.log(response.data)
          mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);          
                 
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
          
          this.setState({ loading_button_eliminar: this.state.loading_button_eliminar = true })
          this.setState({ active_button_eliminar: this.state.active_button_eliminar = true })
          
          $('#modal-elimina_intervalo').modal('hide');
          $('#modal-horarioedit').modal('hide');
          this.componentDidMount();

        
      })
      .catch((error) => {
        console.log(error);

        this.setState({ loading_button_editar: this.state.loading_button_eliminar = true })
        this.setState({ active_button_eliminar: this.state.active_button_eliminar = true })

      })
    }


    SaveParametro=async()=>{

      if (param=='' || this.state.form.tiempo_espera=='' ){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{
        this.setState({ loading_button_intervalo: this.state.loading_button_intervalo = false })
        this.setState({ active_button_intervalo: this.state.active_button_intervalo = false })

        await axios.put(ApiURL+"/api/ConfigParams/SaveParam",
  
          {                 
            name: param,
            value: this.state.form.tiempo_espera
          } 
          )  
  
        .then(response=>{
          console.log(response.data)
          mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
               
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button_intervalo: this.state.loading_button_intervalo = true })
          this.setState({ active_button_intervalo: this.state.active_button_intervalo = true })

          this.Listar_parametros();

          //this.Listar_horario_atencion();
          
        })
        .catch(error=>{
            
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

            this.setState({ loading_button_intervalo: this.state.loading_button_intervalo = true })
            this.setState({ active_button_intervalo: this.state.active_button_intervalo = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        //console.log(error.config);
      
      })

      }      
  
    }


    EditParametro=async()=>{

      if (param=='' ||  this.state.form.tiempo_espera_edit=='0' || this.state.form.tiempo_espera_edit=='' ){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{
        this.setState({ loading_button_editar: this.state.loading_button_editar = false })
        this.setState({ active_button_editar: this.state.active_button_editar = false })

        await axios.put(ApiURL+"/api/ConfigParams/SaveParam",
  
          {                 
            name: param,
            value: this.state.form.tiempo_espera_edit
          } 
          )  
  
        .then(response=>{
          console.log(response.data)
          mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
               
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button_editar: this.state.loading_button_editar = true })
          this.setState({ active_button_editar: this.state.active_button_editar = true })

          this.Listar_parametros();

          $('#modal-editparametro').modal('hide'); // <- to show modal editar intervalo
          
        })
        .catch(error=>{
            
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

            this.setState({ loading_button_editar: this.state.loading_button_editar = true })
            this.setState({ active_button_editar: this.state.active_button_editar = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
            this.setState({ loading_button_editar: this.state.loading_button_editar = true })
            this.setState({ active_button_editar: this.state.active_button_editar = true })
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.setState({ loading_button_editar: this.state.loading_button_editar = true })
            this.setState({ active_button_editar: this.state.active_button_editar = true })
        }
        //console.log(error.config);
      
      })

      }      
  
    }


    Traer_Parametro=async(param)=>{

      if(param==''){

        store.addNotification({
          title: 'Atención',
          message: 'Seleccione un parametro',
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

        this.setState({
          form:{
            tiempo_espera: '',
            //parametro: ''               
          }
        })

      }else{

      await axios.get(ApiURL+"/api/ConfigParams/GetParam/"+param)          
  
        .then(response=>{
          console.log(response.data)
        
          this.setState({
            form:{
              tiempo_espera: response.data.value           
            }
          })
          //mensaje  = response.data.message;
          //alert(response.data.value);
         
          
        })
        .catch(error=>{
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

            this.setState({
              form:{
                tiempo_espera: ''
                //parametro: ''               
              }
            })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        //console.log(error.config);
      
      })

      }
  
     }


     SaveDiaNoLaboral=async()=>{

      if ( this.state.form.dia_no_laboral==''){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{
        if(this.state.form.dia_no_laboral.length>10){

          store.addNotification({
            title: 'Atención',
            message: "Ingrese un rango de fecha valido",
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

        }else{
          
          if(!moment(this.state.form.dia_no_laboral,'YYYY-MM-DD', true).isValid()){

            store.addNotification({
              title: 'Atención',
              message: "La fecha ingresada no es valida",
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

       }else{

        this.setState({ loading_button_intervalo: this.state.loading_button_intervalo = false })
        this.setState({ active_button_intervalo: this.state.active_button_intervalo = false })

        await axios.post(ApiURL+"/api/NotWorkingDates/Add",
  
          {                 
            date: this.state.form.dia_no_laboral,
            
          } 
          )  
  
        .then(response=>{
          console.log(response.data)
          mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
               
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button_intervalo: this.state.loading_button_intervalo = true })
          this.setState({ active_button_intervalo: this.state.active_button_intervalo = true })

          //$("#div_horario1").find("*").prop('disabled', true);
          //$("#div_horario2").find("*").prop('disabled', true);

          this.Listar_dia_nolaboral();
          //this.Listar_parametros();
          //this.Limpiar_form();
          
        })
        .catch(error=>{
            
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

            this.setState({ loading_button_intervalo: this.state.loading_button_intervalo = true })
            this.setState({ active_button_intervalo: this.state.active_button_intervalo = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        //console.log(error.config);
      
      })

       }

      }

      }      
  
    }

    EliminarNoLaboral=()=>{
     
      //var  dato  = horario.id;
      //alert(dato);

      this.setState({ loading_button_eliminar: this.state.loading_button_eliminar = false })
      this.setState({ active_button_eliminar: this.state.active_button_eliminar = false })

      axios.delete(ApiURL+"/api/NotWorkingDates/Remove/"+id_dianolaboral)
      .then((response) => {
        console.log(response.data)
          mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);          
                 
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          }) 
          
          this.setState({ loading_button_eliminar: this.state.loading_button_eliminar = true })
          this.setState({ active_button_eliminar: this.state.active_button_eliminar = true })

          $('#modal-elimina_nolaboral').modal('hide');          
          this.Listar_dia_nolaboral();
        
      })
      .catch((error) => {
        console.log(error);
      })
    }

    

    handleModalconsentsoedit()
    {
      this.setState({show2:!this.state.show})
      $('#modal-cuotasedit').modal('show'); // <- to show modal editar contrato 
    }
       

    render(){ 
      const {form} = this.state; 

     return (

     
        <div className="content-wrapper">        
  
            <div className="col-12 col-sm-12">

                <div className="row">

                    <div className="col-sm-10">   
                    <br></br>      
                       <div className="text-center" id="title"> <h6> <b> HORARIOS DE ATENCIÓN</b>  </h6> </div>          
                    </div>                   
          
                </div>
          
                <div className="card card-primary card-outline card-tabs">

                      <div className="card-header p-0 pt-1 border-bottom-0">

                        <ul className="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                          <li className="nav-item">
                            <a className="nav-link active" id="custom-tabs-three-home-tab" data-toggle="pill" href="#custom-tabs-three-home" role="tab" aria-controls="custom-tabs-three-home" aria-selected="true" onClick={()=>{this.Listar_horario_atencion();this.ModalverificaHorario()}}>Horario de respuesta a solicitudes </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" id="custom-tabs-three-profile-tab" data-toggle="pill" href="#custom-tabs-three-profile" role="tab" aria-controls="custom-tabs-three-profile" aria-selected="false" onClick={()=>{this.handlerSelectdrop();this.Listar_parametros()}}>Tiempo máximo de espera a solicitudes</a>
                          </li>                         
                         
                        </ul>
                      </div>
          
                      <div className="card-body">

                          <div className="tab-content" id="custom-tabs-three-tabContent">
                            
                              <div className="tab-pane fade show active" id="custom-tabs-three-home" role="tabpanel" aria-labelledby="custom-tabs-three-home-tab">

                                  <div className="row" id="div_horario1">
                        
                                      <div className="col-md-6">
                                            <div className="form-group">                    
                                             <label >Día:</label>
                                             <DiasSemana handlerdia={this.handlerdia}/>                                           
                                            </div>                 
                                      </div>
                                                        
                                      <div className="col-md-3">              
                                            <div className="form-group">                    
                                              <label >Inicio:</label>
                                              <input type="time" className="form-control" name="inicio" id="inicio" onChange={this.handleChange} />
                                            </div>              
                                      </div>
                                                
                                      <div className="col-md-3">
                                            <div className="form-group">                    
                                              <label >Fin:</label>
                                              <input type="time" className="form-control" name="fin" id="fin" onChange={this.handleChange}/>                    
                                            </div>                 
                                      </div>                              
                                                        
                                                                
                                  </div>                                
                                             
                                
                            
                    
                                  <div className="modal-footer">    

                                      <div id="div_horario1">

                                          <button id="btn_save"  className="btn btn-save" disabled={!this.state.active_button_intervalo} onClick={()=> this.SaveIntevalo()}> {this.state.loading_button_intervalo?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Guardar
                                                <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" class="bi bi-check2-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                   <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                                   <path fill-rule="evenodd" d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z"/>
                                                </svg>
                                          </button> 

                                      </div>

                                      <button  className="btn btn-primary" onClick={()=>{this.handlerSelectdrop();this.Limpiar_form()} } > Nuevo
                                                <svg width="1.2em" height="1.2em" viewBox="3 3 10 10" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                 <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                </svg>
                                      </button>

                                      <button  className="btn btn-default" onClick={()=>{this.handleModalagregarnolaboral();this.handlerSelectdrop();this.Limpiar_form()}} > Dias no laborales
                                                <svg width="1.2em" height="1.2em" viewBox="3 3 10 10" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                 <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                </svg>
                                      </button>
                                     
                                  </div> 

                                  <div className="d-flex justify-content-center">
                            
                                      {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
                                  
                                  </div> 

                                  <div className="card table-responsive" id="tabla1">   
                                       
                                        
                                      <table id="tabla_agendas" className="table table-bordered table-striped table-hover">
                                        <thead>
                                          <tr>
                                            <th>ID</th>
                                            <th>Día</th>
                                            <th>Inicio</th>
                                            <th>Fin</th>
                                            <th>Editar</th>        
                                          </tr>
                                        </thead>
    
                                        <tbody>
                                             {this.state.Horario.map(horario=>{
                                               var dia=horario.day
                                                 if(dia=='1'){
                                                  dia='Lunes'
                                                }else{
                                                  if(dia=='2'){
                                                    dia='Martes'
                                                  }else{
                                                    if(dia=='3'){
                                                      dia='Miercoles'
                                                    }else{
                                                      if(dia=='4'){
                                                        dia='Jueves'
                                                      }else{
                                                        if(dia=='5'){
                                                          dia='Viernes'
                                                        }else{
                                                          if(dia=='6'){
                                                            dia='Sabado'
                                                          }else{
                                                            dia='Domingo'
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                               return(
                                            
                                               <tr>
                                                 <td>{horario.id}</td>          
                                                 <td>{dia}</td> 
                                                 <td>
                                                  <Moment format="HH:mm">
                                                    {horario.from}
                                                  </Moment>
                                                 </td>                                                     
                                                 <td><Moment format="HH:mm">
                                                    {horario.to}
                                                  </Moment>
                                                 </td> 
                                                 <td onClick={()=>{this.handleModalaeditarhorario();this.seleccionar_horario(horario)}}> <BtnEdit/>  </td>                                                
                                                 
                                               </tr>
                                               )
                                              }
                                            )}                                                            
                                                                               
                                        </tbody>
                                        
                                      </table>                                     
                            
                
                                  </div>  

                                  <div className="d-flex justify-content-center">                              
                                       <img id="empty_page" src={emptypage} alt="logo"  width= "200"/>                             
                                  </div> 
                                  
                                  <div className="d-flex justify-content-center">                  
                                       <h5 id="text_page"> <b>No hay información que mostrar</b> </h5>                          
                                  </div>                     
                             
                                                              
                      
                              </div>
                            
                              <div className="tab-pane fade" id="custom-tabs-three-profile" role="tabpanel" aria-labelledby="custom-tabs-three-profile-tab">
                                
                                  <div className="row" id="div_horario3">
                                      
                                      <div className="col-md-6">              
                                          <div className="form-group">                    
                                                  <label >Opciones a configurar:</label>
                                                  <select className="form-control" name="parametro" id="parametro" onChange={this.GetParametro}>
                                                    
                                                    <option  value="">Seleccione...</option>
                                                    <option  value="Autorización">Documentos o soportes</option>
                                                    <option  value="Cuota Moderadora">Pago de cuota moderadora y consulta particular</option>  
                                                    <option  value="Sin Respuesta">Vencimiento de solicitudes de cita</option>                                                  
                                            
                                                  </select>
                                          </div>              
                                      </div>
                                                        
                                      <div className="col-md-6">
                                          <div className="form-group">                    
                                            <label >Tiempo de espera (horas):</label>
                                            <input type="text" className="form-control camponum" maxLength="2" name="tiempo_espera" id="tiempo_espera" value={form.tiempo_espera} onChange={this.handleChange} onKeyDown={this.Campo_numerico()} />                                            
                                          </div>                 
                                      </div>                            
                                                  
                                  </div>   
      
                                  <div className="modal-footer"> 

                                      <div  id="div_horario4"> 

                                        <button id="btn_save"  className="btn btn-save" disabled={!this.state.active_button_intervalo} onClick={()=> this.SaveParametro()}> {this.state.loading_button_intervalo?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Guardar
                                         <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" class="bi bi-check2-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                            <path fill-rule="evenodd" d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z"/>
                                         </svg>
                                        </button>

                                      </div>

                                        <button  className="btn btn-primary" onClick={()=> this.handlerSelectdrop()} > Nuevo
                                                <svg width="1.2em" height="1.2em" viewBox="3 3 10 10" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                 <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                </svg>
                                        </button>
                                  </div>

                                  <div className="d-flex justify-content-center">
                            
                                      {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
                                  
                                  </div> 

                                  <div className="card table-responsive" id="tabla2">   
                                       
                                        
                                      <table id="tabla_agendas" className="table table-bordered table-striped table-hover">
                                        <thead>
                                          <tr>
                                            <th>ID</th>
                                            <th>Parmetro</th>
                                            <th>Tiempo de espera (horas)</th>
                                            <th>Editar  </th>
                                          </tr>
                                        </thead>
    
                                        <tbody>
                                             {this.state.Parametro.map(parametos=>{

                                               var nom_parametro=''

                                               if(parametos.name=='Autorización'){

                                                nom_parametro='Autorización o soporte'

                                               }else{

                                                 if(parametos.name=='Cuota Moderadora'){

                                                  nom_parametro='Pago de cuota moderadora y consulta particular'

                                                 }else{
                                                  
                                                   if(parametos.name=='Sin Respuesta'){

                                                    nom_parametro='Vencimiento de solicitud de cita'

                                                   }
                                                 }
                                               }
                                               
                                               return(
                                            
                                               <tr>
                                                 <td>{parametos.id}</td>          
                                                 <td>{nom_parametro}</td> 
                                                 <td>{parametos.value}</td>                                       
                                                 <td onClick={()=>{this.handleModalaeditparametro();this.seleccionar_parametro(parametos)}}> <BtnEdit  /> </td>
                                               </tr>
                                               )
                                              }
                                            )}                                                            
                                                                               
                                        </tbody>
                                        
                                      </table>                                     
                            
                
                                  </div>  

                                  <div className="d-flex justify-content-center">                              
                                       <img id="empty_page2" src={emptypage} alt="logo"  width= "200"/>                             
                                  </div>

                                  <div className="d-flex justify-content-center">                  
                                       <h5 id="text_page2"> <b>No hay información que mostrar</b> </h5>                          
                                  </div>
                      
                              </div>
                    
                          </div>                          
                         
                      </div>

                </div>             
            
            </div>
  
  
            <div className="modal fade" id="modal-horarioedit">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title"><b>Editar horario</b></h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    
                        <div className="row">
            
            
                              <div className="col-md-6">
                                    <div className="form-group">                    
                                      <label >ID:</label>
                                      <input type="text" className="form-control" name="id_horario" value={form.id_horario} readOnly />                      
                                    </div>                 
                              </div>
            
                              <div className="col-md-6">
                                    <div className="form-group">                    
                                      <label >Día:</label>
                                      <input type="text" maxLength="20" className="form-control" name="dia_horario" value={form.dia_edit} readOnly/>                  
                                    </div>                 
                              </div>
            
                              <div className="col-md-6">              
                                    <div className="form-group">                    
                                      <label >Inicio:</label>
                                      <input type="time" className="form-control" name="inicio_edit" onChange={this.handleChange} value={form.inicio_edit} />
                                    </div>              
                              </div>
                                                
                              <div className="col-md-6">
                                    <div className="form-group">                    
                                      <label >Fin:</label>
                                      <input type="time" className="form-control" name="fin_edit" onChange={this.handleChange} value={form.fin_edit}/>                    
                                    </div>                 
                              </div>
            
                        </div>
                           
                       
                      </div>
            
                  <div className="modal-footer justify-content-between">
                    <button type="button" className="btn btn-primary"  disabled={!this.state.active_button_editar} onClick={()=> this.edit_horario()}> {this.state.loading_button_editar?false:<ReactBootStrap.Spinner animation="border" size="sm" />}Guardar</button>
                    <button type="button" id="btn_save" className="btn btn-save" onClick={()=> this.Borrar_intervalo()}>Eliminar</button>
                    <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>        
                  </div>
                  
                </div>
              
              </div>
             
            </div> 


            <div className="modal fade" id="modal-editparametro">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title"><b>Editar intervalo</b></h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
        
                    <div className="row">
        
                          <div className="col-md-8">
                                <div className="form-group">                    
                                          <label >Parametro:</label>
                                          <input type="text" className="form-control" name="nameparametro_edit" value={nameparametro_edit} readOnly />                      
                                </div>                 
                          </div>
        
                          <div className="col-md-4">
                          
                                <div className="form-group">                    
                                          <label >Tiempo de espera:</label>
                                          <input type="text" maxLength="2" className="form-control camponum" id="tiempo_espera_edit" name="tiempo_espera_edit" value={form.tiempo_espera_edit} onChange={this.handleChange} onKeyDown={this.Campo_numerico()}/>                       
                                </div>
                          
                          </div>              
                      
                      
                    </div>
        
               
                 
                  </div>
        
                        <div className="modal-footer justify-content-between">        
                           <button type="button" className="btn btn-primary" disabled={!this.state.active_button_editar} onClick={()=> this.EditParametro()}> {this.state.loading_button_editar?false:<ReactBootStrap.Spinner animation="border" size="sm" />}Guardar</button>
                           <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>
                        </div>
                </div>
              
              </div>
            
            </div> 

            <div className="modal fade" id="modal-agregar_dianolaboral"  data-backdrop="static">
                <div className="modal-dialog modal-lg ">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title"><b>Agregar dia no laboral</b><spam  data-toggle="tooltip" data-placement="top" title="Un día no laboral puede ser un festivo o que no es un día hábil; una fecha del calendario que resalta de forma especial y habitualmente suele ser feriado." > <Info/> </spam></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    
                          <div className="modal-body">                    
                                
                              <div className="row">
                                    
                                  <div className="col-md-12">
                                                   
                                        <div className="form-group">                    
                                              <label >Dia no laboral:</label>
                                              <input type="date" className="form-control" id="dia_no_laboral" name="dia_no_laboral" onChange={this.handleChange}/>            
                                        </div>
                                  </div>
                        
                              </div>

                           
                              <div className="row">
                                  
                                    <div className="col-md-12">
                                       
                                        <div className="card table-responsive">
                                 
                                           {/* /.card-header */}
                                           <div className="card-body">
                                              <table id="tabla_dia_nolaboral" className="table table-bordered table-striped table-hover">
                                                <thead>
                                                  <tr>
                                                    <th>ID</th>
                                                    <th>Día</th>                                                                                         
                                                    <th>Eliminar</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {this.state.Dia_nolaboral.map(dia=>{
                                                     

                                                    return(      
                                                     
                                                    <tr>          
                                                       <td>{dia.id}</td>                                  
                                                       <td>
                                                        <Moment format="YYYY/MM/DD">
                                                        {dia.date}
                                                        </Moment>
                                                       </td> 
                                                       <td  onClick={()=>{this.Borrar_no_laboral(dia)}} > <BtnDelete />  </td>
                                                    </tr>
                                             
                                                        )
                                                      }
                                                    )
                                                  }                
                                                </tbody>
                                              </table>
                                              <div className="d-flex justify-content-center">                  
                                                  <h5 id="text_page_dianolaboral"> <b>No hay información que mostrar</b> </h5>                          
                                              </div>
                                           </div>
                                           
                                        </div>            
                 
                                    </div>

                              </div>                            
                        

                              <div className="modal-footer justify-content-between">        
                                   <button type="button" className="btn btn-primary" disabled={!this.state.active_button_intervalo} onClick={()=> this.SaveDiaNoLaboral()}> {this.state.loading_button_intervalo?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Guardar</button>
                                   <button type="button" className="btn btn-danger" data-dismiss="modal" >Cerrar</button>
                              </div>

                          </div>
                 
                  </div>
               
                </div>

              </div>


            <div className="modal fade" id="modal-verificahorario" data-backdrop="static">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header" id="fondo_modal_compra">
                      <h4 className="col-11 modal-title text-center" id="correo_activacion"><b>Importante</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
    
                    <div className="modal-body">

                          
                          {/* /<span id="alert-recoverypass"> </span> */}
                      
                          <div className="row justify-content-md-center" id="mensaje_recupera"> 

                              <div className="col-md-12">

                               Horario de respuesta a solicitudes
                               <br></br>
                               Estas serán las horas hábiles con las que se validarán los tiempos máximos de espera a solicitudes, de acuerdo a la configuración establecida por el prestador de salud.

                              </div>
                                                  
                          
                          </div>

                          <br/>                      
                              
                    </div>             

                  </div>
                  
                </div>
                
            </div>


            <div className="modal fade" id="modal-verificaintervalos" data-backdrop="static">
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header" id="fondo_modal_compra">
                      <h4 className="col-11 modal-title text-center" id="correo_activacion"><b>Importante </b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
    
                    <div className="modal-body">

                          
                          {/* /<span id="alert-recoverypass"> </span> */}
                      
                          <div className="row justify-content-md-center" id="mensaje_recupera"> 

                              <div className="col-md-12">

                              Tiempo máximo de espera a solicitudes. 
                              <br/><br/>
                              Este parámetro define el plazo para que el prestador de salud responda a las solicitudes de citas enviadas por los pacientes. También establece el límite de tiempo para que los pacientes respondan a los requerimientos que el prestador de salud envía en la respuesta de las solicitudes de citas.
                              <br/><br/>
                              Existen tres opciones para configurar el tiempo máximo de espera a solicitudes:
                              <br/><br/>
                              1.	Vencimiento de solicitudes de cita: tiempo en el cual, el prestador de salud, deberá responder las solicitudes de citas enviadas por los pacientes. Superado este plazo, si las solicitudes de citas no fueron atendidas, caducarán automáticamente; sin embargo, si las solicitudes de citas, fueron enviadas en horas no hábiles, quedarán en pausa y no les aplicará el tiempo de vencimiento hasta que vuelva a entrar en vigencia el horario de respuesta a solicitudes del prestador de salud. Si no se configura este parámetro, el sistema establecerá el tiempo de vencimiento en 6 horas.
                              <br/><br/>
                              2.	Documentos o soportes: es el tiempo que el prestador de salud le dará al paciente para que este realice el envío de documentos en pdf o imágenes, los cuales sean solicitados cuando se determine que el soporte es indispensable para asignar la cita solicitada.
                              <br/><br/>
                              3.	Pago de cuota moderadora y consulta particular: tiempo que el prestador de salud le dará al paciente para que realice el pago del valor liquidado de acuerdo al servicio solicitado. El valor de la cuota moderadora se calcula de acuerdo al régimen al que pertenezca el paciente y su nivel de afiliación. El valor de la consulta particular es el determinado por el prestador al momento de crear el servicio asociado al convenio del médico.

                              </div>
                                                  
                          
                          </div>

                          <br/>                      
                              
                    </div>             

                  </div>
                  
                </div>
                
            </div>


            <div className="modal fade" id="modal-elimina_intervalo" data-backdrop="static">

                <div className="modal-dialog modal-sm">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title"><b>Eliminar horario</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
  
                          <div className="col-md-12">
  
                            ¿Seguro que deseas eliminar el horario laboral?
  
                          </div>
                                 
                    </div>
          
                          <div className="modal-footer justify-content-between">        
                             <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={()=> this.delete_intervalo()}>Si</button>
                             <button type="button" className="btn btn-danger" data-dismiss="modal">No</button>
                          </div>
                  </div>                
                </div>
            
            </div>

            <div className="modal fade" id="modal-elimina_nolaboral" data-backdrop="static">

                <div className="modal-dialog modal-sm">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title"><b>Eliminar dia no laboral</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
  
                          <div className="col-md-12">
  
                            ¿Seguro que deseas eliminar el dia no laboral?
  
                          </div>
                                 
                    </div>
          
                          <div className="modal-footer justify-content-between">        
                             <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.state.active_button_eliminar} onClick={()=> this.EliminarNoLaboral()}> {this.state.loading_button_eliminar?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Si</button>
                             <button type="button" className="btn btn-danger" data-dismiss="modal">No</button>
                          </div>
                  </div>                
                </div>
            
            </div>
  
  
        </div>      
      
    )

  }
  
}


export default Horarios_Atencion;