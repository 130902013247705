import React, {Component} from 'react';
import axios from 'axios';
import * as ReactBootStrap from 'react-bootstrap';
import '../tools/css/Crear_Usuarios.css';
import '../tools/css/Home.css'
import TipoID from '../API/TipoID';
import BtnSave from '../components/BtnSave';
import BtnCancel from '../components/BtnCancel';
import BtnEdit from '../components/BtnEdit';

import emptypage from '../tools/images/emptypage.png';
import Logo_nuevo from '../tools/images/Nuevo';
import Logo_edit from '../tools/images/Edit';

import $ from 'jquery'; // <-to import jquery
import 'bootstrap';

import ReactNotifications from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';


const ApiURL="https://medicallapi.azurewebsites.net";
var mensaje='';
var tipo_id='null';
var usuarioid=0;
var usuarioid_edit=0;
var btn_activar='';

class Crear_Usuarios extends Component {

  constructor()
    {
     super()
      this.state={
        show:false,                
        Datos: [],
        Tipo_id:[],
        loading: false,
        loading_button: true,
        active_button: true,
        form:{ 
          nameconsent: '',
          descrip: '',           
          id_consen_edit:'',  
          nombre_consen_edit:'',   
          nom_usua:'',
          descrip_edit:'',
          id_usuario:'',
          usuarioid_edit:'',
          id_usuario_edit:'',
          nom_usua_edit:''
          }
      }   
    
    }

    handleChange=async e=>{
      await this.setState({
         form:{
              ...this.state.form,
              [e.target.name]: e.target.value
              }
         })
         console.log(this.state.form);
         this.Selectdrop();
    }


    Campo_numerico(){
      $(".camponum").keypress(function(tecla)
      {
        if(tecla.charCode<48 || tecla.charCode>57){
         
          return false;

        }
      })
    }


    Selectdrop(){
      
      if(tipo_id=='null' && this.state.form.id_usuario!='' ){
        $("#id_tipoid").val($('#id_tipoid > option:first').val());        
      }

    }


    handlerSelectdrop=async()=>{
      
      $("#id_tipoid").val($('#id_tipoid>option:first').val());
      $("#id_usuario").val("");
      $("#nom_usua").val("");
      $("#div_usuario1").find("*").prop('disabled', false);
      $("#div_usuario2").find("*").prop('disabled', false);
      
      tipo_id='null';
      usuarioid=0
      usuarioid_edit=0

      this.Limpiar_form();
     
    }

    Limpiar_form=()=>{
      this.setState({
        form:{          
          id_usuario:'',
          nom_usua:''
        }
      })
     
    }


    GettipoID = (id) =>{
      //this.setState({tipoid:id.target.value})
      console.log(id.target.value)
      tipo_id=(id.target.value)     
    }

    handleModalusuarioedit()
    {     
      $('#modal-usuarioedit').modal('show'); // <- to show modal editar contrato 
    }

   

    empty_page_hide(){
      $('#tabla1').hide();     
      $('#empty_page').hide();
      $('#text_page').hide();
    }


    componentDidMount(){  
      
      $('[data-toggle="tooltip"]').tooltip()
      this.Cargar_tipo_id();

    }


    _handleKeyDown = (e) => {
      this.Campo_numerico();
      if (e.key === 'Enter' || e.key=='Tab') {
       
        this.Buscar_Usuario()
        
      }
    }


    Cargar_tipo_id=async()=>{    
    
      await axios.get(ApiURL+"/api/TypeIdentification/ListTypeIdentification/paciente")
        .then((response) => {
          console.log(response);
          this.setState({Tipo_id: response.data})
        })
        .catch((error) => {
          console.log(error);
        })
      }


    Buscar_Usuario=async()=>{

      if(tipo_id=='null' || this.state.form.id_usuario.length==0){

        store.addNotification({
          title: 'Atención',
          message: "Los campos tipo de ID y/o identificación están vacios!!!",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        axios.post(ApiURL+"/api/Users/GetUser",
        {
        identification: this.state.form.id_usuario,
        typeId: tipo_id
        
        })  
      .then(response=>{
        console.log(response.data)
        var  mensaje  =response.data.user.id +'-'+ response.data.user.name1 +' '+ response.data.user.name2 +' '+ response.data.user.lastname1 +' '+ response.data.user.lastname2;
        usuarioid=response.data.user.id
        //alert(mensaje);
        this.setState({
          form:{      
            nom_usua:response.data.user.name1.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) +' '+ response.data.user.name2.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) +' '+ response.data.user.lastname1.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) +' '+ response.data.user.lastname2.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))                    
          }
        })
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
      

        this.setState({ buscar_pcte: this.state.buscar_pcte = true })       
        
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          mensaje=error.response.data.message
          
          store.addNotification({
            title: 'Atención',
            message: mensaje,
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
      }
      //console.log(error.config);
    
    })

      }
  
    }

    Listar_Usuarios(){ 

      this.empty_page_hide(); 
      
      this.setState({ loading: this.state.loading = false })
    
      axios.get(ApiURL+"/api/Institutions/GetUsersInstitution")
      .then((response) => {
        console.log(response);
        this.setState({Datos: response.data})

        if(this.state.Datos.length==0){
          $('#tabla1').hide();
          $('#empty_page').show();
          $('#text_page').show();
        }else{
          $('#tabla1').show();
          $('#empty_page').hide();
          $('#text_page').hide();
        }

        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      })
    }



    seleccionarusuario=(usuario)=>{
      usuarioid_edit=usuario.user.id 
      var Cheked=usuario.state

      //alert(this.state.Cheked)     

      this.setState({
        form:{  
          id_usuario_edit:usuario.user.typeId +'-'+ usuario.user.identification,
          nom_usua_edit:usuario.user.name1.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) +' '+ usuario.user.name2 + ' '+ usuario.user.lastname1 + ' '+ usuario.user.lastname2         
        }
      })

      if(usuario.state=='inactivo'){
        btn_activar=<div className="switch-button">                                      
        <input type="checkbox" name="switch-button" id="switch-label" className="switch-button__checkbox" onClick={()=> this.editausuario()} />
        <label htmlFor="switch-label" className="switch-button__label" />
      </div>
      }else{
        if(usuario.state=='activo'){
          btn_activar=<div className="switch-button">                                      
          <input type="checkbox" name="switch-button" id="switch-label" className="switch-button__checkbox" onClick={()=> this.editausuario()} checked />
          <label htmlFor="switch-label" className="switch-button__label" />
        </div>
        }       
      }

      
      //var  dato  = solicitud.user.identification;
      //alert(usuarioid_edit);
    }



    Agregar_Usuario=async()=>{

      if(usuarioid==0 ){

        store.addNotification({
          title: 'Atención',
          message: "No se ha encontrado información del usuario, por favor verifique y vuelva a intentarlo.",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ active_button: this.state.active_button = false })

        await axios.post(ApiURL+"/api/Institutions/AddUserToInstitution",
  
          {
          userId: usuarioid          
          } )  
  
        .then(response=>{
          console.log(response.data)
          mensaje  = response.data.message;
         
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

          $("#div_usuario1").find("*").prop('disabled', true);
          $("#div_usuario2").find("*").prop('disabled', true);

          this.Limpiar_form();
          
        })
        .catch(error=>{
            
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        //console.log(error.config);
      
      })

      }
      
  
    }


    editausuario=async()=>{

      if(usuarioid_edit=='0' ){

        store.addNotification({
          title: 'Atención',
          message: "No ha ingresado información valida del usuario... verifique!!!",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ active_button: this.state.active_button = false })

        await axios.put(ApiURL+"/api/Institutions/DisableUserInstitution",
  
          {
          userId: usuarioid_edit        
          } )  
  
        .then(response=>{
          console.log(response.data)
          mensaje  = response.data;
         
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

          this.Listar_Usuarios();
          $('#modal-usuarioedit').modal('hide'); // <- to hide modal inactivar usuario
          
        })
        .catch(error=>{
            
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        //console.log(error.config);
      
      })

      }
      
  
    }
  

    render(){  
      const {form} = this.state;

     return (

      <div className="content-wrapper">
   

            <div className="col-12 col-sm-12">
                <div className="row">
          
                    <div className="col-sm-12">           
                    <br></br>
                       <div className="text-center" id="title"> <h6> <b> CREAR USUARIOS OPERADORES DEL PORTAL</b>  </h6> </div>
          
                    </div>  
          
                </div>
          
                <div className="card card-primary card-outline card-tabs">
                    <div className="card-header p-0 pt-1 border-bottom-0">
                      <ul className="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                        <li className="nav-item">
                          <a className="nav-link active" id="custom-tabs-three-home-tab" data-toggle="pill" href="#custom-tabs-three-home" role="tab" aria-controls="custom-tabs-three-home" aria-selected="true" onClick={()=>{this.handlerSelectdrop()}}>Crear usuario <Logo_nuevo/>  </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" id="custom-tabs-three-profile-tab" data-toggle="pill" href="#custom-tabs-three-profile" role="tab" aria-controls="custom-tabs-three-profile" aria-selected="false" onClick={()=>{this.Listar_Usuarios()}}>Editar usuario <Logo_edit/></a>
                        </li>
                       
                       
                      </ul>
                    </div>
                    <div className="card-body">
                      <div className="tab-content" id="custom-tabs-three-tabContent">
                        <div className="tab-pane fade show active" id="custom-tabs-three-home" role="tabpanel" aria-labelledby="custom-tabs-three-home-tab">
                          
                            <div className="card">                 
                  
                                <div className="row" id="div_usuario1">
                      
                                    <div className="col-md-4">
                                        <div className="form-group">                    
                                              <label >Tipo ID:</label>                                                          
  
                                              <select onChange={this.GettipoID}  className="form-control" id="id_tipoid" name="id_tipoid"  >
                                              
                                                  <option  value="0"> Seleccione...</option> ,
                                                  {this.state.Tipo_id.map(item=>                              
                                                  <option key={item.code} value={item.code}>  {item.description ? (item.description).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' }  </option>  
                                                  )}
                                                        
                                              </select>                                                  
                                        </div>                             
                                    </div>
                                                      
                                    <div className="col-md-4">            
                                        <div className="form-group">                    
                                                <label >Identificación:</label>
                                                <input type="text" maxLength="10" className="form-control camponum" data-toggle="tooltip" data-placement="top" title="Presion la tecla Enter o Tab para consultar" placeholder="Identificación" name="id_usuario" id="id_usuario" onChange={this.handleChange} onKeyDown={this._handleKeyDown}/>                                                          
                                        </div>                    
                                    </div> 

                                    <div className="col-md-4">
                                        <div className="form-group">                    
                                             <label >Nombre:</label>
                                             <input type="text" className="form-control"  placeholder="Nombre del usuario" name="nom_usua" id="nom_usua" value={form.nom_usua} readOnly/>                       
                                        </div>
                                    </div>                             
                                                      
                                                              
                                </div>                
                               
                  
                                <div className="modal-footer"> 

                                    <div id="div_usuario2">

                                        <button id="btn_save"  className="btn btn-save" disabled={!this.state.active_button} onClick={()=> this.Agregar_Usuario()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Guardar
                                          <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" class="bi bi-check2-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                             <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                             <path fill-rule="evenodd" d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z"/>
                                          </svg>
                                        </button> 

                                    </div>
                                    <button  className="btn btn-primary" onClick={()=> this.handlerSelectdrop()} > Nuevo
                                           <svg width="1.2em" height="1.2em" viewBox="3 3 10 10" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                              <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                           </svg>
                                    </button>
                                </div>               
                    
                         
                            </div>                         
                
                        </div>
                        
                        <div className="tab-pane fade" id="custom-tabs-three-profile" role="tabpanel" aria-labelledby="custom-tabs-three-profile-tab">
                          
                            <div class="d-flex justify-content-center">                                                
                                {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}                                                                  
                            </div>
                            
                            <div className="card table-responsive" id="tabla1">               
                
                                <table className="table table-bordered table-striped">
                                    <thead>
                                      <tr>
                                        <th>ID</th>
                                        <th>Identificación</th>
                                        <th>Usuario</th>
                                        <th>Correo</th>    
                                        <th>Estado</th>      
                                        <th>Activar/Inactivar</th>          
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.Datos.map(usuario=>{
                                        
                                      return(
                              
                                      <tr>
                                        <td>{usuario.id}</td>
                                        <td>{usuario.user.typeId} - {usuario.user.identification}</td>
                                        <td>{(usuario.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))} {(usuario.user.name2)} {(usuario.user.lastname1)} {(usuario.user.lastname2)} </td>
                                        <td>{usuario.user.email}</td>   
                                        <td>{(usuario.state).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>             
                                        <td onClick={()=>{this.handleModalusuarioedit();this.seleccionarusuario(usuario)}}> <BtnEdit  />
                                        </td>
                                      </tr>
                                      )
                                     }
                                   )}
                                      
                                                     
                                    </tbody>

                                </table>                                   
                    
                            </div>

                            <div className="d-flex justify-content-center">                              
                                <img id="empty_page" src={emptypage} alt="logo"  width= "200"/>                             
                            </div>

                            <div className="d-flex justify-content-center">                  
                               <h5 id="text_page"> <b>No hay información que mostrar</b> </h5>                          
                            </div>
                  
                        </div>
                
                
                      </div>
                        
                       
                    </div>

                </div>
    
  
          </div>


          <div className="modal fade" id="modal-usuarioedit">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title"><b>Activar/Inactivar usuario</b></h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>

                <div className="modal-body">
                  
                      <div className="row">
          
                            <div className="col-md-6">
                                  <div className="form-group">                    
                                    <label >ID:</label>
                                    <input type="text" className="form-control" name="id_usuario_edit" value={form.id_usuario_edit} readOnly />                      
                                  </div>                 
                            </div>
          
                            <div className="col-md-6">
                                  <div className="form-group">                    
                                    <label >Usuario:</label>
                                    <input type="text" className="form-control" name="nom_usua_edit" value={form.nom_usua_edit} readOnly />                      
                                  </div>                 
                            </div>
          
                      </div> 

                      <div className="row">          
                          
                            <div className="col-md-6">
                                <label >Inactivar/Activar:</label>
                                <div className="form-group">                                    
                                    {btn_activar}
                                </div>
                            </div>         
                            
          
                      </div>

                </div>
          
                
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
          {/* /.modal */}


      </div>      
      
    )

  }
  
}


export default Crear_Usuarios;