import React, {Component, useState} from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import * as ReactBootStrap from 'react-bootstrap';
import Timer from 'react-compound-timer'
import  * as signalR from '@aspnet/signalr';
import '../tools/css/Home.css';
import emptypage from '../tools/images/carpeta.gif';
import Sound from '../components/Sound';

import Moment from 'react-moment';
import 'moment/locale/es';
import moment from "moment";
import Regimen from '../API/Regimen';
import Eps from '../API/Eps';
import Tipo_Afiliacion from '../API/Tipo_Afiliacion';
import TipoID from '../API/TipoID';
import Profesionales from '../API/Profesionales';
import Especialidad from '../API/Especialidad';
import Contract from '../API/Contract';
import BtnAdd from '../components/BtnAdd';
import BtnDownload from '../components/BtnDownload';
import BtnDelete from '../components/BtnDelete';
import BtnHistory from '../components/BtnHistory';
import img from '../tools/images/search.svg';
import Calendar from '../components/Calendar';
import Logo_clock from '../tools/images/Clock';
import Logo_clockeart from '../tools/images/ClockEart';
import Logo_nuevo from '../tools/images/Nuevo';
import Logo_agenda from '../tools/images/Agenda';
import Logo_rechaza from '../tools/images/Rejected';
import Buscar from '../tools/images/Buscar';

import '../tools/css/Citas.css';

import BtnCancel from '../components/BtnCancel';

import ReactNotifications from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';

import $ from 'jquery'; // <-to import jquery
import 'bootstrap';


const ApiURL="https://medicallapi.azurewebsites.net";
const Token =sessionStorage.getItem('token');

var respuesta='false';
var id_nivel=null;
var mensaje='';
var mensaje_rta='';
var tipo_id=null;
var id_profe='0';
var id_regi=null;
var id_afilia=null;
var idcontra=null;
var fecha_soli='null'
var patientid='0';
var id_entity='0';
var id_especialidad='0';
var diaagenda="";
var rcauota='';
var rautoriza='';
var racepta_autoriza='';
var buscar_cita='';
var buscar_solicitada='';
var buscar_enproceso='';
var buscar_rechazada='';
var buscar_cancelada='';
var tipo=null;
var tipo_consulta='0';
var adjunto='';
var fecha=new Date;
var vlrcm=0;
var soli_proceso=""
var soli_pendientes=""
var id_cita_cancela=0;
var id_sede='0';
var tipo_filtro='';
var institution_id =sessionStorage.getItem('id_institution');
var id_tipo_servi=0;
var tipo_servicio_cita=0;


class Citas extends Component {



  constructor()
    {
     super()
     
      this.state={
        show:false, 
          Contrato: [],
          Contrato_particular: [],
          Citas:[],
          Sedes:[] , 
          Sedes_filtro:[] ,
          Tipo_consulta:[] , 
          Tipo_id:[],
          Pager_Citas: [],
          pager_infocitas:'',         
          Citas_soli:[],
          Pager_Citas_soli: [],
          pager_infocitas_soli:'',
          Citas_proceso:[],
          Pager_Citas_proceso: [],
          pager_infocitas_proceso:'',
          Citas_rechazadas:[],          
          Pager_Citas_rechazadas: [],
          pager_infocitas_rechazadas:'',
          Citas_canceladas:[],          
          Pager_Citas_canceladas: [],
          pager_infocitas_canceladas:'',
          Historial_citas:[],
          Especialidades:[],
          Dias_agenda:[],
          Hora_citas:[],
          Autorizacion:[],  
          Servicios: [],                  
          loading: false,
          loading_button: true,
          loading_dias_agenda:true,
          active_button: true,
          active: true,
          button_rta_cita: false,
          loading_button_cancelacita: true,
          active_button_cancelacita: true,
          estado_sonido:true,
          loading_btnhis: true,
          active_btnhis: true,
          tipoid: <TipoID handleridtipoid={this.handleridtipoid}/>,
          profesionales: [],
          Regimen: [],
          regime: <Regimen handleridregimen={this.handleridregimen}/>,
          tipo_afilia: <Tipo_Afiliacion handleridafiliacion={this.handleridafiliacion}/>,
          Afiliacion: [],
          contratos: <Contract handleridcontra={this.handleridcontra}/>,
          buscar_pcte: false,
          valor_estado:false,
          DataTable_solcitudes:true,
          cuota_mod:'0',
          pago_particular:'',
          nivel:'0', 
          med:'0',
          espe:'0',
          tipoid:'null',
          id_contrato:'null',
          id_regimen:'null',
          dia_agenda:'0',   
          idafilia:'0',
          currentDateTime: Date().toLocaleString(),
          
        form:{ 
          id_contra:'',
          fecha_cita:'',
          fecha_deseada_cita:'',         
          id_pacie:'',
          nom1_pacien:'',
          nom2_pacien:'',
          apell1_pacien:'',
          apell2_pacien:'',
          id_cita:'',
          fecha_solicita:new Date,
          id_pacien_soli:'',
          fecha_naci:'',
          dir_pcte:'',
          tel_pcte:'',
          eps_pcte:'',
          regimen_pcte:'',
          responsable_pcte:'',
          telresponsable_pcte:'',
          parentescoresp_pcte:'',
          edad_pcte:'',
          genero_pcte:'',
          departamento_pct:'',
          municipio_pcte:'',
          tipoid_pacien_hc:'',
          id_pacien_hc:'',
          nom_pcte_soli:'',
          obser:'',
          nom1medico_soli:'',
          nom2medico_soli:'',
          apell1medico_soli:'',
          apell2medico_soli:'',
          espe_soli:'',
          id_autorizacion:'',
          Rcuota_mod:'',
          Rautorizacion:'',
          Racepta_autori:'',
          obser_autorizacion:'',
          buscar_citas:'',
          buscar_solicitadas:'',
          buscar_enprocesos:'',
          buscar_rechazadas:'' ,
          buscar_canceladas:'' ,
          dife:0            
          }
      }
    }

    handleChange= e=>{
      this.setState({
         form:{
              ...this.state.form,
              [e.target.name]: e.target.value
              }
         })
         console.log(this.state.form);
    }

    Campo_numerico(){
      $(".camponum").keypress(function(tecla)
      {
        if(tecla.charCode<48 || tecla.charCode>57){
         
          return false;

        }
      })
    }



    handleModalagregarpcte()
    {      
      $('#modal-addcita').modal('show'); // <- to show modal editar contrato       
    }

    

    handleModalrespuestacita(cita)
    {  
      if(cita.contract.entityId=='999'){
        idcontra=this.state.Contrato_particular[0].id
       
        $('#modal-respuestacita-particular').modal('show'); // <- to show modal

      }else{
        $('#modal-respuestacita').modal('show'); // <- to show modal 
      }
           
    }



    handleModalrespuestacitadrop()
    {
      $('#modal-respuestacita').on('show.bs.modal', function (event) {
        $("#modal-respuestacita input").val("");
        $("#modal-respuestacita textarea").val("");
        $("#modal-respuestacita select").val("Seleccione...");
        $("#modal-respuestacita input[type='radio']").prop('checked', false).change();
    });

      $('#modal-respuestacita-particular').on('show.bs.modal', function (event) {
        $("#modal-respuestacita-particular input").val("");
        $("#modal-respuestacita-particular textarea").val("");
       
        $("#modal-respuestacita-particular input[type='radio']").prop('checked', false).change();
    });

        

        $('#requiere_cita').hide(); 
        $('#seccion_tipoafiliacion').hide();
        $('#seccion_regimen').hide();          
        $('#seccion_nivel-contrato').hide();
        $('#seccion_regimen_nivel').hide();
        $('#select_tipo_consulta').hide();
        $('#seccion_observacion').hide();

        $("#acepta_p").val($('#acepta_p > option:first').val());
        $('#seccion_contrato_p').hide();
        $("#seccion_contrato_p").val($('#seccion_contrato_p > option:first').val());
        $('#seccion_tipocita_p').hide();
        $("#tipo_cita_p").val($('#tipo_cita_p > option:first').val());
        $('#requiere_cita_p').hide();
        $('#seccion_observacion_p').hide();
        this.setState({ button_rta_cita: this.state.button_rta_cita = false }) 

        this.setState({ regime: this.state.regime = <Regimen handleridregimen={this.handleridregimen}/> })
        this.setState({ tipo_afilia: this.state.tipo_afilia = <Tipo_Afiliacion handleridafiliacion={this.handleridafiliacion}/> })
        this.setState({contratos: this.state.contratos = <Contract handleridcontra={this.handleridcontra}/>})

        id_regi=null;
        id_afilia=null;
        idcontra=null;
        tipo=null;
        id_nivel=null;
        tipo_consulta='0'

    }


    handlerSelectdrop(){

      $("#nivel").val($('#nivel > option:first').val());
      $("#profesionales").val($('#profesionales > option:first').val());
      $("#espe").val($('#espe > option:first').val());
      $("#dia_agenda").val($('#dia_agenda > option:first').val());
      $("#fecha_solicita").val($('#fecha_solicita > option:first').val());
      $("#id_tipoid").val($('#id_tipoid > option:first').val());
      $("#id_pacie").val("");  
      $("#nom_pacien").val("");   
      $("#id_contrato_eps").val($('#id_contrato_eps > option:first').val()); 
      $("#id_contrato_particular").val($('#id_contrato_particular > option:first').val());
      $("#regimen").val($('#regimen > option:first').val());
      $("#tipo_afilia").val($('#tipo_afilia > option:first').val());
      $("#tipo_cita").val($('#tipo_cita > option:first').val());
      $("#sede").val($('#sede > option:first').val());
      $("#tipo_consulta").val($('#tipo_consulta > option:first').val());
      $("#fecha_deseada_cita").val("");
      $("#tipo_servicio").val($('#tipo_servicio > option:first').val());

      $('#afilia_beneficiarioaddcita').hide();
      $('#cb_afiliaaddcita').show();
      
      id_nivel=null;
      tipo_id=null;
      id_profe='0';
      id_regi=null;
      id_afilia=null;
      idcontra=null;
      id_especialidad='0';
      tipo=null;
      tipo_consulta='0'
      id_tipo_servi='0'

      this.setState({ active: this.state.active = true })
      this.setState({ active_button: this.state.active_button = true })

      this.setState({Sedes: []})
      this.setState({profesionales: []})
      this.setState({Dias_agenda: []})
      this.setState({Hora_citas: []})
      //this.setState({ profesionales: this.state.active_button = true })
     
    }


    handleModalhistorialcitas()
    {     
      $('#modal-historial_citas').modal('show'); // <- to show modal historial de citas 
      
    }

    handleModalrespuesta_autorizacion()
    {     
      $('#modal-respuesta_autorizacion').modal('show'); // <- to show modal respuesta de la autorizacion 
      
    }


    handleModalrespuesta_autorizaciondrop()
    {     
      $('#modal-respuesta_autorizacion').on('show.bs.modal', function (event) {        
        $("#modal-respuesta_autorizacion textarea").val("");        
        $("#modal-respuesta_autorizacion input[type='radio']").prop('checked', false).change();
    }); 

    $("#Checkacepta_autori input[type='radio']").prop('checked', false).change();
    $("#tipo_consulta_autorirpta").val($('#tipo_consulta_autorirpta > option:first').val());
    $('#select_tipo_consultaautori').hide();

    tipo_consulta='0'
    //document.getElementById('Racepta_autori').checked = false;

    this.setState({ button_rta_cita: this.state.button_rta_cita = false })
      
    }


    handleModalconfirmarcita()
    {      
      $('#modal-confirmar_cita').modal('show'); // <- to show modal elimiinar intervalo
      $('#btn_confirmar').hide();
      $('#btn_aceptar').show();
      
    }

    modal_informacionpcte(){
     
      $('#modal-infopcte').modal('show');
     
    }

   


    componentDidMount(){  

      $('[data-toggle="tooltip"]').tooltip()
      
      this.Citas_solicitadas();
      this.Citas_enproceso();
      this.SignalR();
      //this.Cargar_profesional();
      this.Cargar_contratos();
      this.Cargar_regimen();
      this.Cargar_afiliacion();
      this.Cargar_tipo_id();
      this.Cargar_tipos_consulta();
      this.Cargar_sedesall();
      this.Cargar_Tipo_servicios();

      $('#tabla1').hide();
      $('#empty_page').hide();
      $('#text_page').hide();
      $('#texto_filtro').hide();
      $('#select_sede_filtro').hide();   
      
      
      $('#id_contrato_particular').hide();

      $('#requiere_cita').hide(); 
      $('#seccion_tipoafiliacion').hide();
      $('#seccion_regimen').hide();          
      $('#seccion_nivel-contrato').hide();
      $('#seccion_regimen_nivel').hide();
      $('#select_tipo_consulta').hide();
      $('#seccion_observacion').hide();
      $('#afilia_beneficiario').hide();
      $('#afilia_beneficiarioaddcita').hide();
      $('#select_tipo_consultaautori').hide();

      $('#seccion_contrato_p').hide();
      $('#seccion_tipocita_p').hide();
      $('#requiere_cita_p').hide();
      $('#seccion_observacion_p').hide();
      //$('#btn_aceptar').hide();

      $('#dia_agenda').prop('disabled', 'disabled');

      
      
      //const script = document.createElement("script");
      //script.src = 'js/DataTablet.js'
      //document.body.appendChild(script);
      
    }

    empty_page_hide(){
      $('#tabla1').hide();
      $('#tabla2').hide();
      $('#tabla3').hide();
      $('#tabla4').hide();
      $('#tabla5').hide();
      $('#empty_page').hide();
      $('#empty_page2').hide();
      $('#empty_page3').hide();
      $('#empty_page4').hide();
      $('#empty_page5').hide();
      $('#text_page').hide();
      $('#text_page2').hide();
      $('#text_page3').hide();
      $('#text_page4').hide();
      $('#text_page5').hide();


    }
    

    GetAcepta = (e) =>{
      this.setState({acepta:e.target.value})
      console.log(e.target.value)
      respuesta=(e.target.value)
      this.Requiere_cita  ();
     
    } 
    

    GetNivel = (n) =>{
      this.setState({nivel:n.target.value})
      console.log(n.target.value)
      id_nivel=(n.target.value)

      this.Detalle_CM(id_nivel);
      //this.Cuota_mod();
     
    }


    GetTipocita = (t) =>{
    
      console.log(t.target.value)
      tipo=(t.target.value)
     
    }


    GetMedico = (med) =>{
      this.setState({med:med.target.value})
      console.log(med.target.value)
      id_profe=(med.target.value)
      //this.Cargar_Especialidades(id_profe);
      
      fecha_soli='null'
      $('#dia_agenda').prop('disabled', 'disabled');

      this.Cargar_Diasagenda();

      this.setState({Hora_citas: []})
     
    }

    GetMedicoclcik = (med) =>{
      this.setState({med:med.target.value})
      console.log(med.target.value)
      id_profe=(med.target.value)
      
      //this.Cargar_Especialidades(id_profe);
      //this.Cargar_Diasagenda();

     
     
    }


    GetEspecialidad = (es) =>{
     
      console.log(es.target.value)
      id_especialidad=(es.target.value)
      //this.Cargar_Diasagenda();
      this.Cargar_sedes()

      id_profe='0';
      id_sede='0';
      fecha_soli='null';
      this.setState({profesionales: []})
      this.setState({Dias_agenda: []})
      this.setState({Hora_citas: []})
     
    }

    GetDia_agenda = (da) =>{
      this.setState({dia_agenda:da.target.value})
      console.log(da.target.value)
      diaagenda=(da.target.value)
      fecha_soli='null'
      this.Cargar_Horacita();     
    }

    GetFechacita = (f) =>{
      this.setState({fecha_solicita:f.target.value})
      console.log(f.target.value)
      fecha_soli=(f.target.value)
     
    }

    GettipoID = (id) =>{
      //this.setState({tipoid:id.target.value})
      console.log(id.target.value)
      tipo_id=(id.target.value)     
    }

    Gettipoconsulta = (tc) =>{
      //this.setState({tipoid:id.target.value})
      console.log(tc.target.value)
      tipo_consulta=(tc.target.value)     
    }

    Getcontrato = (co) =>{
      this.setState({id_contrato:co.target.value})
      console.log(co.target.value)
      idcontra=(co.target.value)     
    }

    Getregimen = (re) =>{
      this.setState({id_regimen:re.target.value})
      console.log(re.target.value)
      id_regi=(re.target.value)     

      if(id_regi==1){

        $('#afilia_beneficiarioaddcita').show();
        $('#cb_afiliaaddcita').hide();

        id_afilia=2
        
      }else{

        $('#afilia_beneficiarioaddcita').hide();
        $('#cb_afiliaaddcita').show();

        id_afilia=null

      }

    }

    Getafiliacion = (af) =>{
      this.setState({idafilia:af.target.value})
      console.log(af.target.value)
      id_afilia=(af.target.value)     
    }

    
    GetRcuota = (e) =>{
      this.setState({Rcuota_mod:e.target.value})
      console.log(e.target.value)
      rcauota=(e.target.value)  
     
    }

    GetAutoriza = (n) =>{
      this.setState({Rautorizacion:n.target.value})
      console.log(n.target.value)
      rautoriza=(n.target.value)

      if(rautoriza=='false'){
        $('#select_tipo_consulta').show();
      }else{
        $('#select_tipo_consulta').hide();
        $("#tipo_consulta_autori").val($('#tipo_consulta_autori > option:first').val());
        tipo_consulta='0'
      }

      this.Detalle_CM(id_nivel);
     
    }

    GetAceptaautori = (a) =>{
      //this.setState({Racepta_autori:a.target.value})
      console.log(a.target.value)
      racepta_autoriza=(a.target.value)

      if(racepta_autoriza=='true'){
        $('#select_tipo_consultaautori').show();
      }else{

        $('#select_tipo_consultaautori').hide();
        $("#tipo_consulta_autorirpta").val($('#tipo_consulta_autorirpta > option:first').val());
        tipo_consulta='0'

      }
     
    }

    GetSede = (sd) =>{
      id_sede="0";    
      console.log(sd.target.value)
      id_sede=(sd.target.value)

      this.Cargar_profesionales_agenda();

      
      fecha_soli='null';
     
      this.setState({Dias_agenda: []})
      this.setState({Hora_citas: []})
      
     
    }

    Gettipo_filtro = (tf) =>{
      //this.setState({tipoid:id.target.value})
      $("#sede_filtro").val($('#sede_filtro > option:first').val());      
      $("#buscar_solicitadas").val("");  
      console.log(tf.target.value)
      tipo_filtro=(tf.target.value)
      
      if(tipo_filtro=="1"){
        $('#select_sede_filtro').show(); 
        $('#texto_filtro').hide();
      }
        if(tipo_filtro=="2"){
          $('#texto_filtro').show();
          $('#select_sede_filtro').hide(); 
          
        }
        if(tipo_filtro=="0"){
          $('#texto_filtro').hide();
          $('#select_sede_filtro').hide(); 
          buscar_solicitada=""; 
          this.searchDatasolcitada(buscar_solicitada)   
        }
        
        if(tipo_filtro=="10"){
          $('#texto_filtro').hide();
          $('#select_sede_filtro').hide(); 
          buscar_solicitada=""; 
          this.searchDatasolcitada(buscar_solicitada)   
        } 
    }

    GetSede_filtro = (sdf) =>{
      buscar_solicitada="";    
      console.log(sdf.target.value)    
      buscar_solicitada=(sdf.target.value)
      this.searchDatasolcitada(buscar_solicitada)
     
    }

    BuscarCitas = (bc) =>{
      this.setState({buscar_citas:bc.target.value})
      console.log(bc.target.value)
      buscar_cita=(bc.target.value)
      this.searchDataasignada(buscar_cita)
     
    }

    BuscarSolicitada = (bs) =>{
      this.setState({buscar_citas:bs.target.value})
      console.log(bs.target.value)
      buscar_solicitada=(bs.target.value)
      this.searchDatasolcitada(buscar_solicitada)
     
    }

    BuscarEnproceso = (bp) =>{
      this.setState({buscar_enprocesos:bp.target.value})
      console.log(bp.target.value)
      buscar_enproceso=(bp.target.value)
      this.searchDataenproceso(buscar_enproceso)
     
    }

    BuscarRechazada = (br) =>{
      this.setState({buscar_rechazadas:br.target.value})
      console.log(br.target.value)
      buscar_rechazada=(br.target.value)
      this.searchDatarechazada(buscar_rechazada)
     
    }

    BuscarCancelada = (bca) =>{
      this.setState({buscar_cance:bca.target.value})
      console.log(bca.target.value)
      buscar_cancelada=(bca.target.value)
      this.searchDatacancelada(buscar_cancelada)
     
    }

    
    GetTiposervi = (ts) => {
      id_tipo_servi=(ts.target.value)
      console.log(ts.target.value)
      fecha_soli='null'
      $('#dia_agenda').prop('disabled', 'disabled');
      //this.Cargar_Horacita();  
      this.setState({Dias_agenda: []})
      this.setState({Hora_citas: []})

      if(id_tipo_servi==2){
        $('#id_contrato_particular').hide();
        $('#id_contrato_eps').show();
      }else{
        if(id_tipo_servi==3){
          $('#id_contrato_particular').show();
          $('#id_contrato_eps').hide();

        }
      }
      //alert(id_tipo_servi)
      this.Cargar_Diasagenda();
      this.setState({ loading: this.state.loading_dias_agenda = false })
    }


    GetTiposerviclcik = (ts) =>{
      id_tipo_servi=(ts.target.value)
      console.log(ts.target.value)
      fecha_soli='null'      
      //this.Cargar_Horacita();  
      //this.Cargar_Diasagenda();    
     
    }
    

    SignalR(){
      
      const protocol = new signalR.JsonHubProtocol();
      const SignalRconnection = new signalR.HubConnectionBuilder()
      .withUrl(ApiURL+'/api/notifications', 
      {
          accessTokenFactory: () => Token
      }) 
      .withHubProtocol(protocol)    
      .build();
      this.setState({SignalRconnection}, () =>{
        this.state.SignalRconnection.start()
        .then(() =>console.log('Conexion iniciada'))
        .catch(err =>console.log('Error de Conexion signalR'))

        this.state.SignalRconnection.on("ShowNotification", (message) => {
         const SignalrMessage = message.split('-');
         const msg1=SignalrMessage[0];
         const msg2=SignalrMessage[1];
         const num=msg1.split('-').length;

         console.log( SignalrMessage);         
         this.setState({msg1});
         this.setState({msg2});
         this.setState({num});

         
         this.Citas_solicitadas();
         this.Citas_enproceso();

         this.setState({estado_sonido:this.state.estado_sonido = true})

         

                  
         store.addNotification({
          title: msg1,
          message: msg2,
          type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
          duration: 10000,
          showIcon:true
          }
        })

        this.setState({ estado_sonido: this.state.estado_sonido = false })

      });
             
      })
      
    }


    Cargar_Tipo_servicios(){    
    
      axios.get(ApiURL+"/api/TypeServices")
      .then((response) => {
        console.log(response.data.filter(item => item.id !== 1));
        this.setState({Servicios: response.data.filter(item => item.id !== 1)})
      })
      .catch((error) => {
        console.log(error);
      })
    }


    Cargar_tipo_id=async()=>{    
    
      await axios.get(ApiURL+"/api/TypeIdentification/ListTypeIdentification/paciente")
        .then((response) => {
          console.log(response);
          this.setState({Tipo_id: response.data})
        })
        .catch((error) => {
          console.log(error);
        })
    }


    Cargar_sedesall(){    
    
      axios.get(ApiURL+"/api/Sedes/ListAll")
      .then((response) => {
        console.log(response);
        this.setState({Sedes_filtro: response.data})

        


      })
      .catch((error) => {
        console.log(error);
      })
    }


    Cargar_sedes(){   
       
    
      axios.post(ApiURL+"/api/Sedes/ListBySpecialty",
      {
        institutionId: institution_id,
        specialtyId: id_especialidad
      
      })
      .then((response) => {
        console.log(response);
        this.setState({Sedes: response.data})

        if(this.state.Sedes.length==0){

          $('#modal-verifisede').modal('show');
       
        }else{
          
        }


      })
      .catch((error) => {
        console.log(error);
      })
      
    }


    Cargar_profesional=async()=>{    
    
      await axios.get(ApiURL+"/api/Professionals")
        .then((response) => {
          console.log(response);
          this.setState({profesionales: response.data})
        })
        .catch((error) => {
          console.log(error);
        })
    } 


    Cargar_tipos_consulta=async()=>{    
    
      await axios.get(ApiURL+"/api/TypeConsultation/List")
        .then((response) => {
          console.log(response);
          this.setState({Tipo_consulta: response.data})
        })
        .catch((error) => {
          console.log(error);
        })
    } 

      Cargar_contratos(){    
    
        axios.get(ApiURL+"/api/Contracts/ListActive")
        .then((response) => {
          console.log(response);
          this.setState({Contrato: response.data.filter(item => item.entityId !== 999)})
          this.setState({Contrato_particular:response.data.filter(item => item.entityId == 999)})

          if(this.state.Contrato.length==0 && this.state.Contrato_particular.length==0){

            $('#modal-verificontratos').modal('show');
         
          }else{

            
          }


        })
        .catch((error) => {
          console.log(error);
        })
      }

      Cargar_regimen(){    
    
        axios.get(ApiURL+"/api/Regimes")
        .then((response) => {
          console.log(response);
          this.setState({Regimen: response.data})
        })
        .catch((error) => {
          console.log(error);
        })
      }

      Cargar_afiliacion(){    
    
        axios.get(ApiURL+"/api/TypeAffiliates/List")
        .then((response) => {
          console.log(response);
          this.setState({Afiliacion: response.data})
        })
        .catch((error) => {
          console.log(error);
        })
      }


    Cargar_Especialidades(){    
      
      axios.get(ApiURL+"/api/Specialties/ListByInstitution/"+institution_id)
      .then((response) => {
        console.log(response);
        this.setState({Especialidades: response.data})
        
      })
      .catch((error) => {
        console.log(error);
      })
    }


    Cargar_profesionales_agenda=async()=>{    
      
      await axios.post(ApiURL+"/api/Professionals/ListByInstitutionSpecialty",
      {
        institutionId: institution_id,
        specialtyId: id_especialidad,
        sedeId:id_sede    
      })
      .then((response) => {
        console.log(response);
        this.setState({profesionales: response.data})
        
      })
      .catch((error) => {
        console.log(error);
      })
    }



    Cargar_Diasagenda(){   
      
      //$('#dia_agenda').prop('disabled', 'disabled');
      //this.setState({ loading: this.state.loading_dias_agenda = false })
      
      axios.post(ApiURL+"/api/Schedules/GetDaysAvailable",
      {
      institutionId: institution_id,
      specialtyId: id_especialidad,
      professionalId:id_profe,
      sedeId:id_sede,
      typeServiceId:id_tipo_servi      
      })
      .then((response) => {
        console.log(response);
        this.setState({Dias_agenda: response.data})

        if(this.state.Dias_agenda.length>0){
          $('#dia_agenda').prop('disabled', false);
          this.setState({ loading: this.state.loading_dias_agenda = true })
        }else{
          $('#dia_agenda').prop('disabled', 'disabled');
          this.setState({ loading: this.state.loading_dias_agenda = true })
        }
        
        
      })
      .catch((error) => {
        console.log(error);
        $('#dia_agenda').prop('disabled', 'disabled');
        this.setState({ loading: this.state.loading_dias_agenda = true })
      })
    }

    Cargar_Horacita(){    
      
      axios.post(ApiURL+"/api/Schedules/GetHoursAvailables",
      {
      institutionId: institution_id,
      specialtyId: id_especialidad,
      professionalId:id_profe,
      sedeId:id_sede,
      typeServiceId:id_tipo_servi,
      date:diaagenda
      
      })
      .then((response) => {
        console.log(response);
        this.setState({Hora_citas: response.data})
        
      })
      .catch((error) => {
        console.log(error);
      })
    }


     Citas_solicitadas(){  
      this.setState({estado_sonido:this.state.estado_sonido = true})
      
      this.empty_page_hide();  
      this.setState({ loading: this.state.loading = false })
      axios.get(ApiURL+"/api/Citas/GetRequestAppointment")
      .then((response) => {
        console.log(response);
        this.setState({Citas_soli: response.data.data})  
        this.setState({Pager_Citas_soli: response.data.pager.items})
        this.setState({pager_infocitas_soli: response.data.pager.showData})

        if(this.state.Citas_soli.length==0){
          $('#tabla1').hide();
          $('#empty_page').show();
          $('#text_page').show();
          soli_pendientes="";

        }else{
          $('#tabla1').show();
          $('#empty_page').hide();
          $('#text_page').hide();
          soli_pendientes=response.data.data.length

        }    

        this.setState({ loading: this.state.loading = true })
       
      })
      .catch((error) => {
        console.log(error);
      })
    }

    loadMoreDatasolicitadas(page_solici) {
      var page=page_solici.pagina
      this.setState({ loading: this.state.loading = false })
      
      axios.get(ApiURL+"/api/Citas/GetRequestAppointment?numeropagina="+page)
      .then((response) => {
        console.log(response);

        this.setState({Citas_soli: response.data.data})  
        this.setState({Pager_Citas_soli: response.data.pager.items})
        this.setState({pager_infocitas_soli: response.data.pager.showData})
  
      
        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      })
    
      }

      searchDatasolcitada(buscar_solicitada) {

        this.setState({ loading: this.state.loading = false })
        
        axios.get(ApiURL+"/api/Citas/GetRequestAppointment?texto="+buscar_solicitada)
        .then((response) => {
        console.log(response);

        this.setState({Citas_soli: response.data.data})  
        this.setState({Pager_Citas_soli: response.data.pager.items})
        this.setState({pager_infocitas_soli: response.data.pager.showData})
        
          this.setState({ loading: this.state.loading = true })
        })
        .catch((error) => {
          console.log(error);
        })
      
        }

    Citas_enproceso(){   
      this.empty_page_hide(); 
      this.setState({ loading: this.state.loading = false })
      axios.get(ApiURL+"/api/Citas/ListPending")
      .then((response) => {
        console.log(response);

        this.setState({Citas_proceso: response.data.data})    
        this.setState({Pager_Citas_proceso: response.data.pager.items})
        this.setState({pager_infocitas_proceso: response.data.pager.showData})

        
        
        if(this.state.Citas_proceso.length==0){
          $('#tabla2').hide();
          $('#empty_page2').show();
          $('#text_page2').show();
          soli_proceso=""


        }else{
          $('#tabla2').show();
          $('#empty_page2').hide();
          $('#text_page2').hide();

          soli_proceso=response.data.data.length

        } 

        this.setState({ loading: this.state.loading = true })
       
      })
      .catch((error) => {
        console.log(error);
      })
    }


    loadMoreDataproceso(page_proce) {
      var page=page_proce.pagina
      this.setState({ loading: this.state.loading = false })
      
      axios.get(ApiURL+"/api/Citas/ListPending?numeropagina="+page)
      .then((response) => {
        console.log(response);

        this.setState({Citas_proceso: response.data.data})     
        this.setState({Pager_Citas_proceso: response.data.pager.items})
        this.setState({pager_infocitas_proceso: response.data.pager.showData}) 
      
        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      })
    
      }

      searchDataenproceso(buscar_enproceso) {

        this.setState({ loading: this.state.loading = false })
        
        axios.get(ApiURL+"/api/Citas/ListPending?texto="+buscar_enproceso)
      .then((response) => {
        console.log(response);

        this.setState({Citas_proceso: response.data.data})     
        this.setState({Pager_Citas_proceso: response.data.pager.items})
        this.setState({pager_infocitas_proceso: response.data.pager.showData}) 
        
          this.setState({ loading: this.state.loading = true })
        })
        .catch((error) => {
          console.log(error);
        })
      
        }


    Citas_Asignadas(){  
      this.empty_page_hide();  
      this.setState({ loading: this.state.loading = false })
      axios.get(ApiURL+"/api/Citas/ListAll")
      .then((response) => {
        console.log(response);

        this.setState({Citas: response.data.data})  
        this.setState({Pager_Citas: response.data.pager.items})
        this.setState({pager_infocitas: response.data.pager.showData}) 
        
        if(this.state.Citas.length==0){
          $('#tabla3').hide();
          $('#empty_page3').show();
          $('#text_page3').show();

        }else{
          $('#tabla3').show();
          $('#empty_page3').hide();
          $('#text_page3').hide();

        }
        
        this.setState({ loading: this.state.loading = true })
          
      })
      .catch((error) => {
        console.log(error);
      })
    }


    loadMoreDataasignada(page_asigna) {
      var page=page_asigna.pagina
      this.setState({ loading: this.state.loading = false })
      
      axios.get(ApiURL+"/api/Citas/ListAll?numeropagina="+page)
      .then((response) => {
        console.log(response);

        this.setState({Citas: response.data.data})  
        this.setState({Pager_Citas: response.data.pager.items})
        this.setState({pager_infocitas: response.data.pager.showData}) 
      
        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      })
    
      }    


      searchDataasignada(buscar_cita) {

        this.setState({ loading: this.state.loading = false })
        
        axios.get(ApiURL+"/api/Citas/ListAll?texto="+buscar_cita)
        .then((response) => {
          console.log(response);
  
          this.setState({Citas: response.data.data})  
          this.setState({Pager_Citas: response.data.pager.items})
          this.setState({pager_infocitas: response.data.pager.showData}) 
        
          this.setState({ loading: this.state.loading = true })
        })
        .catch((error) => {
          console.log(error);
        })
      
        }


    Citas_rechazadas(){ 
      this.empty_page_hide();   
      this.setState({ loading: this.state.loading = false })
      axios.get(ApiURL+"/api/Citas/ListReject")
      .then((response) => {
        console.log(response);

        this.setState({Citas_rechazadas: response.data.data})  
        this.setState({Pager_Citas_rechazadas: response.data.pager.items})
        this.setState({pager_infocitas_rechazadas: response.data.pager.showData}) 
        
        if(this.state.Citas_rechazadas.length==0){
          $('#tabla4').hide();
          $('#empty_page4').show();
          $('#text_page4').show();

        }else{
          $('#tabla4').show();
          $('#empty_page4').hide();
          $('#text_page4').hide();

        }

        this.setState({ loading: this.state.loading = true })
       
      })
      .catch((error) => {
        console.log(error);
      })
    }


    loadMoreDatarechazada(page_rechaza) {
      var page=page_rechaza.pagina
      this.setState({ loading: this.state.loading = false })
      
      axios.get(ApiURL+"/api/Citas/ListReject?numeropagina="+page)
      .then((response) => {
        console.log(response);

        this.setState({Citas_rechazadas: response.data.data})  
        this.setState({Pager_Citas_rechazadas: response.data.pager.items})
        this.setState({pager_infocitas_rechazadas: response.data.pager.showData}) 
      
        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      })
    
      }


      searchDatarechazada(buscar_rechazada) {

        this.setState({ loading: this.state.loading = false })
        
        axios.get(ApiURL+"/api/Citas/ListReject?texto="+buscar_rechazada)
        .then((response) => {
          console.log(response);
  
          this.setState({Citas_rechazadas: response.data.data})  
          this.setState({Pager_Citas_rechazadas: response.data.pager.items})
          this.setState({pager_infocitas_rechazadas: response.data.pager.showData})  
        
          this.setState({ loading: this.state.loading = true })
        })
        .catch((error) => {
          console.log(error);
        })
      
        }


        Citas_canceladas(){ 
          this.empty_page_hide();   
          this.setState({ loading: this.state.loading = false })
          axios.get(ApiURL+"/api/Citas/ListCanceled")
          .then((response) => {
            console.log(response);
    
            this.setState({Citas_canceladas: response.data.data})  
            this.setState({Pager_Citas_canceladas: response.data.pager.items})
            this.setState({pager_infocitas_canceladas: response.data.pager.showData}) 
            
            if(this.state.Citas_canceladas.length==0){
              $('#tabla5').hide();
              $('#empty_page5').show();
              $('#text_page5').show();
    
            }else{
              $('#tabla5').show();
              $('#empty_page5').hide();
              $('#text_page5').hide();
    
            }
    
            this.setState({ loading: this.state.loading = true })
           
          })
          .catch((error) => {
            console.log(error);
          })
        }
    
    
        loadMoreDatacancelada(page_cance) {
          var page=page_cance.pagina
          this.setState({ loading: this.state.loading = false })
          
          axios.get(ApiURL+"/api/Citas/ListCanceled?numeropagina="+page)
          .then((response) => {
            console.log(response);
    
            this.setState({Citas_canceladas: response.data.data})  
            this.setState({Pager_Citas_canceladas: response.data.pager.items})
            this.setState({pager_infocitas_canceladas: response.data.pager.showData}) 
          
            this.setState({ loading: this.state.loading = true })
          })
          .catch((error) => {
            console.log(error);
          })
        
          }
    
    
          searchDatacancelada(buscar_cancelada) {
    
            this.setState({ loading: this.state.loading = false })
            
            axios.get(ApiURL+"/api/Citas/ListCanceled?texto="+buscar_cancelada)
            .then((response) => {
              console.log(response);
      
              this.setState({Citas_canceladas: response.data.data})  
              this.setState({Pager_Citas_canceladas: response.data.pager.items})
              this.setState({pager_infocitas_canceladas: response.data.pager.showData})  
            
              this.setState({ loading: this.state.loading = true })
            })
            .catch((error) => {
              console.log(error);
            })
          
            }
   
  
    seleccionarcontra=(contra)=>{
      this.setState({
        form:{
          id_contra:contra.id,  
          nom_eps:contra.entity.razonSocial                   
        }
      })
      //var dato  = (this.state.form.id_contra);
      //alert(contra.id)
    }

    seleccionarsolicitudcita=(cita)=>{

      if(cita.contract.entityId=='999'){
        
        tipo_servicio_cita=3;        

      }else{
       
        tipo_servicio_cita=2;
        
      }

      id_afilia=null
      
    
      this.setState({
        form:{
          id_cita:cita.id,
          id_pacie_soli:cita.patient.user.typeId +'-'+ cita.patient.user.identification,
          tipoid_pacien_hc:cita.patient.user.typeId,
          id_pacien_hc:cita.patient.user.identification,
          nom1_pacien:cita.patient.user.name1 ? cita.patient.user.name1.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
          nom2_pacien:cita.patient.user.name2 ? cita.patient.user.name2.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' ,
          apell1_pacien:cita.patient.user.lastname1 ? cita.patient.user.lastname1.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))): '',
          apell2_pacien:cita.patient.user.lastname2 ? cita.patient.user.lastname2.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))): '',
          obser:'',
          fecha_solicita:moment(cita.fecha).format("DD/MM/YYYY HH:mm"),
          nom1medico_soli: cita.professional.user.name1 ? cita.professional.user.name1.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
          nom2medico_soli: cita.professional.user.name2 ? cita.professional.user.name2.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
          apell1medico_soli: cita.professional.user.lastname1 ? cita.professional.user.lastname1.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
          apell2medico_soli: cita.professional.user.lastname2 ? cita.professional.user.lastname2.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
          espe_soli:cita.specialty.name.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))),
          fecha_naci:moment(cita.patient.user.birthday).format("DD/MM/YYYY"),
          edad_pcte:moment(cita.patient.user.birthday).toNow(true),
          genero_pcte:cita.patient.user.gender,
          dir_pcte:cita.patient.user.address,
          tel_pcte:cita.patient.user.phone,
          municipio_pcte:cita.patient.user.municipality.nombre.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))),
          departamento_pct:cita.patient.user.department.nombre.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))),
          responsable_pcte:cita.patient.user.personInCharge,
          telresponsable_pcte:cita.patient.user.phonePerson,
          parentescoresp_pcte:cita.patient.user.relationshipPerson,
          eps_pcte:cita.contract.entity.razonSocial,
          regimen_pcte:cita.patient.regimen.nombre ? cita.patient.regimen.nombre : '',  
          adjunto: cita.professional.user.avatar,
          obser_autorizfacion:'',
          pago_particular:cita.amountFee
          
        }
      })
      //var  dato  = solicitud.user.identification;
      //alert(cita_proce.fecha);


     
    }


    seleccionarcitaenproceso=(cita_proce)=>{
      var citaid=cita_proce.id
    
      this.setState({
        form:{
          id_cita:cita_proce.id,
          id_pacie_soli:cita_proce.patient.user.typeId +'-'+ cita_proce.patient.user.identification,
          tipoid_pacien_hc:cita_proce.patient.user.typeId,
          id_pacien_hc:cita_proce.patient.user.identification,
          nom1_pacien:cita_proce.patient.user.name1 ? cita_proce.patient.user.name1.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
          nom2_pacien:cita_proce.patient.user.name2 ? cita_proce.patient.user.name2.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' ,
          apell1_pacien:cita_proce.patient.user.lastname1 ? cita_proce.patient.user.lastname1.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))): '',
          apell2_pacien:cita_proce.patient.user.lastname2 ? cita_proce.patient.user.lastname2.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))): '',
          obser:'',
          fecha_solicita:moment(cita_proce.fecha).format("DD/MM/YYYY HH:mm"),
          nom1medico_soli: cita_proce.professional.user.name1 ? cita_proce.professional.user.name1.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
          nom2medico_soli: cita_proce.professional.user.name2 ? cita_proce.professional.user.name2.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
          apell1medico_soli: cita_proce.professional.user.lastname1 ? cita_proce.professional.user.lastname1.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
          apell2medico_soli: cita_proce.professional.user.lastname2 ? cita_proce.professional.user.lastname2.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
          espe_soli:cita_proce.specialty.name.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))),
         
          
        }
      })
      //var  dato  = solicitud.user.identification;
      //alert(cita_proce.fecha);

      
      axios.get(ApiURL+"/api/Citas/GetAppointment/"+citaid)
      .then((response) => {
        console.log(response);
        this.setState({Autorizacion: response.data.authorizations})
          
      })
      .catch((error) => {
        console.log(error);
      })
     
    }

    seleccionarcitacofirm=(cita_confir)=>{
      var citaid=cita_confir.id
    
      this.setState({
        form:{
          id_cita:cita_confir.id,
          id_pacie_soli:cita_confir.patient.user.typeId +'-'+ cita_confir.patient.user.identification,
          tipoid_pacien_hc:cita_confir.patient.user.typeId,
          id_pacien_hc:cita_confir.patient.user.identification,
          nom1_pacien:cita_confir.patient.user.name1 ? cita_confir.patient.user.name1.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
          nom2_pacien:cita_confir.patient.user.name2 ? cita_confir.patient.user.name2.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' ,
          apell1_pacien:cita_confir.patient.user.lastname1 ? cita_confir.patient.user.lastname1.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))): '',
          apell2_pacien:cita_confir.patient.user.lastname2 ? cita_confir.patient.user.lastname2.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))): '',
          obser_autorizacion:cita_confir.anotation,
          fecha_solicita:moment(cita_confir.fecha).format("DD/MM/YYYY HH:mm"),
          nom1medico_soli: cita_confir.professional.user.name1 ? cita_confir.professional.user.name1.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
          nom2medico_soli: cita_confir.professional.user.name2 ? cita_confir.professional.user.name2.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
          apell1medico_soli: cita_confir.professional.user.lastname1 ? cita_confir.professional.user.lastname1.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
          apell2medico_soli: cita_confir.professional.user.lastname2 ? cita_confir.professional.user.lastname2.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
          espe_soli:cita_confir.specialty.name.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))),
         
          
        }
      })
      //var  dato  = solicitud.user.identification;
      //alert(cita_proce.fecha);

      
      axios.get(ApiURL+"/api/Citas/GetAppointment/"+citaid)
      .then((response) => {
        console.log(response);
        this.setState({Autorizacion: response.data.authorizations})
          
      })
      .catch((error) => {
        console.log(error);
      })
     
    }
    
    
    _handleKeyDown = (e) => {
      this.Campo_numerico();
      if (e.key === 'Enter' || e.key=='Tab') {
       
        this.Buscar_Paciente()
        
      }
    }

    Buscar_Paciente=async()=>{

      if(tipo_id=='null' || this.state.form.id_pacie.length==0){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        axios.post(ApiURL+"/api/Patients/GetPatient",
        {
        identification: this.state.form.id_pacie,
        typeId: tipo_id
        
        })  
      .then(response=>{
        console.log(response.data)
        var  mensaje  =response.data.id +'-'+ response.data.user.name1 +' '+ response.data.user.name2 +' '+ response.data.user.lastname1 +' '+ response.data.user.lastname2;
        patientid=response.data.id
        //alert(mensaje);
        this.setState({
          form:{      
            nom_pacien:response.data.user.name1.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) +' '+ response.data.user.name2.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) +' '+ response.data.user.lastname1.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) +' '+ response.data.user.lastname2.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))                    
          }
        })
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
        /*store.addNotification({
          title: 'Muy bien',
          message: mensaje,
          type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })*/

        this.setState({ buscar_pcte: this.state.buscar_pcte = true })       
        
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          mensaje=error.response.data.message
          
          store.addNotification({
            title: 'Atención',
            message: mensaje,
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
      }
      //console.log(error.config);
    
    })

      }
  
     }


     Detalle_CM(id_nivel){  
       
      if(id_nivel > 0){
     
      
      axios.get(ApiURL+"/api/ModeratingFees/GetAmountLevel/"+id_nivel)
      .then((response) => {
        console.log(response.data.amount);
        //this.setState({Municipios: response.data})

        if(id_regi==2 & id_nivel==1){        

          this.setState({
            form:{
              tipoid_pacien_hc: this.state.form.tipoid_pacien_hc,
              id_pacien_hc: this.state.form.id_pacien_hc,
              nom1_pacien:this.state.form.nom1_pacien,
              nom2_pacien:this.state.form.nom2_pacien,
              apell1_pacien:this.state.form.apell1_pacien,
              apell2_pacien:this.state.form.apell2_pacien,
              nom1medico_soli:this.state.form.nom1medico_soli,
              nom2medico_soli:this.state.form.nom2medico_soli,
              apell1medico_soli:this.state.form.apell1medico_soli,
              apell2medico_soli:this.state.form.apell2medico_soli,
              cuota_mod:response.data.amount,
              id_cita: this.state.form.id_cita             
            }
          })
          this.setState({ button_rta_cita: this.state.button_rta_cita = true })
        }else{
  
          if(id_regi==2 & id_nivel==2){
  
            this.setState({
              form:{
                tipoid_pacien_hc: this.state.form.tipoid_pacien_hc,
                id_pacien_hc: this.state.form.id_pacien_hc,
                nom1_pacien:this.state.form.nom1_pacien,
                nom2_pacien:this.state.form.nom2_pacien,
                apell1_pacien:this.state.form.apell1_pacien,
                apell2_pacien:this.state.form.apell2_pacien,
                nom1medico_soli:this.state.form.nom1medico_soli,
                nom2medico_soli:this.state.form.nom2medico_soli,
                apell1medico_soli:this.state.form.apell1medico_soli,
                apell2medico_soli:this.state.form.apell2medico_soli,
                cuota_mod:response.data.amount,  
                id_cita: this.state.form.id_cita            
              }
            })
            this.setState({ button_rta_cita: this.state.button_rta_cita = true })
          }else{
            if(id_regi==2 & id_nivel==3){
  
              this.setState({
                form:{
                  tipoid_pacien_hc: this.state.form.tipoid_pacien_hc,
                  id_pacien_hc: this.state.form.id_pacien_hc,
                  nom1_pacien:this.state.form.nom1_pacien,
                  nom2_pacien:this.state.form.nom2_pacien,
                  apell1_pacien:this.state.form.apell1_pacien,
                  apell2_pacien:this.state.form.apell2_pacien,
                  nom1medico_soli:this.state.form.nom1medico_soli,
                  nom2medico_soli:this.state.form.nom2medico_soli,
                  apell1medico_soli:this.state.form.apell1medico_soli,
                  apell2medico_soli:this.state.form.apell2medico_soli,
                  cuota_mod:response.data.amount,
                  id_cita: this.state.form.id_cita              
                }
              })
              this.setState({ button_rta_cita: this.state.button_rta_cita = true })
            }else{
              this.setState({
                form:{
                  tipoid_pacien_hc: this.state.form.tipoid_pacien_hc,
                  id_pacien_hc: this.state.form.id_pacien_hc,
                  nom1_pacien:this.state.form.nom1_pacien,
                  nom2_pacien:this.state.form.nom2_pacien,
                  apell1_pacien:this.state.form.apell1_pacien,
                  apell2_pacien:this.state.form.apell2_pacien,
                  nom1medico_soli:this.state.form.nom1medico_soli,
                  nom2medico_soli:this.state.form.nom2medico_soli,
                  apell1medico_soli:this.state.form.apell1medico_soli,
                  apell2medico_soli:this.state.form.apell2medico_soli,
                  cuota_mod:'0',
                  id_cita: this.state.form.id_cita              
                }
              })
              this.setState({ button_rta_cita: this.state.button_rta_cita = true })
            }
          }
          
        }
        
        
        
      })
      .catch((error) => {
        
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          mensaje=error.response.data.message
          
          if(id_regi==2){

            $('#modal-verificuotamoderadora').modal('show');

            this.setState({ button_rta_cita: this.state.button_rta_cita = false })
  
            this.setState({
              form:{
                tipoid_pacien_hc: this.state.form.tipoid_pacien_hc,
                id_pacien_hc: this.state.form.id_pacien_hc,
                nom1_pacien:this.state.form.nom1_pacien,
                nom2_pacien:this.state.form.nom2_pacien,
                apell1_pacien:this.state.form.apell1_pacien,
                apell2_pacien:this.state.form.apell2_pacien,
                nom1medico_soli:this.state.form.nom1medico_soli,
                nom2medico_soli:this.state.form.nom2medico_soli,
                apell1medico_soli:this.state.form.apell1medico_soli,
                apell2medico_soli:this.state.form.apell2medico_soli,
                cuota_mod:'0',
                id_cita: this.state.form.id_cita              
              }
            })

          }else{

            this.setState({
              form:{
                tipoid_pacien_hc: this.state.form.tipoid_pacien_hc,
                id_pacien_hc: this.state.form.id_pacien_hc,
                nom1_pacien:this.state.form.nom1_pacien,
                nom2_pacien:this.state.form.nom2_pacien,
                apell1_pacien:this.state.form.apell1_pacien,
                apell2_pacien:this.state.form.apell2_pacien,
                nom1medico_soli:this.state.form.nom1medico_soli,
                nom2medico_soli:this.state.form.nom2medico_soli,
                apell1medico_soli:this.state.form.apell1medico_soli,
                apell2medico_soli:this.state.form.apell2medico_soli,
                cuota_mod:'0',
                id_cita: this.state.form.id_cita              
              }
            })

          }   

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);

         
      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
          
      }
      //console.log(error.config);

      })

    }

  }


    savecitas=async()=>{     

        if( !moment(this.state.form.fecha_deseada_cita,'YYYY-MM-DD', true).isValid()){
          
          store.addNotification({
            title: 'Atención',
            message: "La fecha ingresada no es valida",
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
        
        }else{

      if(fecha_soli=='null' || patientid==0 || id_profe==0 || id_especialidad==0 || idcontra=='null' ||  id_regi=='null' || id_nivel=='null' || id_afilia=='null' || tipo==null ||  tipo_consulta=='0' || this.state.form.fecha_deseada_cita==''){

        store.addNotification({
          title: 'Atención',
          message: "Complete los datos faltantes para continuar.",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{
        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ active_button: this.state.active_button = false })
        await axios.post(ApiURL+"/api/Citas/Create",
  
          {

          fecha: fecha_soli,
          desiredDate:this.state.form.fecha_deseada_cita,
          professionalId: id_profe,
          specialtyId: id_especialidad,
          contractId: idcontra,
          institutionId: institution_id,
          sedeId:id_sede,
          patientId: patientid,
          regimeId:id_regi,
          level:id_nivel,
          typeAffiliateId:id_afilia,
          type:tipo,
          tipoConsultaId:tipo_consulta,
          duration:20
          
          })  
        .then(response=>{
          console.log(response.data)
          var  mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
        store.addNotification({
            title: 'Muy bien',
            message: "Cita asignada correctamente",
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
            duration: 10000,
            showIcon:true
            }
          })

          this.setState({ loading_button: this.state.loading_button = true })
          //this.setState({ active_button: this.state.active_button = true })
          this.setState({ active: this.state.active = false })

          this.setState({
            form:{
              tipoid_pacien_hc: this.state.form.tipoid_pacien_hc,
              id_pacien_hc: this.state.form.id_pacien_hc,
              nom1_pacien:this.state.form.nom1_pacien,
              nom2_pacien:this.state.form.nom2_pacien,
              apell1_pacien:this.state.form.apell1_pacien,
              apell2_pacien:this.state.form.apell2_pacien,
              nom1medico_soli:this.state.form.nom1medico_soli,
              nom2medico_soli:this.state.form.nom2medico_soli,
              apell1medico_soli:this.state.form.apell1medico_soli,
              apell2medico_soli:this.state.form.apell2medico_soli,
              cuota_mod:'0',
              id_cita: this.state.form.id_cita              
            }
          })

          
          
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);

            this.setState({ loading_button: this.state.loading_button = false })
            this.setState({ loading_button: this.state.active_button = false })
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        }
        //console.log(error.config);
      
        })

        }  
      
      } 
      
    
  
    }

     respuesta_cita(){

      if(tipo_servicio_cita==2){

            if(respuesta=='true'){

          

              if( idcontra==null ||  id_regi==null || id_nivel==null || id_afilia==null || tipo==null || rautoriza=='' || rcauota=='' ){
      
                store.addNotification({
                  title: 'Atención',
                  message: "Faltan datos por ingresar.",
                  type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
                  container: 'top-right',                // where to position the notifications
                  animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                  animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                  dismiss: {
                    duration: 10000,
                    showIcon:true
                  }
                })
        
              }else{
        
                this.Confirmar_cita();
                //this.requierecita();
        
              }
      
            }else{
              if(respuesta=='false'){
      
                rcauota='false'
                rautoriza='false'
      
                if(this.state.form.obser==''){
                  store.addNotification({
                    title: 'Atención',
                    message: "Por favor ingrese el motivo del rechazo",
                    type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
                    container: 'top-right',                // where to position the notifications
                    animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                    animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                    dismiss: {
                      duration: 10000,
                      showIcon:true
                    }
                  })
                }else{
      
                  this.aceptarcita();
                }         
              
              }
            }

          }else{

            if(tipo_servicio_cita==3){

                if(respuesta=='true'){

                  id_regi=1;
                  id_nivel=1;
                  id_afilia=1;
                  tipo_consulta=1;

                  if( idcontra==null ||   tipo==null || rautoriza=='' || rcauota=='' ){
          
                    store.addNotification({
                      title: 'Atención',
                      message: "Faltan datos por ingresar",
                      type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
                      container: 'top-right',                // where to position the notifications
                      animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                      animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                      dismiss: {
                        duration: 10000,
                        showIcon:true
                      }
                    })
            
                  }else{
            
                    this.Confirmar_cita();
                    //this.requierecita();
            
                  }
          
                }else{
                  if(respuesta=='false'){
          
                    rcauota='false'
                    rautoriza='false'
          
                    if(this.state.form.obser==''){
                      store.addNotification({
                        title: 'Atención',
                        message: "Por favor ingrese el motivo del rechazo",
                        type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
                        container: 'top-right',                // where to position the notifications
                        animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                        animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                        dismiss: {
                          duration: 10000,
                          showIcon:true
                        }
                      })
                    }else{
          
                      this.aceptarcita();
                    }         
                  
                  }
                }


            }

          }
 
            
       
     }


     Confirmar_cita(){

      if( rautoriza=='false' && tipo_consulta=='0'){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

       }else{

        if( rautoriza=='true'){

          this.handleModalconfirmarcita();
  
        }else{

          this.handleModalconfirmarcita();

        }

       }      

     }


    Traerdato_Cancelar_cita(cita_confir){
      id_cita_cancela  = cita_confir.id;

      $('#modal-cancela_cita').modal('show'); // <- to show modal elimiinar intervalo

     }


     aceptarcita=async()=>{

      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ button_rta_cita: this.state.button_rta_cita = false })
      await axios.put(ApiURL+"/api/Citas/ResponseRequest",
  
          {appointmentId: this.state.form.id_cita,
          accepted: respuesta,
          contractId: idcontra,
          regimeId: id_regi,
          level:id_nivel,
          typeAffiliateId: id_afilia,
          tipoConsultaId: tipo_consulta,
          type: tipo,
          requireAuth: rautoriza,
          requirePayment: rcauota,
          annotation:this.state.form.obser
          } )          
          //this.requierecita()
  
        .then(response=>{
          console.log(response)
          //var  dato  = response.status;
          //alert(dato);
          mensaje  = response.data.message;
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);

          //var  dato  = this.state.form.id_soli_acep;
          //alert(dato);
                 
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })


          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ button_rta_cita: this.state.button_rta_cita = true })
          this.Citas_solicitadas();
          this.Citas_enproceso();

          $('#modal-confirmar_cita').modal('hide');
          $('#modal-respuestacita').modal('hide');
          $('#modal-respuestacita-particular').modal('hide'); // <- to show modal
          //$('#btn_confirmar').show();
          //$('#btn_aceptar').show();
          this.handleModalrespuestacitadrop();
          
        })
        .catch(error=>{
          
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ button_rta_cita: this.state.button_rta_cita = true })
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ button_rta_cita: this.state.button_rta_cita = true })
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ button_rta_cita: this.state.button_rta_cita = true })
        }
        //console.log(error.config);
      
      })
  
     }



     Historial_Citas=async()=>{

      this.setState({ loading_btnhis: this.state.loading_btnhis = false })
      this.setState({ active_btnhis: this.state.active_btnhis = false })

      await axios.post(ApiURL+"/api/Citas/GetAppointmentHistoryPatient",
  
          {
          typeId: this.state.form.tipoid_pacien_hc,
          identification: this.state.form.id_pacien_hc,
          } )          
          //this.requierecita()
  
        .then(response=>{
          console.log(response)      
          
          this.setState({ loading_btnhis: this.state.loading_btnhis = true })
          this.setState({ active_btnhis: this.state.active_btnhis = true })

          this.setState({Historial_citas: response.data})   
          
          if(this.state.Historial_citas.length==0){

            store.addNotification({
              title: 'Atención',
              message: 'El paciente no tiene historial de solicitudes',
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

          }else{

            this.handleModalhistorialcitas();

          }
         
          
          
        })
        .catch(error=>{
          
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

            this.setState({ loading_btnhis: this.state.loading_btnhis = true })
            this.setState({ active_btnhis: this.state.active_btnhis = true })
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
            this.setState({ loading_btnhis: this.state.loading_btnhis = true })
            this.setState({ active_btnhis: this.state.active_btnhis = true })
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.setState({ loading_btnhis: this.state.loading_btnhis = true })
            this.setState({ active_btnhis: this.state.active_btnhis = true })
        }
        //console.log(error.config);
      
      })
  
     }


     requierecita=async()=>{
      
      await axios.put(ApiURL+"/api/Citas/SetRequirements",
  
          {appointmentId: this.state.form.id_cita,
          requireAuth: rautoriza,
          requirePayment: rcauota         
          } )          
          
  
        .then(response=>{
          console.log(response)
          //var  dato  = response.status;
          //alert(dato);
          mensaje_rta  = response.data.message;
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);

          //var  dato  = this.state.form.id_soli_acep;
          //alert(dato);
                 
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje_rta,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.Citas_solicitadas();
          
        })
        .catch(error=>{
          
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje_rta=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje_rta,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

           
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.mensaje_rta);
        }
        //console.log(error.config);
      
      })
  
     }


     Get_Idautorizacion=(autoriza)=>{
      
      this.setState({        
        form:{
          id_autorizacion:autoriza.id,
          obser_autorizacion:'',
          nom1_pacien:this.state.form.nom1_pacien,
          nom2_pacien:this.state.form.nom2_pacien,
          apell1_pacien:this.state.form.apell1_pacien,
          apell2_pacien:this.state.form.apell2_pacien,     
          nom1medico_soli:this.state.form.nom1medico_soli,
          nom2medico_soli: this.state.form.nom2medico_soli,
          apell1medico_soli: this.state.form.apell1medico_soli,
          apell2medico_soli:this.state.form.apell2medico_soli,  
          tipoid_pacien_hc: this.state.form.tipoid_pacien_hc,
          id_pacien_hc: this.state.form.id_pacien_hc,            
        }
      })
      this.setState({ button_rta_cita: this.state.button_rta_cita = true })
     
    }


    respuesta_acepta_autori(){
      if(racepta_autoriza=='false'){

        if(this.state.form.obser_autorizacion.length==0){

          store.addNotification({
            title: 'Atención',
            message: "Ingrese un mensaje para el paciente",
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

        }else{

          this.respuesta_autorizacion();

        }
       

      }else{

        if(racepta_autoriza==''){

          store.addNotification({
            title: 'Atención',
            message: "Ingrese un mensaje para el paciente",
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
          
        }else{
          
          if(racepta_autoriza=='true' && tipo_consulta=='0'){

            store.addNotification({
              title: 'Atención',
              message: "Faltan datos por ingresar",
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })   

          }else{
            this.respuesta_autorizacion();
          } 

        }
        
      }
    }


    respuesta_autorizacion=()=>{
      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ button_rta_cita: this.state.button_rta_cita = false })
     
      axios.put(ApiURL+"/api/Citas/ValidateAuthorization",

        {
        authorizationId: this.state.form.id_autorizacion,
        tipoConsultaId: tipo_consulta,
        accepted: racepta_autoriza,
        anotation: this.state.form.obser_autorizacion         
        })
        
      .then((response) => {
        console.log(response.data)
          mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);          
                 
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ button_rta_cita: this.state.button_rta_cita = true })
          this.Citas_enproceso();
          $('#modal-respuesta_autorizacion').modal('hide');
          this.handleModalrespuesta_autorizaciondrop();
        
      })
      .catch(error => {
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          mensaje=error.response.data.message
          
          store.addNotification({
            title: 'Atención',
            message: mensaje,
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ button_rta_cita: this.state.button_rta_cita = true })
      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
      }
      //console.log(error.config);
      })
    }


    Cancelar_cita=()=>{
     
      //var  dato  = horario.id;
      //alert(id_cita_cancela);

      this.setState({ loading_button_cancelacita: this.state.loading_button_cancelacita = false })
      this.setState({ active_button_cancelacita: this.state.active_button_cancelacita = false })

      axios.put(ApiURL+"/api/Citas/Cancelation/"+id_cita_cancela)
      .then((response) => {
        console.log(response.data)
          mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);          
                 
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          }) 
          
          this.setState({ loading_button_cancelacita: this.state.loading_button_cancelacita = true })
          this.setState({ active_button_cancelacita: this.state.active_button_cancelacita = true })

          this.Citas_Asignadas();
          $('#modal-cancela_cita').modal('hide');          
         
        
      })
      .catch((error) => {
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          mensaje=error.response.data.message
          
          store.addNotification({
            title: 'Atención',
            message: mensaje,
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button_cancelacita: this.state.loading_button_cancelacita = true })
          this.setState({ active_button_cancelacita: this.state.buttoactive_button_cancelacitan_rta_cita = true })
      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          this.setState({ loading_button_cancelacita: this.state.loading_button_cancelacita = true })
          this.setState({ active_button_cancelacita: this.state.buttoactive_button_cancelacitan_rta_cita = true })
          console.log(error.request);
      } else {
          // Something happened in setting up the request and triggered an Error
          this.setState({ loading_button_cancelacita: this.state.loading_button_cancelacita = true })
          this.setState({ active_button_cancelacita: this.state.buttoactive_button_cancelacitan_rta_cita = true })
          console.log('Error', error.message);
      }
      //console.log(error.config);
      })
    }
    

     Cuota_mod(){      

      if(id_regi==5 & id_nivel==1){        

        this.setState({
          form:{
            tipoid_pacien_hc: this.state.form.tipoid_pacien_hc,
            id_pacien_hc: this.state.form.id_pacien_hc,
            nom1_pacien:this.state.form.nom1_pacien,
            nom2_pacien:this.state.form.nom2_pacien,
            apell1_pacien:this.state.form.apell1_pacien,
            apell2_pacien:this.state.form.apell2_pacien,
            nom1medico_soli:this.state.form.nom1medico_soli,
            nom2medico_soli:this.state.form.nom2medico_soli,
            apell1medico_soli:this.state.form.apell1medico_soli,
            apell2medico_soli:this.state.form.apell2medico_soli,
            cuota_mod:'3500',
            id_cita: this.state.form.id_cita             
          }
        })
      }else{

        if(id_regi==5 & id_nivel==2){

          this.setState({
            form:{
              tipoid_pacien_hc: this.state.form.tipoid_pacien_hc,
              id_pacien_hc: this.state.form.id_pacien_hc,
              nom1_pacien:this.state.form.nom1_pacien,
              nom2_pacien:this.state.form.nom2_pacien,
              apell1_pacien:this.state.form.apell1_pacien,
              apell2_pacien:this.state.form.apell2_pacien,
              nom1medico_soli:this.state.form.nom1medico_soli,
              nom2medico_soli:this.state.form.nom2medico_soli,
              apell1medico_soli:this.state.form.apell1medico_soli,
              apell2medico_soli:this.state.form.apell2medico_soli,
              cuota_mod:'14000',  
              id_cita: this.state.form.id_cita            
            }
          })
        }else{
          if(id_regi==5 & id_nivel==3){

            this.setState({
              form:{
                tipoid_pacien_hc: this.state.form.tipoid_pacien_hc,
                id_pacien_hc: this.state.form.id_pacien_hc,
                nom1_pacien:this.state.form.nom1_pacien,
                nom2_pacien:this.state.form.nom2_pacien,
                apell1_pacien:this.state.form.apell1_pacien,
                apell2_pacien:this.state.form.apell2_pacien,
                nom1medico_soli:this.state.form.nom1medico_soli,
                nom2medico_soli:this.state.form.nom2medico_soli,
                apell1medico_soli:this.state.form.apell1medico_soli,
                apell2medico_soli:this.state.form.apell2medico_soli,
                cuota_mod:'36800',
                id_cita: this.state.form.id_cita              
              }
            })
          }else{
            this.setState({
              form:{
                tipoid_pacien_hc: this.state.form.tipoid_pacien_hc,
                id_pacien_hc: this.state.form.id_pacien_hc,
                nom1_pacien:this.state.form.nom1_pacien,
                nom2_pacien:this.state.form.nom2_pacien,
                apell1_pacien:this.state.form.apell1_pacien,
                apell2_pacien:this.state.form.apell2_pacien,
                nom1medico_soli:this.state.form.nom1medico_soli,
                nom2medico_soli:this.state.form.nom2medico_soli,
                apell1medico_soli:this.state.form.apell1medico_soli,
                apell2medico_soli:this.state.form.apell2medico_soli,
                cuota_mod:'0',
                id_cita: this.state.form.id_cita              
              }
            })
          }
        }
        
      } 
       
     }

     Requiere_cita(){

      if(respuesta==='true'){

        $('#requiere_cita').show();
        $('#seccion_tipoafiliacion').show();
        $('#seccion_regimen').show();          
        $('#seccion_nivel-contrato').show();
        $('#seccion_regimen_nivel').show();
        $('#select_tipo_consulta').hide();
        $('#seccion_observacion').show();
        
        $('#seccion_contrato_p').show();
        $('#seccion_tipocita_p').show();        
        $('#requiere_cita_p').show();
        $('#seccion_observacion_p').show();
        
        this.setState({ button_rta_cita: this.state.button_rta_cita = true })
       
      }else{ 
        if(respuesta==='false'){

          $('#requiere_cita').hide();
          $('#seccion_tipoafiliacion').hide();
          $('#seccion_regimen').hide();          
          $('#seccion_nivel-contrato').hide();
          $('#seccion_regimen_nivel').hide();
          $('#select_tipo_consulta').hide();
          $('#seccion_observacion').show();

          $('#seccion_contrato_p').hide();
          $('#seccion_tipocita_p').hide();
          $('#requiere_cita_p').hide();
          $('#seccion_observacion_p').show();
          this.setState({ button_rta_cita: this.state.button_rta_cita = true })
         
        }else{

          $('#requiere_cita').hide();
          $('#seccion_tipoafiliacion').hide();
          $('#seccion_regimen').hide();          
          $('#seccion_nivel-contrato').hide(); 
          $('#seccion_regimen_nivel').hide();
          $('#select_tipo_consulta').hide();
          $('#seccion_observacion').hide();

          $('#seccion_contrato_p').hide();
          $('#seccion_tipocita_p').hide();
          $('#requiere_cita_p').hide();
          $('#seccion_observacion_p').hide();
          this.setState({ button_rta_cita: this.state.button_rta_cita = false }) 

        }         
        
      } 
       
     }


    handleridtipoid = (t) => {
      console.log(t)
      tipo_id=(t)
      //alert(tipo_id)
    }
    
    handleridprofesional = (p) => {
      console.log(p)
      id_profe=(p)
      //alert(id_profe)
      //this.Cargar_Especialidades(id_profe)
    }
    

    handleridregimen = (r) => {
      console.log(r)
      id_regi=(r)
      //alert(id_afilia)
      this.Detalle_CM(id_nivel);

      if(id_regi==1){
        $('#afilia_beneficiario').show();
        $('#cb_afilia').hide();
        $("#cheksi_cuotamoderadora").find("*").prop('disabled', true);
        $("#chekno_cuotamoderadora").find("*").prop('disabled', true);
        $("#Rcuota_modsi").prop("checked", false);
        $("#Rcuota_modno").prop("checked", false);       
       
        id_afilia=2
        rcauota='false'
        this.setState({ button_rta_cita: this.state.button_rta_cita = true })

      }else{
        $('#afilia_beneficiario').hide();
        $('#cb_afilia').show();
        $("#api_tipoafilia").val($('#api_tipoafilia > option:first').val());
        $("#cheksi_cuotamoderadora").find("*").prop('disabled', false);
        $("#chekno_cuotamoderadora").find("*").prop('disabled', false);    
        
        id_afilia=null
        rcauota=''
      }
    }


    handleridafiliacion = (a) => {
      console.log(a)
      id_afilia=(a)
      //alert(id_afilia)
    }

    handlerideps = (p) => {
      console.log(p)
      id_entity=(p)
      //alert(id_entity)
    }

    handleridcontra = (c) => {
      console.log(c)
      idcontra=(c)
      //alert(idcontra)
    }
   
       

    render(){ 
      const {form} = this.state; 

     return (
     
      <div className="content-wrapper">

          <div className="col-12 col-sm-12">
                <div className="row">

                    <div className="col-sm-10">   
                    <br></br>       
                      <div className="text-center" id="title"> <h6> <b> CITAS AMBULATORIAS </b>  </h6> </div>          
                    </div>                   
          
                </div>

                
                <div className="card card-primary card-outline card-tabs">
                      <div className="card-header p-0 pt-1 border-bottom-0">
                          
                          <ul className="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                              <li className="nav-item">
                                <a className="nav-link active" id="custom-tabs-three-home-tab" data-toggle="pill" href="#custom-tabs-three-home" role="tab" aria-controls="custom-tabs-three-home" aria-selected="true" onClick={()=>{this.Citas_solicitadas()}}> <span className="badge badge-danger">{soli_pendientes}</span> Solicitudes recibidas <Logo_clock/> </a>
                              </li> 
                              <li className="nav-item">
                                <a className="nav-link" id="custom-tabs-three-proceso-tab" data-toggle="pill" href="#custom-tabs-three-proceso" role="tab" aria-controls="custom-tabs-three-proceso" aria-selected="false" onClick={()=>{this.Citas_enproceso()}}> <span className="badge badge-danger">{soli_proceso}</span> Citas en proceso <Logo_clockeart/> </a>
                              </li>                             
                              <li className="nav-item">
                                <a className="nav-link" id="custom-tabs-three-messages-tab" data-toggle="pill" href="#custom-tabs-three-messages" role="tab" aria-controls="custom-tabs-three-messages" aria-selected="false" onClick={()=>{this.Citas_Asignadas()}} >Citas confirmadas <Logo_agenda/> </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link" id="custom-tabs-three-rechazada-tab" data-toggle="pill" href="#custom-tabs-three-rechazada" role="tab" aria-controls="custom-tabs-three-rechazada" aria-selected="false" onClick={()=>{this.Citas_rechazadas()}} >Citas rechazadas <Logo_rechaza/> </a>
                              </li> 
                              <li className="nav-item">
                                <a className="nav-link" id="custom-tabs-three-cancelada-tab" data-toggle="pill" href="#custom-tabs-three-cancelada" role="tab" aria-controls="custom-tabs-three-cancelada" aria-selected="false" onClick={()=>{this.Citas_canceladas()}} >Citas canceladas <Logo_rechaza/> </a>
                              </li> 
                              <li className="nav-item">
                                <a className="nav-link" id="custom-tabs-three-profile-tab" data-toggle="pill" href="#custom-tabs-three-profile" role="tab" aria-controls="custom-tabs-three-profile" aria-selected="false" onClick={()=>{this.Cargar_sedes();this.Cargar_Especialidades(); this.Cargar_tipos_consulta()}} >Asignar cita <Logo_nuevo/> </a>
                              </li>  
                                           
                          </ul>
                        
                      </div>


                      <div className="card-body">

                            <div className="tab-content" id="custom-tabs-three-tabContent">

                                  <div className="tab-pane fade show active" id="custom-tabs-three-home" role="tabpanel" aria-labelledby="custom-tabs-three-home-tab">
                                    
                                      <div class="d-flex justify-content-center">
                                                
                                          {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
                                          {this.state.estado_sonido?false:<Sound/>}
                                          
                                      </div>
                                      
                                      <div className="" id="tabla1">                                         

                                          <div className="row">                                   
                                                
                                                <div className="col-sm-3">
                                                    <div className="input-group input-group-sm mb-3 mr-sm-2">                                                      
                                                      <select onChange={this.Gettipo_filtro}  className="form-control" id="tipo_filtro" name="tipo_filtro">
                                                          <option  value="0"> Filtrar por...</option>
                                                          <option  value="1"> Sede</option>
                                                          <option  value="2"> Texto</option>
                                                          <option  value="10"> Limpiar filtro</option>
                                                              
                                                      </select>                      
                                                    </div>                       
                                                </div>

                                                <div className="col-sm-3" id="texto_filtro">
                                                      <div className="input-group input-group-sm mb-3 mr-sm-2">
                                                         <div className="input-group-prepend">
                                                           <div className="input-group-text"><Buscar/></div>
                                                         </div>
                                                         <input type="search" className="form-control" placeholder="Buscar" name="buscar_solicitadas" id="buscar_solicitadas" onChange={this.BuscarSolicitada}/>
                                                      </div>                                
                                                </div>

                                                <div className="col-md-3" id="select_sede_filtro">                                 
                                                    <div className="input-group input-group-sm mb-3 mr-sm-2">                    
                                                              
                                                              <select onChange={this.GetSede_filtro}  className="form-control" id="sede_filtro" name="sede_filtro">
                                                                  <option  value="0"> Seleccione...</option> ,
                                                                  {this.state.Sedes_filtro.map(sede=>                              
                                                                  <option key={sede.id} value={sede.name}> {(sede.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                                                  )}                                                                      
                                                              </select>                   
                                                    </div>
                                                </div> 
                                                
                                            </div>

                                            <table id="tabla_solicitudes_cita" className="table table-bordered table-striped table-hover">
                                                <thead>
                                                  <tr className="info">
                                                      <th>ID Cita</th>
                                                      <th>Fecha solicitud</th>
                                                      <th>Fecha cita</th>
                                                      <th>Paciente</th>   
                                                      <th>Médico</th> 
                                                      <th>Especialidad</th> 
                                                      <th>Tipo de cita</th>                                                      
                                                      <th>Tiempo de solicitud</th>  
                                                      <th>Sede</th>    
                                                      <th>Obs. paciente</th>                                           
                                                  </tr>
                                                </thead>

                                                <tbody>                                                                                                                                       
                                                 
                                                      {this.state.Citas_soli.map(cita=>{

                                                        
                                                          var fecha1 = new Date(this.state.currentDateTime)
                                                          var fecha2 = new Date(cita.created)                                                           
                                                          var dife=fecha1-fecha2
                                                          //alert(dife)
                                                          var tipo_cita=cita.contract.entityId

                                                          if(tipo_cita=='999'){

                                                            tipo_cita='Cita particular'
                                                           

                                                          }else{

                                                            tipo_cita='Cita por EPS'
                                                            
                                                          }

                                                        return(
                                                                                                          
                                                        <tr onClick={()=>{this.handleModalrespuestacita(cita);this.seleccionarsolicitudcita(cita)}}>
                                                          <td>{cita.id}</td> 
                                                          <td> 
                                                          <Moment format="YYYY/MM/DD HH:mm">
                                                          {cita.created}
                                                          </Moment>
                                                          </td>
                                                          <td> 
                                                          <Moment format="YYYY/MM/DD HH:mm">
                                                          {cita.fecha}
                                                          </Moment>
                                                          </td>         
                                                          <td>{(cita.patient.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))} {(cita.patient.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))} {(cita.patient.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))} {(cita.patient.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>                                                                
                                                          <td>{cita.professional.user.name1 ? (cita.professional.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} {cita.professional.user.name2 ? (cita.professional.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} { cita.professional.user.lastname1 ? (cita.professional.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} { cita.professional.user.lastname2 ? (cita.professional.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''}  </td>               
                                                          <td>{(cita.specialty.name).toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                                          <td>{tipo_cita}</td>
                                                          <td><Moment fromNow ago locale='es'> 
                                                          {cita.created} 
                                                          </Moment>
                                                          </td>
                                                          <td>{(cita.schedule.sede.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                                          <td>{(cita.requestAnotation ? cita.requestAnotation.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '')}</td>
                                                        </tr>
                                                        )
                                                       }
                                                       )}                                          
                                          
                                                </tbody>                                                                
                                              
                                            </table> 

                                            <span>{this.state.pager_infocitas_soli}</span>
                                                <nav className="nav">
                                                
                                                    <ul className="pagination">
              
                                                        {this.state.Pager_Citas_soli.map(page_solici=>{
                                                        
                                                          var activo=page_solici.activo
                                                          if(activo==true){
                                                            activo='page-item active'
                                                          }else{
                                                            activo='page-item'
                                                          }
              
                                                         // alert(activo)
                                                          
                                                         return(                          
                                                                  
                                                                 <li className={activo}>
                                                                   <a className="nav-link" onClick={()=>{this.loadMoreDatasolicitadas(page_solici)}}>{page_solici.texto}</a>
                                                                 </li>                                            
                                                           )
                                                          }
                                                         )
                                                        }

                                                    </ul>
                                                          
                                                </nav>                           
                                         
                                      </div> 

                                      <div className="d-flex justify-content-center">                              
                                           <img id="empty_page" src={emptypage} alt="logo"  width= "200"/>                             
                                      </div>      
                                      <div className="d-flex justify-content-center">                  
                                           <h5 id="text_page"> <b>No hay información que mostrar</b> </h5>                          
                                      </div>                   
                                   
                                  </div> 


                                  <div className="tab-pane fade" id="custom-tabs-three-proceso" role="tabpanel" aria-labelledby="custom-tabs-three-proceso-tab">                                             
                                                                          
                                        <div class="d-flex justify-content-center">
                                            
                                            {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
                                    
                                        </div>
                                        
                                        <div className="" id="tabla2">                                    

                                            <div className="row">
                                      
                                                <div className="col-sm-3">
                                                      <div className="input-group input-group-sm mb-3 mr-sm-2">
                                                         <div className="input-group-prepend">
                                                           <div className="input-group-text"><Buscar/></div>
                                                         </div>
                                                         <input type="search" className="form-control" placeholder="Buscar" name="buscar_enproceso" onChange={this.BuscarEnproceso}/>
                                                      </div>                                
                                                </div>
                                                
                                            </div>
                             
                                                <table className="table table-bordered table-striped table-hover">
                                                    <thead>
                                                      <tr>
                                                        <th>ID</th>
                                                        <th>Fecha solicitud</th>
                                                        <th>Fecha cita</th>
                                                        <th>Paciente</th>   
                                                        <th>Médico</th> 
                                                        <th>Especialidad</th> 
                                                        <th>Estado</th>                                                                                                          
                                                        <th>Obs. prestador</th>   
                                                        <th>Sede</th>   
                                                        <th>Responsable</th>
                                                        <th>Tipo de cita</th>
                                                        <th>Tiempo de solicitud</th>  
                                                        <th>Ver archivo adjunto</th> 
                                                        <th>Obs. paciente</th>

                                                      </tr>
                                                    </thead>

                                                    <tbody>                                              
                                                   
                                                        {this.state.Citas_proceso.map(cita_proce=>{
                                                          var dife=0
                                                          var timer=''
                                                          var time=''

                                                          var tipo_cita=cita_proce.contract.entityId

                                                          if(tipo_cita=='999'){

                                                            tipo_cita='Cita particular'
                                                           

                                                          }else{

                                                            tipo_cita='Cita por EPS'
                                                            
                                                          }

                                                          var Trazabilidad_usuario=[]
                                                          Trazabilidad_usuario=cita_proce.traceAppointments

                                                          var usua_resp=''

                                                          if((Trazabilidad_usuario.length%2)==0){
                                                          Trazabilidad_usuario=cita_proce.traceAppointments
                                                          var posicion_usua_resp=Trazabilidad_usuario.length-1
                                                          usua_resp=Trazabilidad_usuario[posicion_usua_resp].user.name1 ? Trazabilidad_usuario[posicion_usua_resp].user.name1 : ' ' + ' ' + Trazabilidad_usuario[posicion_usua_resp].user.name2 ? Trazabilidad_usuario[posicion_usua_resp].user.name2: ' '  + ' ' + Trazabilidad_usuario[posicion_usua_resp].user.lastname1 ? Trazabilidad_usuario[posicion_usua_resp].user.lastname1 : ' ' + ' ' + Trazabilidad_usuario[posicion_usua_resp].user.lastname2 ?  Trazabilidad_usuario[posicion_usua_resp].user.lastname2 : ' '
                                                          //alert(Trazabilidad_usuario[posicion_usua_resp].id) 
                                                          }else{
                                                            var posicion_usua_resp=Trazabilidad_usuario.length-2
                                                            usua_resp=Trazabilidad_usuario[posicion_usua_resp].user.name1 ? Trazabilidad_usuario[posicion_usua_resp].user.name1 : ' ' + ' ' + Trazabilidad_usuario[posicion_usua_resp].user.name2 ? Trazabilidad_usuario[posicion_usua_resp].user.name2: ' '  + ' ' + Trazabilidad_usuario[posicion_usua_resp].user.lastname1 ? Trazabilidad_usuario[posicion_usua_resp].user.lastname1 : ' ' + ' ' + Trazabilidad_usuario[posicion_usua_resp].user.lastname2 ?  Trazabilidad_usuario[posicion_usua_resp].user.lastname2 : ' '  
                                                          }

                                                          cita_proce.authorizations.map(autoritime=>{
                                                          time=autoritime.updated
                                                          

                                                          var fecha1 = new Date(this.state.currentDateTime+1)
                                                          var fecha2 = new Date(time)  
                                                          //alert(time)                                                         
                                                          dife=fecha1-fecha2
                                                          

                                                        })
                                                          
                                                          var estado=''
                                                        if(cita_proce.authorizations.length > 0){
                                                         estado=<button className="btn btn-edit" onClick={()=>{this.handleModalrespuesta_autorizacion();this.seleccionarcitaenproceso(cita_proce)}} > <BtnDownload/> </button>                                                  
                                                         timer=<Moment fromNow ago locale='es'> 
                                                         {time} 
                                                         </Moment>

                                                        }else{
                                                            estado=''
                                                        }                                                        

                                                        
                                                            
                                                        return(
                                                
                                                        <tr>
                                                          <td>{cita_proce.id}</td> 
                                                          <td> 
                                                          <Moment format="YYYY/MM/DD HH:mm">
                                                          {cita_proce.created}
                                                          </Moment>
                                                          </td>
                                                          <td> 
                                                          <Moment format="YYYY/MM/DD HH:mm">
                                                          {cita_proce.fecha}
                                                          </Moment>
                                                          </td>         
                                                          <td>{ cita_proce.patient.user.name1 ? (cita_proce.patient.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} { cita_proce.patient.user.name2 ? (cita_proce.patient.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} { cita_proce.patient.user.lastname1 ?(cita_proce.patient.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} { cita_proce.patient.user.lastname2 ? (cita_proce.patient.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''}</td>                                                                
                                                          <td>{ cita_proce.professional.user.name1 ? (cita_proce.professional.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' } { cita_proce.professional.user.name2 ? (cita_proce.professional.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} { cita_proce.professional.user.lastname1 ? (cita_proce.professional.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ' '} { cita_proce.professional.user.lastname2 ? (cita_proce.professional.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''}  </td>               
                                                          <td>{(cita_proce.specialty.name).toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                                          <td>{(cita_proce.state).toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>    
                                                          <td>{cita_proce.anotation}</td>
                                                          <td>{(cita_proce.schedule.sede.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                                          {/* <td>{Trazabilidad_usuario.map(usuario=>{ return( usuario.user.name1 )})} </td> */}
                                                          <td>{usua_resp.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                                          <td>{tipo_cita}</td>
                                                          <td><Moment fromNow ago locale='es'> 
                                                          {cita_proce.updated} 
                                                          </Moment>
                                                          </td>
                                                          <td>{estado}</td>
                                                          <td>{(cita_proce.requestAnotation ? cita_proce.requestAnotation.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '')}</td>
                                                          
                                                        </tr>
                                                        )                                                      
                                                       }
                                                       )}
                                              
                                                    </tbody>

                                                </table>

                                                <span>{this.state.pager_infocitas_proceso}</span>
                                                <nav className="nav">
                                                
                                                    <ul className="pagination">
              
                                                        {this.state.Pager_Citas_proceso.map(page_proce=>{
                                                        
                                                          var activo=page_proce.activo
                                                          if(activo==true){
                                                            activo='page-item active'
                                                          }else{
                                                            activo='page-item'
                                                          }
              
                                                         // alert(activo)
                                                          
                                                         return(                          
                                                                  
                                                                 <li className={activo}>
                                                                   <a className="nav-link" onClick={()=>{this.loadMoreDataproceso(page_proce)}}>{page_proce.texto}</a>
                                                                 </li>                                            
                                                           )
                                                          }
                                                         )
                                                        }
                                                                      
                                                    </ul>
                                                          
                                                </nav>                                                        
                                                        
                                         
                                        </div>

                                        <div className="d-flex justify-content-center">                              
                                           <img id="empty_page2" src={emptypage} alt="logo"  width= "200"/>                             
                                        </div>
                                        <div className="d-flex justify-content-center">                  
                                           <h5 id="text_page2"> <b>No hay información que mostrar</b> </h5>                          
                                        </div> 
                                        
                                       
                                  </div>               
                                   

                                  <div className="tab-pane fade" id="custom-tabs-three-messages" role="tabpanel" aria-labelledby="custom-tabs-three-messages-tab">                                             
                                                                          
                                        <div class="d-flex justify-content-center">
                                            
                                            {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
                                    
                                        </div>
                                        
                                        <div className="" id="tabla3">                                             

                                            <div className="row">
                                      
                                                <div className="col-sm-3">
                                                      <div className="input-group input-group-sm mb-3 mr-sm-2">
                                                         <div className="input-group-prepend">
                                                           <div className="input-group-text"><Buscar/></div>
                                                         </div>
                                                         <input type="search" className="form-control" placeholder="Buscar" name="buscar_confirmada" onChange={this.BuscarCitas}/>
                                                      </div>                                
                                                </div>
                                                
                                            </div>
                             
                                                <table id="example1" className="table table-bordered table-striped table-hover">
                                                    <thead>
                                                      <tr>
                                                        <th>ID</th>
                                                        <th>Fecha solicitud</th>
                                                        <th>Fecha cita</th>
                                                        <th>Paciente</th>   
                                                        <th>Médico</th> 
                                                        <th>Especialidad</th> 
                                                        <th>Modalidad de cita</th>                                                        
                                                        {/* <th>Observación</th>  */}                                                        
                                                        <th>Sede</th>
                                                        <th>Responsable</th> 
                                                        <th>Tipo de cita</th>
                                                        <th>Detalles</th> 
                                                        <th>Obs. paciente</th>
                                                        <th>Cancelar</th>                                                           
                                                                 
                                                      </tr>
                                                    </thead>

                                                    <tbody>                                              
                                                   
                                                        {this.state.Citas.map(cita_confir=>{

                                                        var Trazabilidad_usuarioc=[]
                                                        
                                                        Trazabilidad_usuarioc=cita_confir.traceAppointments
                                                        
                                                        var usua_respon=''  
                                                        
                                                        var tipo_cita=cita_confir.contract.entityId

                                                          if(tipo_cita=='999'){

                                                            tipo_cita='Cita particular'
                                                           

                                                          }else{

                                                            tipo_cita='Cita por EPS'
                                                            
                                                          }
                                                        
                                                        if(Trazabilidad_usuarioc.length==0){
                                                        //usua_respon=Trazabilidad_usuarioc[1].user.name1 + ' ' + Trazabilidad_usuarioc[1].user.name2  + ' ' + Trazabilidad_usuarioc[1].user.lastname1 + ' ' + Trazabilidad_usuarioc[1].user.lastname2
                                                        //alert("Sin responsable") 
                                                        usua_respon=''
                                                        }else{
                                                          var posicion_usua_resp=Trazabilidad_usuarioc.length-1
                                                          usua_respon=Trazabilidad_usuarioc[posicion_usua_resp].user.name1 ? Trazabilidad_usuarioc[posicion_usua_resp].user.name1 :'' + ' ' + Trazabilidad_usuarioc[posicion_usua_resp].user.name2 ? Trazabilidad_usuarioc[posicion_usua_resp].user.name2 : ' '  + ' ' + Trazabilidad_usuarioc[posicion_usua_resp].user.lastname1 ? Trazabilidad_usuarioc[posicion_usua_resp].user.lastname1 : ' ' + ' ' + Trazabilidad_usuarioc[posicion_usua_resp].user.lastname2 ? Trazabilidad_usuarioc[posicion_usua_resp].user.lastname2 : ' '
                                                        }


                                                        var estado=''
                                                        if(cita_confir.authorizations.length > 0){
                                                         estado=<button className="btn btn-edit" onClick={()=>{this.handleModalrespuesta_autorizacion();this.seleccionarcitacofirm(cita_confir)}} > <BtnDownload/> </button>                                                  
                                                        

                                                        }else{
                                                            estado=''
                                                        } 
                                                          
                                                            
                                                        return(
                                                
                                                        <tr>

                                                          <td>{cita_confir.id}</td> 
                                                          <td> 
                                                          <Moment format="YYYY/MM/DD HH:mm">
                                                          {cita_confir.created}
                                                          </Moment>
                                                          </td>
                                                          <td> 
                                                          <Moment format="YYYY/MM/DD HH:mm">
                                                          {cita_confir.fecha}
                                                          </Moment>
                                                          </td>         
                                                          <td>{ cita_confir.patient.user.name1 ? (cita_confir.patient.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} {cita_confir.patient.user.name2 ? (cita_confir.patient.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} { cita_confir.patient.user.lastname1 ? (cita_confir.patient.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} { cita_confir.patient.user.lastname2 ? (cita_confir.patient.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''}</td>                                                                
                                                          <td>{ cita_confir.professional.user.name1 ? (cita_confir.professional.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} { cita_confir.professional.user.name2 ? (cita_confir.professional.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} { cita_confir.professional.user.lastname1 ? (cita_confir.professional.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} { cita_confir.professional.user.lastname2 ? (cita_confir.professional.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''}  </td>               
                                                          <td>{(cita_confir.specialty.name).toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                                          <td>{(cita_confir.type).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>                                                          
                                                          {/* <td>{cita_confir.anotation}</td> */}                                                          
                                                          <td>{(cita_confir.schedule.sede.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                                          <td>{usua_respon.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                                          <td>{tipo_cita}</td>
                                                          <td>{estado}</td>
                                                          <td>{(cita_confir.requestAnotation ? cita_confir.requestAnotation.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '')}</td>
                                                          <td onClick={()=>{this.Traerdato_Cancelar_cita(cita_confir)}}> <BtnDelete />  </td>
                                                        </tr>
                                                        )
                                                       }
                                                       )}
                                              
                                                    </tbody>

                                                </table>
                             
                                                <span>{this.state.pager_infocitas}</span>
                                                <nav className="nav">
                                                
                                                    <ul className="pagination">
              
                                                        {this.state.Pager_Citas.map(page_asigna=>{
                                                        
                                                          var activo=page_asigna.activo
                                                          if(activo==true){
                                                            activo='page-item active'
                                                          }else{
                                                            activo='page-item'
                                                          }
              
                                                         // alert(activo)
                                                          
                                                         return(                          
                                                                  
                                                                 <li className={activo}>
                                                                   <a className="nav-link" onClick={()=>{this.loadMoreDataasignada(page_asigna)}}>{page_asigna.texto}</a>
                                                                 </li>                                            
                                                           )
                                                          }
                                                         )
                                                        }

                                                    </ul>
                                                          
                                                </nav>                                              
                                         
                                        </div>

                                        <div className="d-flex justify-content-center">                              
                                           <img id="empty_page3" src={emptypage} alt="logo"  width= "200"/>                             
                                        </div>

                                        <div className="d-flex justify-content-center">                  
                                           <h5 id="text_page3"> <b>No hay información que mostrar</b> </h5>                          
                                        </div> 
                                        
                                       
                                  </div>


                                  <div className="tab-pane fade" id="custom-tabs-three-rechazada" role="tabpanel" aria-labelledby="custom-tabs-three-rechazada-tab">                                             
                                                                          
                                        <div class="d-flex justify-content-center">
                                            
                                            {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
                                    
                                        </div>
                                        
                                        <div className="" id="tabla4">                                                                                      

                                            <div className="row">
                                      
                                                <div className="col-sm-3">
                                                      <div className="input-group input-group-sm mb-3 mr-sm-2">
                                                         <div className="input-group-prepend">
                                                           <div className="input-group-text"><Buscar/></div>
                                                         </div>
                                                         <input type="search" className="form-control" placeholder="Buscar" name="buscar_rechazadas" onChange={this.BuscarRechazada}/>
                                                      </div>                                
                                                </div>
                                                
                                            </div>
                             
                                                <table id="example1" className="table table-bordered table-striped table-hover">
                                                    <thead>
                                                      <tr>
                                                        
                                                        <th>ID</th>
                                                        <th>Fecha solicitud</th>
                                                        <th>Fecha cita</th>
                                                        <th>Paciente</th>   
                                                        <th>Médico</th> 
                                                        <th>Especialidad</th>                                                                
                                                        <th>Sede</th>                                                        
                                                        <th>Estado</th> 
                                                        <th>Tipo de cita</th>
                                                        <th>Obs. prestador</th> 
                                                        <th>Obs. paciente</th>     
                                                      </tr>
                                                    </thead>

                                                    <tbody>                                              
                                                   
                                                        {this.state.Citas_rechazadas.map(cita_rechaza=>{

                                                          var tipo_cita=cita_rechaza.contract.entityId

                                                          if(tipo_cita=='999'){

                                                            tipo_cita='Cita particular'
                                                          

                                                          }else{

                                                            tipo_cita='Cita por EPS'
                                                            
                                                          }

                                                         
                                                        return(
                                                
                                                        <tr>
                                                          <td>{cita_rechaza.id}</td> 
                                                          <td> 
                                                          <Moment format="YYYY/MM/DD HH:mm">
                                                          {cita_rechaza.created}
                                                          </Moment>
                                                          </td>
                                                          <td> 
                                                          <Moment format="YYYY/MM/DD HH:mm">
                                                          {cita_rechaza.fecha}
                                                          </Moment>
                                                          </td>         
                                                          <td>{cita_rechaza.patient.user.name1 ? (cita_rechaza.patient.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} { cita_rechaza.patient.user.name2 ? (cita_rechaza.patient.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} { cita_rechaza.patient.user.lastname1 ? (cita_rechaza.patient.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} { cita_rechaza.patient.user.lastname2 ? (cita_rechaza.patient.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''}</td>                                                                
                                                          <td>{cita_rechaza.professional.user.name1 ? (cita_rechaza.professional.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} { cita_rechaza.professional.user.name2 ? (cita_rechaza.professional.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''}  { cita_rechaza.professional.user.lastname1 ? (cita_rechaza.professional.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} { cita_rechaza.professional.user.lastname2 ? (cita_rechaza.professional.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} </td>               
                                                          <td>{(cita_rechaza.specialty.name).toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                                          <td>{(cita_rechaza.schedule.sede.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                                          <td>{(cita_rechaza.state).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                                          <td>{tipo_cita}</td>
                                                          <td>{cita_rechaza.anotation}</td>
                                                          <td>{(cita_rechaza.requestAnotation ? cita_rechaza.requestAnotation.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '')}</td>
                                                        </tr>
                                                        )
                                                       }
                                                       )}
                                              
                                                    </tbody>

                                                </table>

                                                <span>{this.state.pager_infocitas_rechazadas}</span>
                                                <nav className="nav">
                                                
                                                    <ul className="pagination">
              
                                                        {this.state.Pager_Citas_rechazadas.map(page_rechaza=>{
                                                        
                                                          var activo=page_rechaza.activo
                                                          if(activo==true){
                                                            activo='page-item active'
                                                          }else{
                                                            activo='page-item'
                                                          }
              
                                                         // alert(activo)
                                                          
                                                         return(                          
                                                                  
                                                                 <li className={activo}>
                                                                   <a className="nav-link" onClick={()=>{this.loadMoreDatarechazada(page_rechaza)}}>{page_rechaza.texto}</a>
                                                                 </li>                                            
                                                           )
                                                          }
                                                         )
                                                        }

                                                    </ul>
                                                          
                                                </nav>                                                
                                                        
                                         
                                        </div>

                                        <div className="d-flex justify-content-center">                              
                                           <img id="empty_page4" src={emptypage} alt="logo"  width= "200"/>                             
                                        </div>

                                        <div className="d-flex justify-content-center">                  
                                           <h5 id="text_page4"> <b>No hay información que mostrar</b> </h5>                          
                                        </div> 
                                        
                                       
                                  </div>

                                  <div className="tab-pane fade" id="custom-tabs-three-cancelada" role="tabpanel" aria-labelledby="custom-tabs-three-cancelada-tab">                                             
                                                                          
                                        <div class="d-flex justify-content-center">
                                            
                                            {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
                                    
                                        </div>
                                        
                                        <div className="" id="tabla5">                                                                                      

                                            <div className="row">
                                      
                                                <div className="col-sm-3">
                                                      <div className="input-group input-group-sm mb-3 mr-sm-2">
                                                         <div className="input-group-prepend">
                                                           <div className="input-group-text"><Buscar/></div>
                                                         </div>
                                                         <input type="search" className="form-control" placeholder="Buscar" name="buscar_cance" onChange={this.BuscarCancelada}/>
                                                      </div>                                
                                                </div>
                                                
                                            </div>
                             
                                                <table id="example1" className="table table-bordered table-striped table-hover">
                                                    <thead>
                                                      <tr>
                                                        <th>ID</th>                                                       
                                                        <th>Fecha de cita</th>
                                                        <th>Fecha de cancelación</th>
                                                        <th>Paciente</th>   
                                                        <th>Médico</th> 
                                                        <th>Especialidad</th> 
                                                        <th>Reponsable de cancelar</th>                                                          
                                                        <th>Sede</th>   
                                                        <th>Tipo de cita</th>
                                                        <th>Obs. prestador</th>  
                                                        <th>Obs. paciente</th> 
                                                                 
                                                      </tr>
                                                    </thead>

                                                    <tbody>                                              
                                                   
                                                        {this.state.Citas_canceladas.map(cita_cance=>{

                                                        var tipo_cita=cita_cance.contract.entityId

                                                          if(tipo_cita=='999'){

                                                            tipo_cita='Cita particular'
                                                          

                                                          }else{

                                                            tipo_cita='Cita por EPS'
                                                            
                                                          }
                                                                                                                
                                                        return(
                                                
                                                        <tr>
                                                          <td>{cita_cance.id}</td> 
                                                          <td> 
                                                          <Moment format="YYYY/MM/DD HH:mm">
                                                          {cita_cance.fecha}
                                                          </Moment>
                                                          </td>
                                                          <td> 
                                                          <Moment format="YYYY/MM/DD HH:mm">
                                                          {cita_cance.cancelationDate}
                                                          </Moment>
                                                          </td>         
                                                          <td>{cita_cance.patient.user.name1 ? (cita_cance.patient.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} { cita_cance.patient.user.name2 ? (cita_cance.patient.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} { cita_cance.patient.user.lastname1 ? (cita_cance.patient.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} { cita_cance.patient.user.lastname2 ? (cita_cance.patient.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''}</td>                                                                
                                                          <td>{cita_cance.professional.user.name1 ? (cita_cance.professional.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} { cita_cance.professional.user.name2 ? (cita_cance.professional.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''}  { cita_cance.professional.user.lastname1 ? (cita_cance.professional.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} { cita_cance.professional.user.lastname2 ? (cita_cance.professional.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} </td>               
                                                          <td>{(cita_cance.specialty.name).toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                                          <td>{(cita_cance.cancelationRol).toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                                          <td>{(cita_cance.schedule.sede.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                                          <td>{tipo_cita}</td>                                                          
                                                          <td>{cita_cance.anotation}</td>
                                                          <td>{(cita_cance.requestAnotation ? cita_cance.requestAnotation.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '')}</td>
                                                        </tr>
                                                        )
                                                       }
                                                       )}
                                              
                                                    </tbody>

                                                </table>

                                                <span>{this.state.pager_infocitas_canceladas}</span>
                                                <nav className="nav">
                                                
                                                    <ul className="pagination">
              
                                                        {this.state.Pager_Citas_canceladas.map(page_cance=>{
                                                        
                                                          var activo=page_cance.activo
                                                          if(activo==true){
                                                            activo='page-item active'
                                                          }else{
                                                            activo='page-item'
                                                          }
              
                                                         // alert(activo)
                                                          
                                                         return(                          
                                                                  
                                                                 <li className={activo}>
                                                                   <a className="nav-link" onClick={()=>{this.loadMoreDatarechazada(page_cance)}}>{page_cance.texto}</a>
                                                                 </li>                                            
                                                           )
                                                          }
                                                         )
                                                        }

                                                    </ul>
                                                          
                                                </nav>                                                
                                                        
                                         
                                        </div>

                                        <div className="d-flex justify-content-center">                              
                                           <img id="empty_page5" src={emptypage} alt="logo"  width= "200"/>                             
                                        </div>

                                        <div className="d-flex justify-content-center">                  
                                           <h5 id="text_page5"> <b>No hay información que mostrar</b> </h5>                          
                                        </div> 
                                        
                                       
                                  </div>

                                  <div className="tab-pane fade" id="custom-tabs-three-profile" role="tabpanel" aria-labelledby="custom-tabs-three-profile-tab">
                                        
                                        <h6 id="title"> <b>DATOS GENERALES</b> </h6>                                        

                                        <div className="row"> 

                                              <div className="col-md-4">

                                                   <div className="form-group">                    
                                                        <label >Especialidad:</label>
                                                        <select onChange={this.GetEspecialidad}  className="form-control" id="espe" name="espe" disabled={!this.state.active}>
                                                            <option  value="0"> Seleccione...</option> ,
                                                            {this.state.Especialidades.map(item=>                              
                                                            <option key={item.id} value={item.id}> {(item.name).toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                                            )}
                                                                    
                                                        </select>                      
                                                   </div>

                                              </div>  

                                              <div className="col-md-4"> 

                                                  <div className="form-group">                    
                                                            <label >Sede:</label>
                                                            <select onChange={this.GetSede}  className="form-control" id="sede" name="sede" disabled={!this.state.active}>
                                                                <option  value="0"> Seleccione...</option> ,
                                                                {this.state.Sedes.map(sede=>                              
                                                                <option key={sede.id} value={sede.id}> {(sede.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                                                )}                                                                      
                                                            </select>                   
                                                  </div>

                                              </div>                                             

                                              <div className="col-md-4">

                                                  <div className="form-group">                    
                                                        <label >Profesional:</label>
                                                        <select onChange={this.GetMedico} onClick={this.GetMedicoclcik}  className="form-control" id="profesionales" name="profesionales" disabled={!this.state.active} >
                    
                                                            <option  value="0"> Seleccione...</option> ,
                                                            {this.state.profesionales.map(item=>                              
                                                            <option key={item.id} value={item.id}>  {item.user.name1 ? (item.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' }  {item.user.name2 ? (item.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} { item.user.lastname1 ? (item.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' } { item.user.lastname2 ? (item.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' }</option>  
                                                            )}
                                                                    
                                                        </select>                      
                                                  </div>

                                              </div>                                                                                                                                                                                                                 
                                                                                   
                                        
                                        </div>

                                        <h6 id="title"> <b>DATOS DE LA CITA</b> </h6> 
                        
                                        <div className="row">

                                              <div className="col-md-4">
          
                                                    <div className="form-group">                    
                                                      <label >Tipo de cita:</label>
                                                      <select onChange={this.GetTipocita}  className="form-control" id="tipo_cita" name="tipo_cita" disabled={!this.state.active}>
                                                        <option  value="Telemedicina">Seleccione...</option>
                                                        <option  value="telemedicina">Telemedicina</option>
                                                        <option  value="presencial">Presencial</option>                           
                                                      
                                                      </select>                      
                                                    </div> 
                    
                                              </div>

                                              <div className="col-md-4">                                 
                                                  <div className="form-group">                    
                                                    <label >Tipo de servicio:</label>                                                    
                                                        <select onChange={this.GetTiposervi} onClick={this.GetTiposerviclcik} className="form-control" id="tipo_servicio" name="tipo_servicio" disabled={!this.state.active}>
                                                          <option  value="0"> Seleccione...</option> ,
                                                          {this.state.Servicios.map(servicio=>                              
                                                          <option key={servicio.id} value={servicio.id}> {(servicio.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                                          )}                                                                      
                                                          </select>                   
                                                  </div>
                                              </div>

                                              <div className="col-md-2">
                                                   <div className="form-group">                    
                                                        <label >Dias agenda: {this.state.loading_dias_agenda?false:<ReactBootStrap.Spinner animation="border" size="sm" />}</label>
                                                        <select onChange={this.GetDia_agenda}  className="form-control" id="dia_agenda" name="dia_agenda" disabled={!this.state.active} >
                                                            <option  value="0"> Seleccione...</option> ,
                                                            
                                                            {this.state.Dias_agenda.map(item=>                              
                                                            <option key={item.date} value={item.date}> {(item.fecha).toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                                            )}
                                                                    
                                                        </select>                      
                                                   </div>
                                              </div>                                              

                                              <div className="col-md-2">
                                                   <div className="form-group">                    
                                                        <label >Horas citas:</label>
                                                        <select onChange={this.GetFechacita}  className="form-control" id="fecha_solicita" name="fecha_solicita" disabled={!this.state.active}>
                                                            <option  value="0"> Seleccione...</option> ,
                                                            {this.state.Hora_citas.map(item=>                              
                                                            <option key={item.date} value={item.date}> {(item.fecha).toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                                            )}
                                                                    
                                                        </select>                      
                                                   </div>
                                              </div>                                                                                                         
                        
                                        </div>

                                        <h6 id="title"> <b>DATOS DEL PACIENTE</b> </h6>                        
                        
                                        <div className="row">

                                              <div className="col-md-4">
                                                  <div className="form-group">                    
                                                          <label >Tipo ID:</label>                                                          

                                                          <select onChange={this.GettipoID}  className="form-control" id="id_tipoid" name="id_tipoid" disabled={!this.state.active} >
                    
                                                              <option  value="0"> Seleccione...</option> ,
                                                              {this.state.Tipo_id.map(item=>                              
                                                              <option key={item.code} value={item.code}>  {item.description ? (item.description).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' }  </option>  
                                                              )}
                                                                    
                                                          </select>                                                 
                                                  </div>                              
                                              </div>

                                              <div className="col-md-4">            
                                                  <div className="form-group">                    
                                                          <label >Identificación:</label>
                                                          <input type="text" maxLength="10" className="form-control camponum" id="id_pacie" data-toggle="tooltip" data-placement="top" title="Presion la tecla Enter o Tab para consultar" placeholder="Identificación" name="id_pacie" onChange={this.handleChange} onKeyDown={this._handleKeyDown} disabled={!this.state.active}/>                                                          
                                                  </div>                        
                              
                                              </div>

                                              <div className="col-md-4">
                                                   <div className="form-group">                    
                                                        <label >Nombre:</label>
                                                        <input type="text" className="form-control" id="nom_pacien"  placeholder="Nombre" name="nom_pacien" value={form.nom_pacien} readOnly/>                       
                                                   </div>
                                              </div>                                                                                       
                                             
                        
                                        </div>                            
                                    
                            
                                        <div className="row">  

                                              <div className="col-md-4">                                                                          
                                                    <div className="form-group">                    
                                                        <label >Contrato:</label> 
                                                        <select onChange={this.Getcontrato} className="form-control" id="id_contrato_eps" name="id_contrato_eps" disabled={!this.state.active}>
                                                            <option  value="null"> Seleccione...</option> ,
                                                            {this.state.Contrato.map(item=>                              
                                                            <option key={item.id} value={item.id}> {item.name.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                                            )}
                                                                    
                                                        </select>  
                                                        <select onChange={this.Getcontrato} className="form-control" id="id_contrato_particular" name="id_contrato_particular" disabled={!this.state.active}>
                                                            <option  value="null"> Seleccione...</option> ,
                                                            {this.state.Contrato_particular.map(item=>                              
                                                            <option key={item.id} value={item.id}> {item.name.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                                            )}
                                                                    
                                                        </select>                                                                        
                                                        
                                                    </div>                                        
                                              </div>

                                              <div className="col-md-4">
                                                   <div className="form-group">                    
                                                        <label >Regimen:</label>
                                                        <select onChange={this.Getregimen} className="form-control" id="regimen" name="regimen" disabled={!this.state.active}>
                                                            <option  value="null"> Seleccione...</option> ,
                                                            {this.state.Regimen.map(item=>                              
                                                            <option key={item.id} value={item.id}> {item.nombre.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                                            )}
                                                                    
                                                        </select>                     
                                                    </div>
                                              </div>

                                              <div className="col-md-4">
                                                    <div className="form-group" id="cb_afiliaaddcita">                    
                                                       <label >Tipo afiliación:</label>
                                                       <select onChange={this.Getafiliacion} className="form-control" id="tipo_afilia" name="tipo_afilia" disabled={!this.state.active}>
                                                            <option  value="null"> Seleccione...</option> ,
                                                            {this.state.Afiliacion.map(item=>                              
                                                            <option key={item.id} value={item.id}> {item.name}</option>  
                                                            )}
                                                                    
                                                        </select>
                                                    </div>

                                                    <div className="form-group" id="afilia_beneficiarioaddcita" >                    
                                                          <label >Tipo afiliación:</label>
                                                          <select className="form-control" disabled={!this.state.active}>
                                                             <option  value="2">Beneficiario</option>                                                           
                                                          </select>
                                                          
                                                    </div>
                                              
                                              </div>                         

                                             
                                        </div> 

                                        <div className="row">                              
                                              

                                              <div className="col-md-4">
                                      
                                                    <div className="form-group">                    
                                                        <label >Nivel:</label>
                                                        <select onChange={this.GetNivel}  className="form-control" id="nivel" name="nivel" disabled={!this.state.active}>
                                                           <option  value="0">Seleccione...</option>
                                                           <option  value="1">1</option> 
                                                           <option  value="2">2</option>
                                                           <option  value="3">3</option>                    
                                                        </select>                                                        
                                                    </div>
                                              
                                              </div> 

                                              <div className="col-md-4">
                                                   <div className="form-group">                    
                                                        <label >Tipo de consulta:</label>
                                                        <select onChange={this.Gettipoconsulta}  className="form-control" id="tipo_consulta" name="tipo_consulta" disabled={!this.state.active}>
                                                            <option  value="0"> Seleccione...</option> ,
                                                            {this.state.Tipo_consulta.map(item=>                              
                                                            <option key={item.id} value={item.id}> {(item.name).toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                                            )}
                                                                    
                                                        </select>                      
                                                   </div>
                                              </div>

                                              <div className="col-md-4">
                                                   
                                                    <div className="form-group">                    
                                                            <label >Fecha deseada de cita:</label>
                                                            <input type="date" className="form-control" id="fecha_deseada_cita" name="fecha_deseada_cita" onChange={this.handleChange} disabled={!this.state.active}/>               
                                                    </div>
                                              </div>                                             
                                            
                                        </div> 


                                        <div className="modal-footer"> 

                                               <button id="btn_save"  className="btn btn-save"   disabled={!this.state.active_button} onClick={()=> this.savecitas()} > {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />}Guardar
                                                  <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" class="bi bi-check2-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                     <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                                     <path fill-rule="evenodd" d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z"/>
                                                  </svg>
                                                </button>
                                                <button  className="btn btn-primary" onClick={()=> this.handlerSelectdrop()}> Nuevo
                                                   <svg width="1.2em" height="1.2em" viewBox="3 3 10 10" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                   </svg>
                                                </button>
                                              
                                        </div>       
                                  


                                       
                                  </div>                  
                             
                            </div>

                      </div>
                 
                </div>
          
          </div>


          <div className="modal fade" id="modal-addcita">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title"><b>Asignar cita</b></h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">                   
      
                        
          
          
                </div>

                <div className="modal-footer justify-content-between">
                  <button type="button" className="btn btn-primary" disabled={!this.state.active_button} onClick={()=> this.savecitas()} > {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />}Guardar</button>
                  <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>        
                </div>
              </div>
              
            </div>
            
          </div> 


          <div className="modal fade" id="modal-respuestacita" data-backdrop="static">
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title"><b>Respuesta de solicitud de cita por EPS</b></h4>
                  <button type="button" className="close" data-dismiss="modal" onClick={()=> this.handleModalrespuestacitadrop()} aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  
                      <div className="row">
                                      
                          <div className="col-md-1">
                              <div className="form-group">                    
                                      <label >ID cita:</label>
                                      <input type="text" className="form-control" id="id_cita" name="id_cita" value={form.id_cita} readOnly />                                                  
                              </div>
          
                          </div>

                          <div className="col-md-3">
          
                              <div className="form-group">                    
                                      <label >Fecha de cita:</label>
                                      <input type="text" maxLength="20" className="form-control" id="fecha_solicita" name="fecha_solicita" value={form.fecha_solicita} readOnly />                       
                              </div>
          
                          </div>

                          <div className="col-md-3">
          
                              <div className="form-group">                    
                                      <label >Identificación:</label>
                                      <input type="text" maxLength="20" className="form-control" id="id_pacien_soli" name="id_pacie_soli" value={form.id_pacie_soli} readOnly />                       
                              </div>
          
                          </div>

                          <div className="col-md-5">
          
                              <div className="form-group">                    
                                   <label >Nombre:</label>
                                   <input type="text" className="form-control" id="dir_sede"   name="nom_pcte_soli" value={form.nom1_pacien +' '+ form.nom2_pacien +' '+ form.apell1_pacien +' '+ form.apell2_pacien} readOnly/>
                                   <a id="btn-registrarse" href="#" onClick={()=> this.modal_informacionpcte()} > Más información del paciente </a>                       
                              </div>
          
                          </div>
                      
                      </div>


                      <div className="row">

                          <div className="col-md-6">

                              <div className="form-group">                    
                                   <label >Médico:</label>
                                   <input type="text" className="form-control" id="dir_sede"   name="medico_soli" value={form.nom1medico_soli +' '+ form.nom2medico_soli +' '+ form.apell1medico_soli +' '+ form.apell2medico_soli } readOnly/>                       
                              </div>

                            </div>

                            <div className="col-md-6">

                              <div className="form-group">                    
                                   <label >Especialidad:</label>
                                   <input type="text" className="form-control" id="dir_sede"   name="espe_soli" value={form.espe_soli} readOnly/>                       
                              </div>

                            </div>                            
          
                      </div>


                      <div className="row" > 

                          <div className="col-md-4">
        
                              <div className="form-group">                    
                                <label >Respuesta:</label>
                                <select onChange={this.GetAcepta}  className="form-control" id="acepta" name="acepta">
                                  <option  value="0">Seleccione...</option>
                                  <option  value="true">Aceptar</option>
                                  <option  value="false">Rechazar</option>                           
                                
                                </select>                      
                              </div> 
                          </div>                                                  
        
                          <div className="col-md-4" id="seccion_regimen">
                               <div className="form-group">                    
                                    <label >Regimen:</label>
                                    {this.state.regime}                   
                                </div>
                          </div>

                          <div className="col-md-4" id="seccion_tipoafiliacion">
                                  <div className="form-group" id="cb_afilia">                    
                                     <label >Tipo afiliación:</label>
                                     {this.state.tipo_afilia}
                                  </div>
                                  <div className="form-group" id="afilia_beneficiario">                    
                                      <label >Tipo afiliación:</label>
                                      <select className="form-control">
                                         <option  value="2">Beneficiario</option>                                                           
                                      </select>
                                      
                                  </div>                            
                          </div> 

                                                  
      
                      </div>


                      <div className="row" id="seccion_nivel-contrato">  

                          <div className="col-md-4">
                    
                                  <div className="form-group">                    
                                      <label >Nivel:</label>
                                      <select onChange={this.GetNivel}  className="form-control" id="nivel" name="nivel" >
                                         <option  value="null">Seleccione...</option>
                                         <option  value="1">1</option> 
                                         <option  value="2">2</option>
                                         <option  value="3">3</option>                    
                                      </select>
                                      
                                  </div>
                            
                          </div>                         

                          <div className="col-md-4">

                                <div className="form-group">                    
                                    <label >Contrato:</label>                                                                          
                                    <select onChange={this.Getcontrato} className="form-control" id="id_contrato" name="id_contrato" disabled={!this.state.active}>
                                        <option  value="null"> Seleccione...</option> ,
                                        {this.state.Contrato.map(item=>                              
                                        <option key={item.id} value={item.id}> {item.name.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                        )}
                                                
                                    </select>                              
                                </div>
                    
                          </div>  

                          <div className="col-md-4">
          
                                <div className="form-group">                    
                                  <label >Tipo de cita:</label>
                                  <select onChange={this.GetTipocita}  className="form-control" id="tipo_cita" name="acepta">
                                    <option  value="telemedicina">Seleccione...</option>
                                    <option  value="telemedicina">Telemedicina</option>
                                    <option  value="presencial">Presencial</option>                           
                                  
                                  </select>                      
                                </div> 

                          </div>                                              
                        

                      </div>


                      <div className="row" id="requiere_cita">                   
                                                  
                          <label >Solicitar documentos o soportes:</label>        

                          <div className="col-md-1"> <br/>
                              <div className="form-group form-check">  
                                      <label >Sí</label>                                                             
                                      <input onClick={this.GetAutoriza} type="radio" className="form-control" name="Rautorizacion" value="true"/>
                                                                            
                              </div>
          
                          </div>

                          <div className="col-md-1"> <br/>
                              <div className="form-group form-check">  
                                      <label >No</label>                                                             
                                      <input onClick={this.GetAutoriza} type="radio" className="form-control" name="Rautorizacion" value="false"/>
                                                                            
                              </div>
          
                          </div>               
                      

                           <label >Cuota moderadora:</label> 

                          <div></div>          

                          <div className="col-md-1" id="cheksi_cuotamoderadora"><br/>
                              <div className="form-group form-check">  
                                      <label >Sí:</label>                                                             
                                      <input onClick={this.GetRcuota} type="radio" className="form-control" id="Rcuota_modsi" name="Rcuota_mod" value="true"/>
                                                                            
                              </div>          
                          </div>

                          <div className="col-md-1" id="chekno_cuotamoderadora"><br/>
                              <div className="form-group form-check">  
                                      <label >No:</label>                                                             
                                      <input onClick={this.GetRcuota} type="radio" className="form-control" id="Rcuota_modno" name="Rcuota_mod" value="false"/>                                                                            
                              </div>          
                          </div>

                          <div className="col-md-2"> <br/>
                              <div className="form-group"> 
                                     <label >Valor:</label>                                    
                                     <input type="text" className="form-control"  name="cuota_mod" value={form.cuota_mod} readOnly />                                                  
                              </div>          
                          </div>

                      </div> 

                      <div className="row" >

                          

                      </div>

                      <div className="row" id="seccion_observacion">  

                          <div className="col-md-6" id="select_tipo_consulta" >
                               <div className="form-group">                    
                                    <label >Tipo de consulta:</label>
                                    <select onChange={this.Gettipoconsulta}  className="form-control" id="tipo_consulta_autori" name="tipo_consulta_autori" disabled={!this.state.active}>
                                        <option  value="0"> Seleccione...</option> ,
                                        {this.state.Tipo_consulta.map(item=>                              
                                        <option key={item.id} value={item.id}> {(item.name).toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                        )}
                                                
                                    </select>                      
                               </div>
                          </div>                                
                                    
                          <div className="col-md-6">

                              <div className="form-group">                    
                                      <label >Mensaje para el paciente:</label>                           
                                      <textarea class="form-control" maxLength="300"  rows="2" id="obser" name="obser" value={form.obser} onChange={this.handleChange}  ></textarea>
                                      
                              </div>

                          </div>

                      </div>         
                          
                </div> 

                <div className="modal-footer justify-content-between"> 

                     {/*  <button type="button" className="btn btn-primary" id="btn_confirmar" disabled={!this.state.button_rta_cita} onClick={()=> this.respuesta_cita()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Guardar</button>    */} 
                                    
                      <button type="button" className="btn btn-primary" id="btn_aceptar" disabled={!this.state.button_rta_cita} onClick={()=> this.respuesta_cita()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Guardar</button>  

                      <button type="button" className="btn btn-primary" disabled={!this.state.active_btnhis} onClick={()=> this.Historial_Citas()}> {this.state.loading_btnhis?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Historial de citas 
                          <svg xmlns="http://www.w3.org/2000/svg" width="1.4em" height="1.4em" fill="currentColor" class="bi bi-card-list" viewBox="-1 0 17 17">
                            <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                            <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                          </svg>
                      </button>      
                
                      <button type="button" className="btn btn-danger"  onClick={()=> this.handleModalrespuestacitadrop()} data-dismiss="modal">Cerrar</button>         
                         
                </div>

              </div>
             
            </div>
            
          </div>

          <div className="modal fade" id="modal-respuestacita-particular" data-backdrop="static">
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title"><b>Respuesta de solicitud de cita particular</b></h4>
                  <button type="button" className="close" data-dismiss="modal" onClick={()=> this.handleModalrespuestacitadrop()} aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  
                      <div className="row">
                                      
                          <div className="col-md-1">
                              <div className="form-group">                    
                                      <label >ID cita:</label>
                                      <input type="text" className="form-control" id="id_cita" name="id_cita" value={form.id_cita} readOnly />                                                  
                              </div>
          
                          </div>

                          <div className="col-md-3">
          
                              <div className="form-group">                    
                                      <label >Fecha de cita:</label>
                                      <input type="text" maxLength="20" className="form-control" id="fecha_solicita" name="fecha_solicita" value={form.fecha_solicita} readOnly />                       
                              </div>
          
                          </div>

                          <div className="col-md-3">
          
                              <div className="form-group">                    
                                      <label >Identificación:</label>
                                      <input type="text" maxLength="20" className="form-control" id="id_pacien_soli" name="id_pacie_soli" value={form.id_pacie_soli} readOnly />                       
                              </div>
          
                          </div>

                          <div className="col-md-5">
          
                              <div className="form-group">                    
                                   <label >Nombre:</label>
                                   <input type="text" className="form-control" id="dir_sede"   name="nom_pcte_soli" value={form.nom1_pacien +' '+ form.nom2_pacien +' '+ form.apell1_pacien +' '+ form.apell2_pacien} readOnly/>
                                   <a id="btn-registrarse" href="#" onClick={()=> this.modal_informacionpcte()} > Más información del paciente </a>                       
                              </div>
          
                          </div>
                      
                      </div>


                      <div className="row">

                          <div className="col-md-6">

                              <div className="form-group">                    
                                   <label >Médico:</label>
                                   <input type="text" className="form-control" id="dir_sede"   name="medico_soli" value={form.nom1medico_soli +' '+ form.nom2medico_soli +' '+ form.apell1medico_soli +' '+ form.apell2medico_soli } readOnly/>                       
                              </div>

                            </div>

                            <div className="col-md-6">

                              <div className="form-group">                    
                                   <label >Especialidad:</label>
                                   <input type="text" className="form-control" id="dir_sede"   name="espe_soli" value={form.espe_soli} readOnly/>                       
                              </div>

                            </div>                            
          
                      </div>


                      <div className="row" > 

                          <div className="col-md-4">
        
                              <div className="form-group">                    
                                <label >Respuesta:</label>
                                <select onChange={this.GetAcepta}  className="form-control" id="acepta_p" name="acepta">
                                  <option  value="0">Seleccione...</option>
                                  <option  value="true">Aceptar</option>
                                  <option  value="false">Rechazar</option>                           
                                
                                </select>                      
                              </div> 
                          </div> 

                          <div className="col-md-4" id="seccion_contrato_p">

                                <div className="form-group">                    
                                    <label >Contrato:</label>                                                                          
                                    <select onChange={this.Getcontrato} className="form-control" id="id_contrato" name="id_contrato" disabled={!this.state.active}>
                                        
                                        {this.state.Contrato_particular.map(item=>                              
                                        <option key={item.id} value={item.id}> {item.name.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                        )}
                                                
                                    </select>                              
                                </div>
                    
                          </div>   

                          <div className="col-md-4" id="seccion_tipocita_p">
          
                                <div className="form-group">                    
                                  <label >Tipo de cita:</label>
                                  <select onChange={this.GetTipocita}  className="form-control" id="tipo_cita_p" name="tipo_cita_p">
                                    <option  value="telemedicina">Seleccione...</option>
                                    <option  value="telemedicina">Telemedicina</option>
                                    <option  value="presencial">Presencial</option>                           
                                  
                                  </select>                      
                                </div> 

                          </div>                                              
        
                                                  
      
                      </div>

                      <div className="row" id="requiere_cita_p">                   
                                                  
                          <label >Solicitar documentos o soportes:</label>        

                          <div className="col-md-1"> <br/>
                              <div className="form-group form-check">  
                                      <label >Sí</label>                                                             
                                      <input onClick={this.GetAutoriza} type="radio" className="form-control" name="Rautorizacion" value="true"/>
                                                                            
                              </div>
          
                          </div>

                          <div className="col-md-1"> <br/>
                              <div className="form-group form-check">  
                                      <label >No</label>                                                             
                                      <input onClick={this.GetAutoriza} type="radio" className="form-control" name="Rautorizacion" value="false"/>
                                                                            
                              </div>
          
                          </div>               
                      

                           <label >Valor consulta:</label> 

                          <div></div>          

                          <div className="col-md-1" id="cheksi_cuotamoderadora"><br/>
                              <div className="form-group form-check">  
                                      <label >Sí:</label>                                                             
                                      <input onClick={this.GetRcuota} type="radio" className="form-control" id="Rcuota_modsi" name="Rcuota_mod" value="true"/>
                                                                            
                              </div>          
                          </div>

                          <div className="col-md-1" id="chekno_cuotamoderadora"><br/>
                              <div className="form-group form-check">  
                                      <label >No:</label>                                                             
                                      <input onClick={this.GetRcuota} type="radio" className="form-control" id="Rcuota_modno" name="Rcuota_mod" value="false"/>                                                                            
                              </div>          
                          </div>

                          <div className="col-md-2"> <br/>
                              <div className="form-group"> 
                                     <label >Valor:</label>                                    
                                     <input type="text" className="form-control"  name="cuota_mod" value={form.pago_particular} readOnly />                                                  
                              </div>          
                          </div>

                      </div> 

                      <div className="row" >

                          

                      </div>

                      <div className="row" id="seccion_observacion_p"> 

                                                         
                                    
                          <div className="col-md-6">

                              <div className="form-group">                    
                                      <label >Mensaje para el paciente:</label>                           
                                      <textarea class="form-control" maxLength="300"  rows="2" id="obser" name="obser" value={form.obser} onChange={this.handleChange}  ></textarea>
                                      
                              </div>

                          </div>

                      </div>         
                          
                </div> 

                <div className="modal-footer justify-content-between"> 

                     {/*  <button type="button" className="btn btn-primary" id="btn_confirmar" disabled={!this.state.button_rta_cita} onClick={()=> this.respuesta_cita()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Guardar</button>    */} 
                                    
                      <button type="button" className="btn btn-primary" id="btn_aceptar" disabled={!this.state.button_rta_cita} onClick={()=> this.respuesta_cita()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Guardar</button>  

                      <button type="button" className="btn btn-primary" disabled={!this.state.active_btnhis} onClick={()=> this.Historial_Citas()}> {this.state.loading_btnhis?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Historial de citas 
                          <svg xmlns="http://www.w3.org/2000/svg" width="1.4em" height="1.4em" fill="currentColor" class="bi bi-card-list" viewBox="-1 0 17 17">
                            <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                            <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                          </svg>
                      </button>      
                
                      <button type="button" className="btn btn-danger"  onClick={()=> this.handleModalrespuestacitadrop()} data-dismiss="modal">Cerrar</button>         
                         
                </div>

              </div>
             
            </div>
            
          </div>


          <div className="modal fade" id="modal-respuesta_autorizacion" data-backdrop="static">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title"><b>Autorización de cita</b></h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=> this.handleModalrespuesta_autorizaciondrop()}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  
                      <div className="row">
                                      
                          <div className="col-md-1">
                              <div className="form-group">                    
                                      <label >ID:</label>
                                      <input type="text" className="form-control" id="id_cita" name="id_cita" value={form.id_cita} readOnly />                                                  
                              </div>          
                          </div>

                          <div className="col-md-3">
          
                              <div className="form-group">                    
                                      <label >Fecha cita:</label>
                                      <input type="text" maxLength="20" className="form-control" id="fecha_solicita" name="fecha_solicita" value={form.fecha_solicita} readOnly />                       
                              </div>
          
                          </div>

                          <div className="col-md-3">
          
                              <div className="form-group">                    
                                      <label >Identificación:</label>
                                      <input type="text" maxLength="20" className="form-control" id="id_pacien_soli" name="id_pacie_soli" value={form.id_pacie_soli} readOnly />                       
                              </div>
          
                          </div>

                          <div className="col-md-5">
          
                              <div className="form-group">                    
                                   <label >Nombre:</label>
                                   <input type="text" className="form-control" id="dir_sede"   name="nom_pcte_soli" value={form.nom1_pacien +' '+ form.nom2_pacien +' '+ form.apell1_pacien +' '+ form.apell2_pacien} readOnly/>                       
                              </div>
          
                          </div>
                      
                      </div>


                      <div className="row">

                          <div className="col-md-6">

                              <div className="form-group">                    
                                   <label >Médico:</label>
                                   <input type="text" className="form-control" id="dir_sede"   name="medico_soli" value={form.nom1medico_soli +' '+ form.nom2medico_soli +' '+ form.apell1medico_soli +' '+ form.apell2medico_soli } readOnly/>                       
                              </div>

                            </div>

                            <div className="col-md-6">

                              <div className="form-group">                    
                                   <label >Especialidad:</label>
                                   <input type="text" className="form-control" id="dir_sede"   name="espe_soli" value={form.espe_soli} readOnly/>                       
                              </div>

                            </div>
          
                      </div>  

                      <div className="row">
                                  
                                    <div className="col-md-12">
                                       
                                        <div className="card table-responsive">
                                 
                                           {/* /.card-header */}
                                           <div className="card-body">
                                              <table id="tabla_autorizacion" className="table table-bordered table-striped table-hover">
                                                <thead>
                                                  <tr>
                                                    <th>ID</th>
                                                    <th>Fecha envío</th> 
                                                    <th>Aprobada</th>
                                                    <th>Ver</th>
                                                    <th>Aceptar</th>    
                                                    <th>Rechazar</th>                                                 
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                   {this.state.Autorizacion.map(autoriza=>{
                                                     var autorizada=autoriza.approved
                                                     var Archivos=[]
                                                     Archivos=autoriza.authorizationFiles
                                                     var td_si=''
                                                     var td_no
                                                     //alert(autorizada)
                                                     if(autorizada==null){
                                                       autorizada='Sin respuesta'
                                                       td_si=<div className="form-group form-check">
                                                               <input onClick={this.GetAceptaautori} type="radio" className="checkbox" id="exampleCheck1" name="Checkacepta_autori" value="true" />
                                                               <label id="check_terminos" className="form-check-label" htmlFor="exampleCheck1"> </label>
                                                             </div>

                                                       td_no=<div className="form-group form-check">
                                                               <input onClick={this.GetAceptaautori} type="radio" className="checkbox" id="exampleCheck1" name="Checkacepta_autori" value="false" />
                                                               <label id="check_terminos" className="form-check-label" htmlFor="exampleCheck1"> </label>
                                                             </div>
                                                       
                                                     }else{
                                                       if(autorizada==true){
                                                          autorizada='Si'
                                                       }else{
                                                        autorizada='No'
                                                       }
                                                       
                                                     }
                                                     return(      
                                                     
                                                     <tr>          
                                                       <td>{autoriza.id}</td>                                             
                                                       <td>
                                                       <Moment format="YYYY/MM/DD HH:mm">
                                                        {autoriza.date}
                                                       </Moment>
                                                       </td>
                                                       <td>{autorizada}</td>                                               
                                                       <td> {Archivos.map(fil=>{ return(<a href={fil.file} target="_blank"> 
                                                              <button className="btn btn-edit">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-file-earmark-image" viewBox="0 0 16 16">
                                                                  <path d="M6.502 7a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                                                                  <path d="M14 14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5V14zM4 1a1 1 0 0 0-1 1v10l2.224-2.224a.5.5 0 0 1 .61-.075L8 11l2.157-3.02a.5.5 0 0 1 .76-.063L13 10V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4z"/>
                                                                </svg>
                                                              </button> </a>)})}
                                                       </td>
                                                       <td onClick={()=>{this.Get_Idautorizacion(autoriza)}}> 
                                                       {td_si}
                                                        </td>
                                                        <td onClick={()=>{this.Get_Idautorizacion(autoriza)}}> 
                                                        {td_no}
                                                        </td>
                                                     </tr>
                                             
                                                        )
                                                       }
                                                     )
                                                   }                
                                                </tbody>
                                              </table>
                                           </div>
                                           
                                        </div>            
                 
                                    </div>

                        </div>                   


                      <div className="row">

                          <div className="col-md-6" id="select_tipo_consultaautori" >
                               <div className="form-group">                    
                                    <label >Tipo de consulta:</label>
                                    <select onChange={this.Gettipoconsulta}  className="form-control" id="tipo_consulta_autorirpta" name="tipo_consulta_autorirpta" disabled={!this.state.active}>
                                        <option  value="0"> Seleccione...</option> ,
                                        {this.state.Tipo_consulta.map(item=>                              
                                        <option key={item.id} value={item.id}> {(item.name).toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                        )}
                                                
                                    </select>                      
                               </div>
                          </div>

                          <div className="col-md-6">

                              <div className="form-group">                    
                                      <label >Mensaje para el paciente:</label>                           
                                      <textarea class="form-control" maxLength="300" rows="2" name="obser_autorizacion" value={form.obser_autorizacion} onChange={this.handleChange} ></textarea>                                     
                              </div>

                          </div>
                       
                      </div>                    
          
                          
                </div>                
                <div className="modal-footer justify-content-between">      
                                    
                      <button type="button" className="btn btn-primary" disabled={!this.state.button_rta_cita} onClick={()=> this.respuesta_acepta_autori()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Guardar</button>           
                
                      <button type="button" className="btn btn-primary" disabled={!this.state.active_btnhis} onClick={()=> this.Historial_Citas()}> {this.state.loading_btnhis?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Historial de citas 
                          <svg xmlns="http://www.w3.org/2000/svg" width="1.4em" height="1.4em" fill="currentColor" class="bi bi-card-list" viewBox="-1 0 17 17">
                            <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                            <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                          </svg>
                      </button>
                      
                      <button type="button" className="btn btn-danger" onClick={()=> this.handleModalrespuesta_autorizaciondrop()} data-dismiss="modal">Cerrar</button>         
                         
                </div>
              </div>
             
            </div>
            
          </div>

          <div className="modal fade" id="modal-infopcte" data-backdrop="static">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title"><b>Más información del paciente</b></h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  
                      <div className="row">
                                      
                          <div className="col-md-3">
                              <div className="form-group">                    
                                      <label >Fecha de nacimiento:</label>
                                      <input type="text" className="form-control" id="fecha_naci" name="fecha_naci" value={form.fecha_naci} readOnly/>                                                                         
                              </div>
          
                          </div>

                          <div className="col-md-2">
                              <div className="form-group">                    
                                      <label >Edad:</label>
                                      <input type="text" className="form-control" id="edad_pcte" name="edad_pcte" value={form.edad_pcte} readOnly/>                                                                         
                              </div>
          
                          </div>

                          <div className="col-md-1">
          
                              <div className="form-group">                    
                                      <label >Género:</label>
                                      <input type="text"  className="form-control" id="genero_pcte" name="genero_pcte" value={form.genero_pcte} readOnly/>                       
                              </div>
          
                          </div>

                          <div className="col-md-3">
                              <div className="form-group">                    
                                      <label >Teléfono:</label>
                                      <input type="text" className="form-control" id="tel_pcte" name="tel_pcte" value={form.tel_pcte} readOnly/>                                                                         
                              </div>
          
                          </div>

                          <div className="col-md-3">
          
                              <div className="form-group">                    
                                      <label >Dirección:</label>
                                      <input type="text"  className="form-control" id="dir_pcte" name="dir_pcte" value={form.dir_pcte} readOnly/>                       
                              </div>
          
                          </div>
                      
                      </div>

                      <div className="row">

                          <div className="col-md-6">

                              <div className="form-group">                    
                                      <label >Municipio:</label>
                                      <input type="text" className="form-control" id="municipio_pcte" name="municipio_pcte" value={form.municipio_pcte} readOnly/>                                                                         
                              </div>

                          </div>

                          <div className="col-md-6">

                              <div className="form-group">                    
                                      <label >Departamento:</label>
                                      <input type="text" className="form-control" id="departamento_pct" name="departamento_pct" value={form.departamento_pct} readOnly/>                                                                         
                              </div>
          
                          </div>
                                      
                          
                      
                      </div>

                      <div className="row">
                                      
                          <div className="col-md-6">

                              <div className="form-group">                    
                                      <label >EPS:</label>
                                      <input type="text" className="form-control" id="eps_pcte" name="eps_pcte" value={form.eps_pcte} readOnly/>                                                                         
                              </div>
          
                          </div>

                          <div className="col-md-6">

                              <div className="form-group">                    
                                      <label >Régimen:</label>
                                      <input type="text" className="form-control" id="regimen_pcte" name="regimen_pcte" value={form.regimen_pcte} readOnly/>                                                                         
                              </div>
          
                          </div>
                         
                      
                      </div>  

                      <div className="row">
                                      
                          <div className="col-md-6">

                              <div className="form-group">                    
                                      <label >Persona responsable:</label>
                                      <input type="text" className="form-control" id="responsable_pcte" name="responsable_pcte" value={form.responsable_pcte} readOnly/>                                                                         
                              </div>
          
                          </div>

                          <div className="col-md-3">

                              <div className="form-group">                    
                                      <label >Teléfono:</label>
                                      <input type="text" className="form-control" id="telresponsable_pcte" name="telresponsable_pcte" value={form.telresponsable_pcte} readOnly/>                                                                         
                              </div>
          
                          </div>

                          <div className="col-md-3">

                              <div className="form-group">                    
                                      <label >Parentesco:</label>
                                      <input type="text" className="form-control" id="parentescoresp_pcte" name="regimeparentescoresp_pcten_pcte" value={form.parentescoresp_pcte} readOnly/>                                                                         
                              </div>
          
                          </div>
                         
                      
                      </div>      
          
          
                </div>
               
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
          {/* /.modal */}



          <div className="modal fade" id="modal-historial_citas" data-backdrop="static">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title"><b>Historial de citas solicitadas</b></h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">  


                      <div className="row">
                                  
                                  <div className="col-md-12">
                                     
                                      <div className="card table-responsive">
                               
                                         
                                          <div className="card-body">
                                             <table id="tabla_historial" className="table table-bordered table-striped table-hover">
                                               <thead>
                                                 <tr>
                                                   <th>ID Cita</th>
                                                   <th>Profesional</th> 
                                                   <th>Especialidad</th>
                                                   <th>Fecha de la cita</th>  
                                                   <th>Estado</th>                                            
                                                 </tr>
                                               </thead>
                                               <tbody>
                                                  {this.state.Historial_citas.map(historial=>{
                  
                                                    return(      
                                                    
                                                    <tr>          
                                                      <td>{historial.id}</td> 
                                                      <td>{historial.professional.user.name1 ? (historial.professional.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) :''} {historial.professional.user.name2 ? (historial.professional.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) :''} {historial.professional.user.lastname1 ?(historial.professional.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} {historial.professional.user.lastname2 ? (historial.professional.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''}</td>   
                                                      <td>{(historial.specialty.name).toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>                                          
                                                      <td>
                                                      <Moment format="YYYY/MM/DD HH:mm">
                                                       {historial.fecha}
                                                      </Moment>
                                                      </td>
                                                      <td>{(historial.state).toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                                    </tr>
                                            
                                                       )
                                                      }
                                                    )
                                                  }                
                                               </tbody>
                                             </table>
                                          </div>

                                         
                                      </div>            
               
                                  </div>

                      </div>                 
      
                        
          
          
                </div>

                <div className="modal-footer justify-content-between">
                  <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>        
                </div>
              </div>
              
            </div>
            
          </div> 
           

          <div className="modal fade" id="modal-verificontratos" data-backdrop="static">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header" id="fondo_modal_compra">
                      <h4 className="col-11 modal-title text-center" id="correo_activacion"><b>Atención</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
    
                    <div className="modal-body">

                          
                          {/* /<span id="alert-recoverypass"> </span> */}
                      
                          <div className="row justify-content-md-center" id="mensaje_recupera"> 

                              <div className="col-md-12">

                              Para brindar el servicio de consulta externa el prestador de salud primero debe crear los contratos. Las citas solo se le asignarán afiliados a una EPS con contrato y a los pacientes particulares.
                              <br/>
                              <br/>
                              <a id="btn-registrarse" href="/contratos">Ingresa aquí </a> &nbsp; para crear un contrato particular o por una EPS.

                              </div>
                                                  
                          
                          </div>

                          <br/>                      
                             
                              
                    </div>   
                   

                  </div>
                  
                </div>
                
          </div>

          <div className="modal fade" id="modal-verifisede" data-backdrop="static">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header" id="fondo_modal_compra">
                  <h4 className="col-11 modal-title text-center" id="correo_activacion"><b>Atención</b></h4>
                 
                </div>

                <div className="modal-body">
                      
                      {/* /<span id="alert-recoverypass"> </span> */}
                  
                      <div className="row justify-content-md-center" id="mensaje_recupera"> 
                          <div className="col-md-12">
                          El prestador de salud no ha creado una sede; no se prodrán crear agendas.
                          <br/>
                          <br/>
                          <a id="btn-registrarse" href="/sedes">Ingresa aquí </a> &nbsp; para crear una.
                          </div>
                                              
                      
                      </div>
                      <br/>                     
                         
                          
                </div>   
               
              </div>
              
            </div>
            
          </div>


          <div className="modal fade" id="modal-verificuotamoderadora" data-backdrop="static">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header" id="fondo_modal_compra">
                      <h4 className="col-11 modal-title text-center" id="correo_activacion"><b>Atención</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
    
                    <div className="modal-body">

                          
                          {/* /<span id="alert-recoverypass"> </span> */}
                      
                          <div className="row justify-content-md-center" id="mensaje_recupera"> 

                              <div className="col-md-12">

                                 El prestador no tiene configurado el valor de la cuota moderadora para este nivel, &nbsp; <a id="btn-registrarse" href="/cuotasmoderadoras">  ingresa aquí </a> &nbsp;
                                 para establecerlo.

                              </div>
                                                  
                          
                          </div>

                          <br/>                      
                             
                              
                    </div>   
                   

                  </div>
                  
                </div>
                
          </div>


          <div className="modal fade" id="modal-confirmar_cita" data-backdrop="static">

                <div className="modal-dialog modal-sm">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title"><b>Importante!</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
  
                          <div className="col-md-12">
  
                              ¿Seguro deseas asignar la cita?
  
                          </div>
                                 
                    </div>
          
                          <div className="modal-footer justify-content-between">        
                             <button type="button" className="btn btn-primary" onClick={()=> this.aceptarcita()} data-dismiss="modal" >Si</button>
                             <button type="button" className="btn btn-danger" data-dismiss="modal">No</button>
                          </div>
                  </div>                
                </div>
            
          </div>


          <div className="modal fade" id="modal-cancela_cita" data-backdrop="static">

                <div className="modal-dialog modal-sm">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title"><b>Cancelar cita</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
  
                          <div className="col-md-12">
  
                          La cancelación de una cita no se puede revertir. <br/> <br/>
                          ¿Seguro que desea cancelar esta cita?
  
                          </div>
                                 
                    </div>
          
                          <div className="modal-footer justify-content-between">        
                             <button type="button" className="btn btn-primary" data-dismiss="modal"  disabled={!this.state.active_button_cancelacita} onClick={()=> this.Cancelar_cita()}> {this.state.loading_button_cancelacita?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Si</button>
                             <button type="button" className="btn btn-danger" data-dismiss="modal">No</button>
                          </div>
                  </div>                
                </div>
            
          </div>
              



      </div>      
      
    )

  }
  
}


export default Citas;