import React, {Component,useState} from 'react';
import axios from 'axios';
import * as ReactBootStrap from 'react-bootstrap';
import '../tools/css/Servicios.css';

import emptypage from '../tools/images/carpeta.gif';

import Especialidad from '../API/Especialidad';

import BtnEdit from '../components/BtnEdit';
import $ from 'jquery'; // <-to import jquery
import 'bootstrap';

import Moment from 'react-moment';
import ReactNotifications from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';


const ApiURL="https://medicallapi.azurewebsites.net";
var mensaje='';
var id_especialidad=1;
var estado_edit=null;

class Servicios extends Component {

  constructor()
    {
     super()
      this.state={
        show:false,
        profe: '',
        Servicios:[] ,
        loading: false,
        loading_button: true,
        active_button: true,
        form:{   
          cod_servi:'',  
          nom_servi:'',   
          descrip:'',
          valor_servi:'',
          id_servi_edit:'',
          cod_servi_edit:'',  
          nom_servi_edit:'',   
          descrip_edit:'',
          valor_servi_edit:''
          }     
              
      }      
      
    }

    handleChange=async e=>{
     
      await this.setState({
         form:{
              ...this.state.form,
              [e.target.name]: e.target.value
              }
         })
         
         console.log(this.state.form);
         //this.Selectdrop();
    }

    Campo_numerico(){
      $(".camponum").keypress(function(tecla)
      {
        if(tecla.charCode<48 || tecla.charCode>57){
         
          return false;

        }
      })
    }
    

    Selectdrop(){
      
      if(id_especialidad==1 && (this.state.form.cod_servi!='' ||this.state.form.nom_servi!='') ){
        $("#especialidad").val($('#especialidad > option:first').val());        
      }

     
    }
 
    
    handleModalnewservicio()
    {
      id_especialidad=1;
      $("#especialidad").val($('#especialidad>option:first').val());
      $("#cod_servi").val("");
      $("#nom_servi").val("");
      $("#descrip").val("");
      $("#valor_servi").val("");
      
      $('#modal-crearservicio').modal('show'); // <- to show modal crear sede 
      
    }

    GetEstado = (es) =>{
     
      console.log(es.target.value)
      estado_edit=(es.target.value)

      $('#cb_estado').hide();
    }

    Limpiar_form=()=>{
      this.setState({
        form:{          
          cod_servi:'',  
          nom_servi:'',   
          descrip:'',
          valor_servi:''     
        }
      })
     
    }

    empty_page_hide(){
      $('#tabla1').hide();     
      $('#empty_page').hide();
      $('#text_page').hide();
      
    }
  
    handleModaleditservicio()
    {     
      $('#modal-editservicio').modal('show'); // <- to show modal crear sede 
    } 


    componentDidMount(){ 

      $('[data-toggle="tooltip"]').tooltip()
      
      $('#select_especialidad').hide();
      
      this.empty_page_hide();
      
      this.setState({ loading: this.state.loading = false })
    
      axios.get(ApiURL+"/api/Services/ListAll")
      .then((response) => {
        console.log(response);
        this.setState({Servicios: response.data.data})

        if(this.state.Servicios.length==0){
          $('#tabla1').hide();
          $('#empty_page').show();
          $('#text_page').show();
        }else{
          $('#tabla1').show();
          $('#empty_page').hide();
          $('#text_page').hide();
        }

        this.setState({ loading: this.state.loading = true })

      })
      .catch((error) => {
        console.log(error);
      })

      

    }

    
    seleccionarservicio=(servicio)=>{
      estado_edit=servicio.state
      $('#cb_estado').show();
      
      this.setState({
        form:{
          id_servi_edit:servicio.id,  
          cod_servi_edit:servicio.code,   
          nom_servi_edit:servicio.name ,
          descrip_edit:servicio.description,
          valor_servi_edit:servicio.price         
         
        }
      })

      
      //var  dato  = servicio.id;
      //alert(servicio.id);
    }
    
    
    saveservices=async()=>{

      if(this.state.form.cod_servi.length==0 || this.state.form.nom_servi.length==0 || this.state.form.descrip.length==0 || this.state.form.valor_servi.length==0){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ active_button: this.state.active_button = false })

        await axios.post(ApiURL+"/api/Services/Create",
  
          {code: this.state.form.cod_servi,
          name: this.state.form.nom_servi,
          description: this.state.form.descrip,
          specialtyId: id_especialidad,
          price: this.state.form.valor_servi,
          state: "activo"
          })  
        .then(response=>{
          console.log(response.data)
          var  mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
        store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

          this.componentDidMount();
          $('#modal-crearservicio').modal('hide');
          this.Limpiar_form();

          
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        }
        //console.log(error.config);
      
      })

      }      
  
     }


     editservice=async()=>{

      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ active_button: this.state.active_button = false })

      await axios.put(ApiURL+"/api/Services/"+this.state.form.id_servi_edit,  
        {
          id: this.state.form.id_servi_edit,
          code: this.state.form.cod_servi_edit,
          name: this.state.form.nom_servi_edit,
          description: this.state.form.descrip_edit,
          price: this.state.form.valor_servi_edit,
          state: estado_edit
        } )          
  
        .then(response=>{
          console.log(response.data)
          mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);          
                 
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

          this.componentDidMount();
          $('#modal-editservicio').modal('hide');

          
          
        })
        .catch(error=>{
          console.log(error)
          store.addNotification({
            title: 'Atención',
            message: 'Error al actualizar servicio !!!',
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
      
      })
        
      this.setState({ loading_button: this.state.loading_button = true })
      this.setState({ active_button: this.state.active_button = true })
  
     }

     handleProfesionales = (e) => {
       console.log(e)
     }
     
     
     handleridespe = (es) => {
      console.log(es)
      id_especialidad=(es)
      //alert(id_profe)
    }
  

    render(){     
     const {form} = this.state;

     return (    

     
      <div className="content-wrapper">

            <div className="col-12 col-sm-12">
          
                    <div className="row">
              
                          <div className="col-sm-10">  
                          <br></br>    
                             <div className="text-center" id="title"> <h6> <b> GESTIONAR SERVICIOS </b>  </h6> </div>      
                          </div>
                
                          <div  className="col-sm-2 col-md-offset-2">
                          <br></br>
                             <button id="btn_crear" className="btn btn-edit" onClick={()=>{this.handleModalnewservicio()}}> Crear
                                <svg width="20" height="15" viewBox="3 3 10 10" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                </svg> 
                             </button>        
                          </div>
              
                    </div>

                    <div className="card">            
                  
                          <div className="card-body"> 

                                <div className="d-flex justify-content-center">
                    
                                      {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
                              
                                </div>                   
                                    
                                <div className="card table-responsive" id="tabla1">
                          
                          
                                        <table id="tabla_convenios" className="table table-bordered table-striped">
                                          <thead>
                                            <tr>
                                              <th>ID</th>
                                              <th>CUPS</th>         
                                              <th>Nombre</th>
                                              <th>Valor</th>
                                              <th>Estado</th>
                                              <th>Editar</th>
                                              
                                            </tr>
                                          </thead>
                                          <tbody>
                                          {this.state.Servicios.map(servicio=>{
                                            return(      
                                            
                                            <tr>          
                                              <td>{servicio.id}</td>
                                              <td>{servicio.code}</td>
                                              <td>{(servicio.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                              <td>{servicio.price}</td>
                                              <td>{(servicio.state).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                              <td onClick={()=>{this.handleModaleditservicio();this.seleccionarservicio(servicio)}}> <BtnEdit  /> 
                                              </td>       
                                            </tr>
                                    
                                              )
                                             }
                                           )}                
                                          </tbody>
                                        </table>
                          
                                </div>
                                
                                <div className="d-flex justify-content-center">                              
                                     <img id="empty_page" src={emptypage} alt="logo"  width= "200"/>                             
                                </div>

                                <div className="d-flex justify-content-center">                  
                                     <h5 id="text_page"> <b>No hay información que mostrar</b> </h5>                          
                                </div>

                          </div>
                      
                    </div>

            </div>


            <div className="modal fade" id="modal-crearservicio" data-backdrop="static">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title"><b>Crear Servicio</b></h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=> this.Limpiar_form()}>
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
        
                      <div className="row">
        
                          <div className="col-md-3">
                    
                                <div className="form-group">                    
                                          <label >CUPS:</label>
                                          <div className="input-group">

                                              <input type="text" maxLength="10" className="form-control" id="cod_servi" name="cod_servi" autoComplete='nope' value={form.cod_servi} onChange={this.handleChange}/>                       
                                              <div className="input-group-append">
                                                     <button id="icono_crea" className="btn btn-primary form-control-md" type="button" data-toggle="tooltip" data-placement="top" title="La Clasificación Única de Procedimientos en Salud - CUPS, corresponde al ordenamiento lógico y detallado de los procedimientos en salud que se realizan en el país, en cumplimiento a los principios de interoperabilidad y estandarización de datos utilizados para tal efecto de identificación por un código y una descripción validada por los expertos del país, independientemente de la profesión o disciplina del sector salud que los realice, así como del ámbito de realización de estos." > <span className="fas fa-info-circle"></span> </button>
                                              </div>

                                          </div>
                                </div>
        
                          </div>
        
                          <div className="col-md-9">
                       
                                <div className="form-group">                    
                                          <label >Nombre:</label>
                                          <div className="input-group">                                            
                                          
                                              <input type="text" className="form-control" id="nom_servi" name="nom_servi" autoComplete='nope' value={form.nom_servi} onChange={this.handleChange}/>               
                                              <div className="input-group-append">
                                                     <button id="icono_crea" className="btn btn-primary form-control-md" type="button" data-toggle="tooltip" data-placement="top" title="Corresponde al nombre del servicio a crear, cuya descripción debe ser igual a la estipulada en la norma vigente que estable los CUPS, de acuerdo con el código utilizado." > <span className="fas fa-info-circle"></span> </button>
                                              </div>

                                          </div>
                                </div>
        
                          </div>
                        
                      </div>

                      {/* <div className="row" id="select_especialidad">               
                                        
                                        <div className="col-md-12">
                                            <div className="form-group">                    
                                                    <label >Especialidad:</label>
                                                    <Especialidad handleridespe={this.handleridespe} />
                                            </div>
                        
                                        </div>
                                        
                        
                      </div> */}
        
                      <div className="row">
        
                          <div className="col-md-12">
        
                                <div className="form-group">                    
                                    <label >Descripción:</label>                           
                                    <textarea class="form-control" rows="3" id="descrip" name="descrip" autoComplete='nope' value={form.descrip} onChange={this.handleChange} ></textarea>
                                    
                                </div>
        
                          </div>
        
                      </div>
        
        
                      <div className="row">               
                                        
                            <div className="col-md-3">
                                <div className="form-group">                    
                                        <label >Valor:</label>
                                        <div className="input-group"> 

                                            <input type="text" maxLength="10" className="form-control camponum" id="valor_servi" name="valor_servi" autoComplete='nope' value={form.valor_servi}  onChange={this.handleChange} onKeyDown={this.Campo_numerico()}/> 
                                            <div className="input-group-append">
                                                   <button id="icono_crea" className="btn btn-primary form-control-md" type="button" data-toggle="tooltip" data-placement="top" title="Es el precio que el Prestador de Salud quiera darle al servicio a crear, acorde al contrato suscrito con las EPS, o a la tarifa que tenga definida para las atenciones particulares." > <span className="fas fa-info-circle"></span> </button>
                                            </div>

                                        </div>
                                </div>
            
                            </div>                            
                            
            
                      </div>              
                 
                  </div>
                  <div className="modal-footer justify-content-between">        
                    <button type="button" className="btn btn-primary" disabled={!this.state.active_button} onClick={()=> this.saveservices()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Guardar</button>
                    <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={()=> this.Limpiar_form()}>Cerrar</button>
                  </div>
                </div>
               
              </div>
             
            </div>
           



            <div className="modal fade" id="modal-editservicio" data-backdrop="static">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title"><b>Editar Servicio</b></h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
        
                    <div className="row">
        
                          <div className="col-md-6">
                                <div className="form-group">                    
                                  <label >ID:</label>
                                  <input type="text" className="form-control" id="id_servi_edit" name="id_servi_edit" autoComplete='nope' value={form.id_servi_edit} readOnly />                      
                                </div>                 
                          </div>
        
                          <div className="col-md-6">
                          
                                <div className="form-group">                    
                                          <label >CUPS:</label>
                                          <input type="text" maxLength="10" className="form-control" id="cod_servi_edit" name="cod_servi_edit" autoComplete='nope' value={form.cod_servi_edit} onChange={this.handleChange}/>                       
                                </div>
                          
                          </div>              
                      
                      
                    </div>
        
                      <div className="row">
                      
                          <div className="col-md-12">
                          
                                <div className="form-group">                    
                                          <label >Nombre:</label>
                                          <input type="text" className="form-control" id="nom_servi_edit" name="nom_servi_edit" autoComplete='nope' value={form.nom_servi_edit} onChange={this.handleChange}/>               
                                </div>
                          
                          </div>
                      
                      </div>
                      
                      <div className="row">
                      
                      <div className="col-md-12">
                      
                            <div className="form-group">                    
                                <label >Descripción:</label>                           
                                <textarea class="form-control" rows="2" id="descrip_edit" name="descrip_edit" autoComplete='nope' value={form.descrip_edit} onChange={this.handleChange} ></textarea>
                                
                            </div>
                      
                      </div>
                      
                      </div>
                      
                      
                      <div className="row">               
                                    
                        <div className="col-md-6">
                              <div className="form-group">                    
                                      <label >Valor:</label>
                                      <input type="text" maxLength="10" className="form-control camponum" id="valor_servi_edit" name="valor_servi_edit" autoComplete='nope' value={form.valor_servi_edit} onChange={this.handleChange}/> 
                              </div>
                      
                        </div>

                        <div className="col-md-6">
                             
                              <div className="form-group">                                               
                                      <label >Estado:</label>
                                      <select onChange={this.GetEstado} onClick={this.GetEstado} id="select_estado" className="form-control">
                                            <option id="cb_estado" value={estado_edit}>{estado_edit}</option> 
                                            <option  value="activo">activo</option> 
                                            <option  value="inactivo">inactivo</option>  
                                                                                                                         
                                      </select>
                              </div>
            
                        </div>
                        
                      
                      </div>          
         
                 
                  </div>
        
                        <div className="modal-footer justify-content-between">        
                           <button type="button" className="btn btn-primary" disabled={!this.state.active_button} onClick={()=> this.editservice()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />}Guardar</button>
                           <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>
                        </div>
                </div>
              
              </div>
            
            </div> 


      </div>      
      
    )

  }
  
}


export default Servicios;